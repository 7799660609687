// 首页
import request from "@/utils/request";

// 帖子详情
export function postsInfo(data) {
  return request({
    url: "/api/member/posts/info",
    method: "post",
    data: data,
  });
}

// 用户评论列表
export const commentList = (data) => {
  return request({
    url: "/api/member/posts/commentList",
    method: "post",
    data,
  });
};

// 发布评论
export const submitComment = (data) => {
  return request({
    url: "/api/member/posts/submitComment",
    method: "post",
    data,
  });
};

// 投诉
export const complaint = (data) => {
    return request({
      url: '/api/member/posts/complaint',
      method: "post",
      data
    })
  }

  