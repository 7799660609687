<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1" ref="navbar"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="flex-ju-sb" style="padding-bottom: 18px">
				<div class="title">个人信息</div>
				<div class="flex">
					<div class="btn" @click="goBack">返回</div>
				</div>
			</div>
			<div class="index_c_item">
				<div class="flex-ju-sb border-b" style="cursor: pointer">
					<div>头像</div>
					<!-- <el-upload
            class="upload-demo"
            ref="upload"
            :action="domain"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :auto-upload="false">
            <div class="flex">
              <el-image
                :src="userInfo.avatar"
                style="width: 48px; height: 48px"
                class="avatar"
              ></el-image>
            </div>
          </el-upload> -->
					<!-- <el-upload class="avatar-uploader" :action="domain" :show-file-list="false" :with-credentials="true" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="userInfo.avatar != '' || userInfo.avatar != null" :src="userInfo.avatar" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload> -->
          <uploadQiniu :imageurl="userInfo.avatar" @successPhoto="successPhoto" red="upload" />
				</div>
				<div class="flex-ju-sb border-b" style="padding-top: 16px; cursor: pointer" @click="(centerDialogVisible = true), (title = '修改昵称')">
					<div>昵称</div>
					<div class="flex">
						<div class="index_c_item_main">{{ userInfo.nickname }}</div>
						<i class="el-icon-arrow-right" style="font-size: 20px; color: #cccccc; font-weight: bold"></i>
					</div>
				</div>
				<div class="flex-ju-sb" style="padding-top: 16px; cursor: pointer" @click="(centerDialogVisible = true), (title = '修改性别')">
					<div>性别</div>
					<div class="flex">
						<div class="index_c_item_main">
							{{ userInfo.gender == 0 ? '女' : '男' }}
						</div>
						<i class="el-icon-arrow-right" style="font-size: 20px; color: #cccccc; font-weight: bold"></i>
					</div>
				</div>
			</div>
		</div>

		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
		<el-dialog :visible.sync="centerDialogVisible" width="25%" center :title="title" :show-close="false">
			<el-input v-model="nickname" placeholder="请输入修改昵称" v-if="title == '修改昵称'"></el-input>
			<el-select v-model="gender" placeholder="请选择" v-else style="width: 100%">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
			<div class="flex-center" style="padding-top: 16px">
				<div class="dialog_btn" @click="sumbit">确定</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
import environment from '@/utils/environment.js';
import uploadQiniu from '@/components/Upload/qiniuUpload';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
		uploadQiniu,
	},
	data() {
		return {
			centerDialogVisible: false,
			title: '',
			nickname: '',
			options: [
				{
					value: 1,
					label: '男',
				},
				{
					value: 0,
					label: '女',
				},
			],
			gender: '',
			userInfo: {},
			domain: environment.upLoadPath(),
			token: localStorage.getItem('bupaolu_token'),
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		goBack() {
			this.$router.go(-1);
		},
		sumbit() {
			if (this.title == '修改性别') {
				this.$api.me.updateAccount({ gender: this.gender, type: 2 }).then(res => {
					this.$message.success(res);
					this.centerDialogVisible = false;
					this.userInfo.gender = this.gender;
					localStorage.setItem('bupaolu_userInfo', this.userInfo);
				});
			} else {
				this.$api.me.updateAccount({ nickname: this.nickname, type: 1 }).then(res => {
					this.$message.success(res);
					this.centerDialogVisible = false;
					this.userInfo.nickname = this.nickname;
					localStorage.setItem('bupaolu_userInfo', this.userInfo);
				});
			}
		},
		handleAvatarSuccess(res, file) {
			this.userInfo.avatar = URL.createObjectURL(file.raw);
		},
    successPhoto(e) {
      this.$api.me.updateAccount({ avatar: e, type: 0 }).then(res => {
				this.$message.success('更新成功');
				this.userInfo.avatar = res.data;
        this.$refs.navbar.init(e)
				this.$refs.upload.init(e)
				localStorage.setItem('bupaolu_userInfo', this.userInfo);
			});
    },
		beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 2MB!');
			}
			return isLt2M;
		},
	},
	mounted() {
		this.userInfo = JSON.parse(localStorage.getItem('bupaolu_userInfo'));
		this.gender = this.userInfo.gender;
		this.nickname = this.userInfo.nickname;
		// console.log(layui);
	},
	created() {},
};
</script>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c {
	padding: 80px 350px;
}
.title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 24px;
	color: #333333;
}
.btn {
	background: #ffffff;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	padding: 8px 24px;
	margin-right: 16px;
	cursor: pointer;
}
.index_c_item {
	padding: 24px;
	background-color: #fff;
	border-radius: 12px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
}
.index_c_item_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #9e9e9e;
	margin-right: 12px;
}
.avatar {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	margin-right: 8px;
}
.dialog_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 24px;
	color: #333333;
}
.dialog_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 20px;
	background: #fdc570;
	border-radius: 277px 277px 277px 277px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
</style>
