<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="1" :sumbit="true" :keywords="query.institutionName" @sumbit="sumbit"></navbar>
    <!-- banner -->
    <div class="content">
      <div class="title" v-if="type != 'nav'">搜索结果</div>
      <div class="sumbit_list">
        <div class="flex" style="align-items: baseline">
          <div class="sumbit_title">选择分类：</div>
          <div>
            <div class="flex-wrap">
              <div :class="cateIdx == index ? 'tag_active' : 'tag'" v-for="(item, index) in categoryList" :key="index" @click="cateClick(index, item)">{{ item.institutionCategoryName }}</div>
            </div>
            <div class="flex-wrap" v-if="cateIdx != 0" style="justify-content: flex-start">
              <div :class="cateIdx1 == index ? 'tag_active' : 'tag'" v-for="(item, index) in categoryList[cateIdx].childCategoryVos" :key="index" @click="cateClick1(index, item)">{{ item.institutionCategoryName }}</div>
            </div>
          </div>
        </div>
        <div class="flex" style="align-items: baseline;">
          <div class="sumbit_title">选择标签：</div>
          <div>
            <div class="flex-wrap">
              <div
                :class="item.check ? 'tag_active' : 'tag'"
                v-for="(item, index) in tabList"
                :key="index"
                @click="tabClick(index, item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex" style="align-items: baseline">
          <div class="sumbit_title">智能排序：</div>
          <div>
            <div class="flex-wrap">
              <div :class="sortIdx == index ? 'tag_active' : 'tag'" v-for="(item, index) in sortList" :key="index" @click="sortClick(index, item)">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6" v-for="item in dataList" :key="item">
          <div
            class="content_item"
            @click="goUrl('/detail?id=' + item.institutionId)"
          >
            <div class="content_item_cover" :style="{background: `url(${item.coverImage || ''})`, backgroundSize: 'cover'}">
              <div class="content_item_cover_text m-text-line-2">
               {{ item.address }}
              </div>
            </div>
            <div class="content_item_bottom">
              <div class="content_item_bottom_title m-text-line-2">{{ item.institutionName }}</div>
              <el-rate disabled v-model="item.institutionPoint"></el-rate>
              <div class="flex" style="padding-top: 12px">
								<div :class="tabs == '签约' ? 'content_item_bottom_tag_item1' : 'content_item_bottom_tag_item'" v-for="tabs in item.tabs" :key="tabs">{{ tabs }}</div>
							</div>
              <!-- <div class="flex" style="padding-top: 12px" v-for="ite in item.courseBaseVos" :key="ite">
                <span class="content_item_bottom_price">￥{{ ite.price }}</span>
                <span class="content_item_bottom_title_1">{{ ite.courseName }}</span>
              </div> -->
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- <div class="content_list">
        
      </div> -->
      <div class="flex-center">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
           @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
import { scrollTo } from '@/utils/scroll-to'
export default {
  name: "sumbit",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      tabList: [],
			categoryList: [],
			cateIdx: 0,
			cateIdx1: null,
			sortList: [
				{ name: '默认', check: true, id: null },
				{ name: '距离近到远', check: false, id: 1 },
				{ name: '价格高到低', check: false, id: 2 },
				{ name: '价格低到高', check: false, id: 3 },
			],
			sortIdx: 0,
			query: {
        pageNumber: 1,
        pageSize: 20,
        sortType: null, //1距离近到远 2价格高到低 3价格低到高
        categoryLevel1Id: 0,
        categoryLevel2Id: null,
        lat: 0,
        lon: 0,
        institutionName: null, //搜索内容
				tabs: []
      },
			dataList: [],
      total: 0,
      type: ''
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    getTypeList() {
      this.$api.common.tabList().then((res) => {
        const result = res.map((item) => {
          return {
            name: item,
            check: false,
          };
        });
        this.tabList = result;
      });
    },
    getInstitutionCategoryList() {
      this.$api.home
        .getInstitutionCategoryList({ institutionName: this.query.institutionName })
        .then((res) => {
					this.categoryList = res
          for (const key in this.categoryList) {
            if (this.categoryList[key].institutionCategoryId == this.query.categoryLevel1Id) {
             this.cateIdx = Number(key)
            }
          }
				});
    },
    tabClick(index, data) {
      this.tabList[index].check = !this.tabList[index].check;
      this.sumbit()
      this.$forceUpdate();
    },
		cateClick(index, data) {
			this.cateIdx = index
			this.query.categoryLevel1Id = data.institutionCategoryId
      if (this.query.categoryLevel1Id == 0) {
        this.query.categoryLevel2Id = null
      }
      this.sumbit()
		},
		cateClick1(index, data) {
			this.cateIdx1 = index
			this.query.categoryLevel2Id = data.institutionCategoryId
      this.sumbit()
		},
		sortClick(index, data) {
			this.sortIdx = index
			this.query.sortType = data.id
      this.sumbit()
		},
		sumbit(e) {
			this.query.institutionName = e || null
			let tabs = []
			for (const key in this.tabList) {
				if (this.tabList[key].check) {
					tabs.push(this.tabList[key].name)
				}
			}
			this.query.tabs = tabs
			this.query.pageNumber = 1
      this.type = ''
			this.getSearch()
		},
		getSearch() {
			this.$api.home.getInstitutionSearch(this.query).then(res => {
				this.dataList = res.records
        this.total = res.total
			})
		},
    currentChange(e) {
      this.query.pageNumber = e
      scrollTo(0, 800)
      this.getSearch()
    }
  },
  mounted() {
    // console.log(layui);
    if (this.$route.query.institutionCategoryId) {
      this.query.categoryLevel1Id = Number(this.$route.query.institutionCategoryId)
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    if (localStorage.getItem('bupaolu_address')) {
      this.query.lon = JSON.parse(localStorage.getItem('bupaolu_address')).lon
      this.query.lat = JSON.parse(localStorage.getItem('bupaolu_address')).lat
    }
    if (this.$route.query.keywords) {
      this.query.institutionName = this.$route.query.keywords
    }
    this.getTypeList();
		this.getInstitutionCategoryList()
		this.getSearch()
    
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.content {
  padding: 80px 250px;
}
.title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 28px;
  color: #333333;
  padding-bottom: 24px;
}
.sumbit_list {
  padding: 20px 16px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 24px;
}
.sumbit_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  padding-right: 20px;
}
.tag {
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #f5f5f5;
  padding: 4px 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.tag_active {
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #fdc570;
  padding: 4px 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background: #fdc570;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}

.content_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}
.content_item {
  background-color: #ffffff;
  margin-bottom: 16px;
  height: 450px;
  margin-bottom: 20px;
  cursor: pointer;
}
.content_item_cover {
  width: 340px;
  height: 340px;
  background: #d8d8d8;
  border-radius: 12px 12px 0 0;
  position: relative;
}
.content_item_cover_text {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 15px;
  box-sizing: border-box;
}

.content_item_bottom {
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;
  box-sizing: border-box;
  padding: 16px;
}
.content_item_bottom_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  padding-bottom: 12px;
}
.content_item_bottom_tag_item {
  background: #f5f5f5;
  border-radius: 2px 2px 2px 2px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 16px;
}
.content_item_bottom_tag_item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(253, 197, 112, 0.1);
  border-radius: 2px 2px 2px 2px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #fdc570;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 16px;
}
.content_item_bottom_price {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 12px;
  color: #f95620;
  padding-bottom: 4px;
}
.content_item_bottom_title_1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding-left: 8px;
  padding-bottom: 4px;
}
</style>
