import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import qs from 'qs'
import { getCookie } from '@/utils/cookie'
import router from '../router/index'

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  // baseURL: '/api', // 测试
  baseURL: '/gw', //正式
  transformRequest: [
    function(data) {
      return JSON.stringify(data)
    }
  ],
  headers: {
    token: localStorage.getItem('bupaolu_token') || '',
    platform: 1
  },
  // timeout: 5000 // request timeout
})
axios.defaults.withCredentials = true
axios.defaults.headers['Content-Type'] = 'application/json'
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    // 请求前出错做些什么
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data
    // console.log(response)
    if (res.code == 0) {
      // 请求成功后将数据返回
      return Promise.resolve(res.data)
    }
    if (res.code == 1) {
      // 请求出错将错误信息返回
      // console.log('执行了request中的 错误消息')
      // Message.error(res.message||'系统出现错误，请稍后重试')
      // TODO data为空时报错
      if (!res.data && res.message) {
        Message.error(res.message)
      }
      return Promise.reject(res.message)
    }
    if (res.code == 2) {
      // 用户未登录
      Message.error('您还没有登录或者登录凭证过期')
      // vue 发送事件给组件监听
      localStorage.removeItem('bupaolu_token')
      localStorage.setItem('bupaolu_roter', 1)
      router.push('/login')
      return Promise.reject(res.message)
    } else {
      Message.error(res.message)
      return Promise.reject(res.message)
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    Message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
