<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="index_c_item">
				<div class="flex" style="padding-bottom: 24px">
					<!-- <div class="index_c_item_cover"></div> -->
					<el-image :src="teachInfo.memberImage" fit="cover" class="index_c_item_cover" style="width: 110px; height: 154px"></el-image>
					<div style="width: 95%">
            <div class="index_c_item_name">{{ teachInfo.memberName }}</div>
            <div class="index_c_item_main m-text-line-3">{{ teachInfo.introduction }}</div>
          </div>
				</div>
        <div class="index_c_item_title">教师特点</div>
        <div class="index_c_item_main m-text-line-3" style="padding-bottom: 24px">{{ teachInfo.feature }}</div>
        <div class="index_c_item_title">老师寄语</div>
        <div class="index_c_item_main m-text-line-3">{{ teachInfo.message }}</div>
      </div>
		</div>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			query: {
        institutionId: '',
        institutionMemberId: '',
      },
      teachInfo: {},
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		getDetail() {
			this.$api.home.teachInfoDetail(this.query).then(res => {
				this.teachInfo = res
			})
		}
	},
	mounted() {
		if (this.$route.query.institutionId) {
    	  this.query.institutionId = Number(this.$route.query.institutionId)
    	}
    	if (this.$route.query.institutionMemberId) {
    	  this.query.institutionMemberId = Number(this.$route.query.institutionMemberId)
    	}
    	this.getDetail()
		// console.log(layui);
	},
	created() {},
};
</script>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c {
	padding: 80px 300px;
}
.index_c_item {
	padding: 24px;
	background-color: #fff;
	border-radius: 12px;
}
.index_c_item_cover {
	width: 110px;
	height: 154px;
	border-radius: 4px 4px 4px 4px;
	margin-right: 12px;
}
.index_c_item_name {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #333333;
	padding-bottom: 8px;
}
.index_c_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	padding-bottom: 8px;
}
.index_c_item_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #9e9e9e;
  word-break: normal;
}
</style>
