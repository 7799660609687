<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="flex-ju-sb" style="padding-bottom: 18px">
				<div class="title">关联孩子</div>
				<div class="flex">
					<div class="btn" @click="goBack">返回</div>
					<div class="btn1" @click="centerDialogVisible = true">添加孩子信息</div>
				</div>
			</div>
			<div class="index_c_list">
				<div class="index_c_item" v-for="(item, index) in dataList" :key="index" @click="handEdit(item)">
					<div class="index_c_item_title flex-ju-sb" style="padding-bottom: 18px">
						<div>{{ item.childName }}</div>
						<i class="el-icon-delete" style="color: #ccc; font-size: 20px" @click.stop="handDel(item.id, item.childName)"></i>
					</div>
					<div style="padding-bottom: 4px" class="index_c_item_status">{{ statusText(item.status) }}</div>
					<div style="padding-bottom: 4px" class="index_c_item_main flex">
						性别：
						<span>{{ item.gender == 0 ? '女' : '男' }}</span>
					</div>
					<div style="padding-bottom: 4px" class="index_c_item_main flex">
						证件类型：
						<span>{{ item.cardType }}</span>
					</div>
					<div class="index_c_item_main flex">
						证件号码：
						<span>{{ hideIdCard(item.idCard) }}</span>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="centerDialogVisible" width="30%" :show-close="false">
			<div class="dialog_box">
				<div class="dialog_top flex-ju-sb" style="margin-top: -30px; padding-bottom: 48px">
					<div class="dialog_top_title">添加孩子信息</div>
					<i class="el-icon-error" style="color: #fdc570; font-size: 24px; cursor: pointer" @click="centerDialogVisible = false"></i>
				</div>
				<div class="dialog_c border-b">
					<div class="flex" style="padding-bottom: 16px">
						<span style="padding-left: 27px">姓名：</span>
						<el-input v-model="params.childName" placeholder="请填写监护人姓名" style="width: 320px"></el-input>
					</div>
					<div class="flex" style="padding-bottom: 16px">
						<span>证件类型：</span>
						<div class="flex">
							<div class="flex" @click="params.gender = 1">
								<div class="radio" v-if="params.gender == 0"></div>
								<i v-if="params.gender == 1" class="el-icon-success" style="color: #fdc570; font-size: 18px; cursor: pointer"></i>
								<span style="padding-left: 8px">男</span>
							</div>
							<div @click="params.gender = 0" class="flex" style="padding-left: 24px">
								<div class="radio" v-if="params.gender == 1"></div>
								<i v-if="params.gender == 0" class="el-icon-success" style="color: #fdc570; font-size: 18px; cursor: pointer"></i>
								<span style="padding-left: 8px">女</span>
							</div>
						</div>
					</div>
					<div class="flex" style="padding-bottom: 16px">
						<span>证件类型：</span>
						<el-select v-model="params.cardType" placeholder="请选择证件类型" style="width: 320px">
    				  <el-option
    					  v-for="item in options"
    					  :key="item.value"
    					  :label="item.label"
    					  :value="item.value"
    					  >
    					</el-option>
    				</el-select>
					</div>
					<div class="flex">
						<span>证件号码：</span>
						<el-input placeholder="请输入证码号码" v-model="params.idCard" style="width: 320px"></el-input>
					</div>
				</div>
				<div class="flex-end" style="padding-top: 16px">
					<div @click="centerDialogVisible = false" class="close_btn" style="margin-right: 16px">取消</div>
					<div @click="handAdd()" class="sumbit_btn">保存</div>
				</div>
			</div>
		</el-dialog>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			centerDialogVisible: false,
			dataList: [],
			params: {
        cardType: '',
        childName: '',
        gender: 0,
        idCard: '',
      },
			options: [],
			type: ''
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		goBack() {
			this.$router.go(-1);
		},
		getList() {
			this.$api.me.childList().then((res) => {
        this.dataList = res;
      });
		},
		statusText(key) {
      switch (key) {
        case 0:
          return "核验中";
        case 1:
          return "核验通过";
        case 2:
          return "核验无效";
      }
    },
		hideIdCard(idCard) {
      return idCard.replace(/^(.{6})(?:\d+)(.{4})$/, '$1****$2');
    },
		handEdit(data) {
			this.params = {
        cardType: data.cardType,
        childName: data.childName,
        gender: data.gender,
        idCard: data.idCard,
        childId: data.id
      }
			if (this.type == '') {
				this.centerDialogVisible = true
			} else {
				// 返回上一页并携带数据
				sessionStorage.setItem('chlidData', JSON.stringify(this.params));
				this.$router.go(-1);
			}
		},
		getType() {
      this.$api.common.cardType().then((res) => {
        const result = res.map((item) => {
          return {
            value: item,
            label: item,
          };
        });
				this.options = result
      })
    },
		handAdd() {
			if (this.params.childId) {
				this.$api.me.modifyChild(this.params).then((res) => {
					this.$message.success(res)
					this.getList()
					this.centerDialogVisible = false
				})
			} else {
				this.$api.me.addChild(this.params).then((res) => {
					this.$message.success(res)
					this.getList()
					this.centerDialogVisible = false
				})
			}
		},
		handDel(childId, childName) {
      let that = this;
			this.$confirm(`是否删除${childName}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.me.delChild({ id: childId }).then((res) => {
				  this.$message.success(res)
				  this.getList();
				});
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
	},
	mounted() {
		this.getList()
		this.getType()
		if (this.$route.query.type) {
			this.type = this.$route.query.type
		}
	},
	created() {},
};
</script>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c {
	padding: 80px 350px;
}
.title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 24px;
	color: #333333;
}
.btn {
	background: #ffffff;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	padding: 8px 24px;
	margin-right: 16px;
	cursor: pointer;
}
.btn1 {
	background: #fdc570;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #ffffff;
	padding: 8px 24px;
	cursor: pointer;
}
.index_c_list {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	flex-wrap: wrap;
}
.index_c_item {
	width: 384px;
	background: #ffffff;
	border-radius: 12px 12px 12px 12px;
	padding: 24px;
	box-sizing: border-box;
	margin-bottom: 24px;
	cursor: pointer;
	margin-right: 24px;
}
.index_c_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 20px;
	color: #333333;
}
.index_c_item_status {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #f95620;
}
.index_c_item_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
}
.index_c_item_main span {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #333333;
}

.dialog_top_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
}
.dialog_c span {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
}
.sumbit_btn {
	background: #fdc570;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	padding: 8px 24px;
	cursor: pointer;
}
.close_btn {
	background: #f5f5f5;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #333;
	padding: 8px 24px;
	cursor: pointer;
}
.radio_active {
  width: 16px;
  height: 16px;
  border-radius: 75px 75px 75px 75px;
  border: 1px solid #dddddd;
  cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #F9AF20;
}
.radio {
  width: 16px;
  height: 16px;
  border-radius: 75px 75px 75px 75px;
  border: 1px solid #dddddd;
  cursor: pointer;
	align-items: center;
	justify-content: center;
}
</style>
