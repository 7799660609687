// 订单接口
import https from "@/utils/request";

// 订单列表
export function myList(data) {
  return https({
    url: "/api/member/order/myList",
    method: 'post',
    data,
  });
}


// 订单详情
export function info(data) {
  return https({
    url: "/api/member/order/info",
    method: 'post',
    data,
  });
}

// 售后
export function applySales(data) {
  return https({
    url: "/api/member/order/applySales",
    method: 'post',
    data,
  });
}

// 评价
export function orderComment(data) {
  return https({
    url: "/api/member/order/orderComment",
    method: 'post',
    data,
  });
}

// 核销
export function audit(data) {
  return https({
    url: "/api/member/sales/course/audit",
    method: 'post',
    data,
  });
}

export function getStatus (data) {
  return https({
    url: "/api/member/order/getStatus",
    method: 'post',
    data,
  });
}