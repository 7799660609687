// 通用接口
import request from "@/utils/request";

// 证件类型
export function cardType(data){
  return request({
    url: "/common/cardType",
    method: 'post',
    data,
  });
}

// 标签
export function tabList(data){
  return request({
    url: "/common/tabList",
    method: 'post',
    data,
  });
}

// 获取七牛云token
export function getToken(data){
  return request({
    url: "/api/member/file/getToken",
    method: 'post',
    data,
  });
}

export function platformHosting(){
  return request({
    url: "/h5/platformHosting",
    method: 'get',
  });
}