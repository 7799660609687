import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    SET_LOGIN_STATUS: false, // 登录状态
  },
  mutations: {
    increment(state) {
      state.SET_LOGIN_STATUS = state
    }
  },
  actions: {
  },
  modules: {
  }
})
