import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/rem.js'

import App from './App'
import store from './store'
import router from './router'
import api from './api'
import axios from 'axios'

import AMap from 'vue-amap'
Vue.use(AMap) // 引入高德组件
Vue.use(Element, {
  size: Cookies.get('size') || 'mini', // set element-ui default size
  zIndex: 500
})
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

Vue.prototype.$api = api
Vue.prototype.$axios = axios

Vue.config.productionTip = false

import { setCookie, getCookie, checkCookie, clearCookie } from '@/utils/cookie';
Vue.prototype.$setCookie = setCookie;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$checkCookie = checkCookie;
Vue.prototype.$clearCookie = clearCookie;

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: 'ecf477c3e4caeecbb8b9036c50ad3ceb',
  securityJsCode: 'c2b0921bf9df6bf8cc3c6c024a0a2669',
  // 插件集合 （插件按需引入）
  plugin: [
    'Autocomplete',
    'PlaceSearch',
    'Scale',
    'OverView',
    'ToolBar',
    'MapType',
    'PolyEditor',
    'AMap.CircleEditor',
    'Geolocation',
    'CitySearch'
  ]
})

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
})
