<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :sumbit="true" :tabIdx="2" :keywords="params.keywords" @sumbit="getSearch"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="index_c_tab">
				<div @click="tagClick(0)" :class="params.type == 0 ? 'index_c_tab_item_active' : 'index_c_tab_item'">全部</div>
				<div @click="tagClick(1)" :class="params.type == 1 ? 'index_c_tab_item_active' : 'index_c_tab_item'">图文</div>
				<div @click="tagClick(2)" :class="params.type == 2 ? 'index_c_tab_item_active' : 'index_c_tab_item'">视频</div>
				<div @click="tagClick(3)" :class="params.type == 3 ? 'index_c_tab_item_active' : 'index_c_tab_item'">用户</div>
			</div>
			<el-row :gutter="20">
				<el-col :span="6" v-for="(item, index) in dataList" :key="index">
					<div class="index_c_item" @click="goUrl('/communityDetail?id=' + item.postsId)">
						<div class="index_c_item_cover" :style="{ background: `url(${item.avatar || ''})`, backgroundSize: 'cover' }">
							<div class="index_c_item_video" v-if="item.postsType == 2">
								<i class="el-icon-video-play" style="color: #ccc; font-size: 30px"></i>
							</div>
							<div class="index_c_item_top_right" v-if="item.question">提问</div>
						</div>
						<div class="index_c_item_title m-text-line-2">{{ item.content }}</div>
						<div class="flex-ju-sb">
							<div class="flex">
								<!-- <div class="index_c_item_avatar"></div> -->
								<el-image fit="cover" :src="item.avatar" style="width: 24px; height: 24px" class="index_c_item_avatar" @click.stop="goUrl('/user_community?id=' + item.memberId)"></el-image>
								<div class="index_c_item_name">{{ item.nickname }}</div>
							</div>
							<div class="flex" @click.stop="getZan(item.postsId, index)">
								<!-- <i class="el-icon-thumb"></i> -->
								<el-image :src="item.zan ? '/static/pc/zan1.png' : '/static/pc/zan.png'" fit="cover" style="width: 20px; height: 20px"></el-image>
								<div class="index_c_item_num">{{ item.zanCount }}</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<div class="index_c_list"></div>
		</div>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			params: {
				city: null,
				keywords: null,
				pageNumber: 1,
				pageSize: 20,
				topicId: 0,
				type: 0,
			},
			tabList: [],
			dataList: [],
			tabIdx: 0,
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		async tagClick(index) {
			this.params.type = index;
			this.params.pageNumber = 1;
			this.dataList = [];
			this.getList();
		},
		tabClick(index, id) {
			this.tabIdx = index;
			this.params.topicId = id;
			this.dataList = [];
			this.params.pageNumber = 1;
			this.getList();
		},
		getList() {
			this.$api.community.postsList(this.params).then(res => {
				if (res.records != null) {
					// 合并数组
					this.dataList = [...this.dataList, ...res.records];
				}
			});
		},
		getZan(id, index) {
			this.$api.community.zan({ id: id }).then(res => {
				uni.$u.toast(res.data);
				if (this.dataList[index].zan) {
					this.dataList[index].zan = false;
					this.dataList[index].zanCount--;
				} else {
					this.dataList[index].zan = true;
					this.dataList[index].zanCount++;
				}
				this.$forceUpdate();
			});
		},
		getSearch(e) {
			this.params.keywords = e
			this.params.pageNumber = 1
			this.dataList = []
			this.getList();
		},
		getZan(id, index) {
			this.$api.community.zan({ id: id }).then(res => {
				console.log(res);
				this.$message.success(res);
				if (this.dataList[index].zan) {
					this.dataList[index].zan = false;
					this.dataList[index].zanCount--;
				} else {
					this.dataList[index].zan = true;
					this.dataList[index].zanCount++;
				}
				this.$forceUpdate();
			});
		},
	},
	mounted() {
		if (this.$route.query.keywords) {
			this.query.institutionName = this.$route.query.keywords
    }
			this.getList();
		// console.log(layui);
	},
	created() {},
};
</script>
<style scoped>
.index_c {
	padding: 40px 350px;
}
.index_c_tab {
	padding-right: 800px;
	padding-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.index_c_tab_item_active {
	background: #f5f5f5;
	border-radius: 264px 264px 264px 264px;
	padding: 8px 24px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	cursor: pointer;
}
.index_c_tab_item {
	border-radius: 264px 264px 264px 264px;
	border: 1px solid #f5f5f5;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #999999;
	padding: 8px 24px;
	cursor: pointer;
}
.index_c_list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}
.index_c_item {
	margin-bottom: 48px;
	cursor: pointer;
}
.index_c_item_cover {
	width: 282px;
	height: 282px;
	border-radius: 16px 16px 16px 16px;
	background-color: #000;
	margin-bottom: 16px;
	position: relative;
}
.index_c_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	margin-bottom: 8px;
}
.index_c_item_avatar {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	margin-right: 8px;
}
.index_c_item_name {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #333333;
}
.index_c_item_num {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #333333;
	padding-left: 6px;
}
.index_c_item_video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.index_c_item_top_right {
	position: absolute;
	top: 0;
	right: 0;
	width: 60px;
	height: 28px;
	background: #f95620;
	border-radius: 0px 16px 0px 16px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #ffffff;
	text-align: center;
	line-height: 28px;
}
</style>
