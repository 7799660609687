
// 首页
import request from '@/utils/request'

// 我的订单
export function myList(data) {
  return request({
    url: '/api/member/order/myList',
    method: 'post',
    data: data
  })
}

// 课程列表
export function courseList(data) {
  return request({
    url: '/api/member/sales/course/myList',
    method: 'post',
    data: data
  })
}

// 退款/售后
export function salesList(data) {
  return request({
    url: '/api/member/order/salesList',
    method: 'post',
    data: data
  })
}

// 我的评价
export function orderCommentList(data) {
  return request({
    url: '/api/member/order/orderCommentList',
    method: 'post',
    data: data
  })
}

// 我的帖子
export function postsList(data) {
  return request({
    url: '/api/member/posts/myList',
    method: 'post',
    data: data
  })
}

// 浏览历史
export function reviewList(data) {
  return request({
    url: '/api/member/course/reviewList',
    method: 'post',
    data: data
  })
}

// 我的收藏
export function collectList(data) {
  return request({
    url: '/api/member/collectList',
    method: 'post',
    data: data
  })
}

// 我的礼券
export function couponList(data) {
  return request({
    url: '/api/member/couponList',
    method: 'post',
    data: data
  })
}

// 意见反馈
export function feedbackList(data) {
  return request({
    url: '/api/member/pc/feedback/list',
    method: 'post',
    data: data
  })
}

// 意见反馈提交
export function feedbackAdd(data) {
  return request({
    url: '/api/member/pc/feedback/add',
    method: 'post',
    data: data
  })
}


// 关联小孩
export function childList(data) {
  return request({
    url: "/api/member/myChildList",
    method: 'post',
    data,
  });
}

// 关联小孩-添加
export function addChild(data) {
  return request({
    url: "/api/member/addChild",
    method: 'post',
    data,
  });
}

// 关联小孩-修改
export function modifyChild(data) {
  return request({
    url: "/api/member/modifyChild",
    method: 'post',
    data,
  });
}

// 关联小孩-删除
export function delChild(data) {
  return request({
    url: "/api/member/delChild",
    method: 'post',
    data,
  });
}


// 换绑手机号
export function changePhoneCheck(data) {
  return request({
    url: "/api/member/changePhone/check",
    method: 'post',
    data,
  });
}

// 换绑手机号
export function changePhone(data) {
  return request({
    url: "/api/member/changePhone",
    method: 'post',
    data,
  });
}

// 
export function updateAccount(data) {
  return request({
    url: "/api/member/updateAccount",
    method: 'post',
    data,
  });
}

// 小孩添加
export function childAdd(data) {
  return request({
    url: '/api/member/addChild',
    method: 'post',
    data,
  })
}

// 小孩修改
export function childUpdate(data) {
  return request({
    url: '/api/member/modifyChild',
    method: 'post',
    data,
  })
}
// 小孩删除
export function childDel(data) {
  return request({
    url: '/api/member/delChild',
    method: 'post',
    data,
  })
}

// 认证专家
export function authExpert(data) {
  return request({
    url: '/api/member/authExpert',
    method: 'post',
    data,
  })
}

// 注销账号
export function logoff(data) {
  return request({
    url: '/api/member/logoff',
    method: 'post',
    data,
})
}