// 登录
import request from '@/utils/request'
import environment from '@/utils/environment'

export function code(time){
  var api = environment.verification()
  return `${api}?timestamp=${time}`
}

export function register(data) {
  return request({
    url: '/h5/register',
    method: 'post',
    data: data
  })
}

export function privacyPolicy(data) {
  return request({
    url: '/h5/privacyPolicy',
    method: 'post',
    data: data
  })
}

export const baseInfo = (data) => {
  return request({
    url: '/api/member/baseInfo',
    method: 'post',
    data: data,
  });
}

export function byMobile(data) {
  return request({
    url: '/api/member/login/byMobile',
    method: 'post',
    data: data
  })
}

export function bindPhone(data) {
  return request({
    url: '/api/member/bindPhone',
    method: 'post',
    data: data
  })
}

// 发送验证码
export const sendCode = (data) => {
  return request({
    url: '/api/member/login/sendCode',
    method: 'post',
    data,
  })
}

export const sendCode1 = (data) => {
  return request({
    url: '/api/member/sendCode',
    method: 'post',
    data,
  })
}

export const authUrl = (data) => {
  return request({
    url: '/api/member/login/authUrl/' + data,
    method: 'post',
  })
}