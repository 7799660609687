<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="1"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="title flex-ju-sb">
        <div>订单详情</div>
        <div class="index_c_item_back" @click="goBack">返回</div>
      </div>
      <div class="index_c_item">
        <div class="flex">
          <div style="border-right: 1px solid #F5F5F5;padding-right: 24px">
            <div class="item_title" style="padding-bottom: 16px;font-size: 14px">订单号：{{ detailData.sn }}</div>
            <div class="flex-center order_item_status" :style="{'color': detailData.status != 1 ? '#333' : '#FF6600'}">
              {{ orderStatusText(detailData.status) }}
            </div>
            <div class="flex-center" style="padding-top: 16px">
              <div class="index_c_item_top_btn" v-if="detailData.status != 2 && detailData.status != 3"  @click="orderBtnUrl(detailData.status)">
                {{ orderStatusBtnText(detailData.status) }}
              </div>
            </div>
          </div>
          <div class="flex" style="padding-left: 24px;">
            <div class="index_c_item_title">
              <div class="flex">
                <!-- <div class="item_c_item_top_icon"></div> -->
              </div>
              <div class="flex" style="padding-bottom: 8px;">提交订单</div>
              <div class="flex" style="padding-bottom: 8px;">
                <div class="item_c_item_top_yuan_active"></div>
                <div :class="detailData.status >= 3 || detailData.status == 1 ? 'item_c_item_top_border_active' : 'item_c_item_top_border'"></div>
              </div>
              <!-- <div class="index_c_item_main" style="font-size: 12px; width: 100px;">2024-01-15 09:58:12</div> -->
            </div>
            <div :class="detailData.status >= 3 || detailData.status == 1 ? 'index_c_item_title' : 'index_c_item_main'">
              <div class="flex">
                <!-- <div class="item_c_item_top_icon"></div> -->
              </div>
              <div class="flex" style="padding-bottom: 8px;">付款</div>
              <div class="flex" style="padding-bottom: 8px;">
                <div :class="detailData.status >= 3 || detailData.status == 1 ? 'item_c_item_top_yuan_active' : 'item_c_item_top_yuan'"></div>
                <div :class="detailData.status >= 3 || detailData.status == 1 ? 'item_c_item_top_border_active' : 'item_c_item_top_border'"></div>
              </div>
              <!-- <div class="index_c_item_main" style="font-size: 12px; width: 100px;">2024-01-15 09:58:12</div> -->
            </div>
            <div :class="detailData.status >= 4 || detailData.status == 3 ? 'index_c_item_title' : 'index_c_item_main'">
              <div class="flex">
                <!-- <div class="item_c_item_top_icon"></div> -->
              </div>
              <div class="flex" style="padding-bottom: 8px;">待评价</div>
              <div class="flex" style="padding-bottom: 8px;">
                <div :class="detailData.status >= 4 || detailData.status == 3 ? 'item_c_item_top_yuan_active' : 'item_c_item_top_yuan'"></div>
                <div :class="detailData.status == 3 ? 'item_c_item_top_border_active' : 'item_c_item_top_border'"></div>
              </div>
              <!-- <div class="index_c_item_main" style="font-size: 12px; width: 100px;">2024-01-15 09:58:12</div> -->
            </div>
            <div :class="detailData.status == 3 ? 'index_c_item_title' : 'index_c_item_main'">
              <div class="flex">
                <!-- <div class="item_c_item_top_icon"></div> -->
              </div>
              <div class="flex" style="padding-bottom: 8px;">完成</div>
              <div class="flex" style="padding-bottom: 8px;">
                <div :class="detailData.status == 3 ? 'item_c_item_top_yuan_active' : 'item_c_item_top_yuan'"></div>
                <div :class="detailData.status == 3 ? 'item_c_item_top_border_active' : 'item_c_item_top_border'"></div>
              </div>
              <!-- <div class="index_c_item_main" style="font-size: 12px; width: 100px;">2024-01-15 09:58:12</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="index_c_item">
        <div class="index_c_item_main" style="padding-bottom: 12px">
          {{ detailData.institutionName }}
        </div>
        <div class="flex">
          <div class="">
            <el-image :src="detailData.courseCoverImage" style="width: 56px; height: 56px; border-radius: 8px;margin-right: 12px" fit="cover"></el-image>
          </div>
          <div style="width: 95%">
            <div class="index_c_item_title" style="padding-bottom: 4px">
              {{ detailData.courseName }}
            </div>
            <div class="flex-ju-sb">
              <div class="main">
                <span v-for="item in detailData.tabs" :key="item">{{ item }}</span>
              </div>
              <div class="price">￥{{ detailData.price }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="index_c_item">
        <div class="flex-ju-sb" style="padding-bottom: 16px">
          <div class="flex">
            <div class="yuan"></div>
            <div class="index_c_item_title">学生信息</div>
          </div>
        </div>
        <div style="padding-bottom: 16px">
          <div class="item_title" style="padding-bottom: 8px"> <span class="main">学生姓名：</span> {{ student.name }}</div>
          <div class="item_title" style="padding-bottom: 8px"> <span class="main">性别：</span> {{ student.gender == 0 ? '男' : '女' }}</div>
          <div class="item_title" style="padding-bottom: 8px"> <span class="main">证件类型：</span> {{ student.idCardType }}</div>
          <div class="item_title" style="padding-bottom: 8px"> <span class="main">证件号码：</span> {{ hideIdCard(student.idCard) }}</div>
        </div>
        <div style="border-bottom: 1px solid #f5f5f5;"></div>
        <div class="flex" style="padding-bottom: 16px;padding-top: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">监护人信息</div>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="guardian.name">
          <div class="item_title" style="min-width: 70px; text-align: right;">
            <span class="main">姓名：</span>{{ guardian.name }}
          </div>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="guardian.idCard">
          <div class="item_title"> <span class="main">证件号码：</span> {{ guardian.idCard }}</div>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="guardian.contact">
          <div class="item_title"> <span class="main">联系电话：</span> {{ guardian.contact }}</div>
        </div>
        <div class="flex" v-if="guardian.address">
          <div class="item_title"> <span class="main">居住地址：</span> {{ guardian.address }}</div>
        </div>
         <div style="border-bottom: 1px solid #f5f5f5;padding-bottom: 16px;"></div>
         <div class="flex" style="padding-bottom: 16px;padding-top: 16px" v-if="emergencyContact.name && emergencyContact.contact">
          <div class="yuan"></div>
          <div class="index_c_item_title">紧急联系人信息</div>
        </div>
        <div class="flex" style="padding-bottom: 16px">
          <div class="item_title"  style="min-width: 70px; text-align: right;" v-if="emergencyContact.name">
            <span class="main">姓名：</span> {{ emergencyContact.name }}
          </div>
        </div>
        <div class="flex" v-if="emergencyContact.contact">
          <div class="item_title"> <span class="main">联系电话：</span> {{ emergencyContact.contact }}</div>
        </div>
      </div>

      <div class="index_c_item" v-if="detailData.hukouImage && detailData.certificatesImage">
        <div class="flex" style="padding-bottom: 16px;padding-top: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">相关资料</div>
        </div>
        <div class="flex-ju-sb" style="padding-bottom: 16px" v-if="detailData.hukouImage">
          <div class="item_title">户口本电子版</div>
          <div class="flex" style="cursor: pointer;" @click="imageUrl = detailData.hukouImage, centerDialogVisible = true">
            <div class="main">查看</div>
            <i class="el-icon-arrow-right" style="font-weight: bold; color: #999;padding-left: 8px"></i>
          </div>
        </div>
        <div class="flex-ju-sb" v-if="detailData.certificatesImage">
          <div class="item_title">房产证电子版</div>
          <div class="flex" style="cursor: pointer;" @click="imageUrl = detailData.certificatesImage, centerDialogVisible = true">
            <div class="main">查看</div>
            <i class="el-icon-arrow-right" style="font-weight: bold; color: #999;padding-left: 8px"></i>
          </div>
        </div>
      </div>
      <!-- <div class="index_c_item">
        <div class="flex" style="padding-bottom: 16px;padding-top: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">合同文件</div>
        </div>
        <div class="flex-ju-sb">
          <div class="item_title">合同电子版</div>
          <div class="flex" style="cursor: pointer;">
            <div class="main">查看</div>
            <i class="el-icon-arrow-right" style="font-weight: bold; color: #999;padding-left: 8px"></i>
          </div>
        </div>
      </div> -->
      <!-- <div class="index_c_item">
        <div class="flex" style="padding-bottom: 16px;padding-top: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">购买须知</div>
        </div>
      </div> -->
    </div>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
    <el-dialog :visible.sync="centerDialogVisible" width="30%" :show-close="false">
      <el-image :src="imageUrl"></el-image>
    </el-dialog>
    <el-dialog :visible.sync="payShow" width="30%" :show-close="false">
      <div class="index_c_item">
        <div class="flex-ju-sb" style="padding-bottom: 20px; cursor: pointer;" v-for="(item, index) in payList" :key="index" @click="payTypeClick(item.id)">
          <div class="flex">
            <el-image :src="item.icon" style="width: 45px; height: 45px; padding-right: 20px"></el-image>
            <span>{{ item.name }}</span>
          </div>
          <i
            v-if="item.id == payType"
            class="el-icon-success"
            style="color: #fdc570; font-size: 20px"
          ></i>
        </div>
      </div>
      <div class="flex-center">
        <div class="index_c_bottom">
          <div class="index_c_bottom_btn" @click="pay">
            立即支付
          </div>
        </div>
      </div>
    </el-dialog>
        <el-dialog
      :visible.sync="centerDialogVisible"
      width="30%"
      title="微信支付"
      center
      :show-close="false"
    >
      <div class="flex-center">
        <el-image :src="wxPayCodeUrl"></el-image>
		  </div>
    </el-dialog>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
import QRCode from 'qrcode'
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      sn: '',
      price: '',
      payType: 1,
      detailData: {},
      student: {},
      guardian: {},
      emergencyContact: {},
      payList: [
        {
          id: 1,
          name: '微信支付',
          icon: '/static/icon/wx.png'
        },
        {
          id: 0,
          name: '支付宝支付',
          icon: '/static/icon/zfb.png'
        }
      ],
      centerDialogVisible: false,
      payForm: '',
      imageUrl: '',
      payShow: false,
      centerDialogVisible: false,
      wxPayCodeUrl: '',
      payForm: '',
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    goBack() {
			this.$router.go(-1);
		},
    getInfo(sn) {
      this.$api.order.info({sn: sn}).then(res => {
        this.detailData = res
        this.student = this.detailData.student
        this.guardian = this.detailData.guardian
        this.emergencyContact = this.detailData.emergencyContact
      })
    },
    hideIdCard(idCard) {
      if (idCard) {
        return idCard.replace(/^(.{6})(?:\d+)(.{4})$/, '$1****$2');
      }
    },
    payTypeClick(type) {
      this.payType = type
    },
    pay() {
      this.$api.home.payOrder({
        sn: this.sn,
        platform: 2,
        payType: this.payType
      }).then(res => {
        // this.centerDialogVisible = true
        this.payForm = res.data
        const div = document.createElement("divform");
        div.innerHTML = res.data;
        document.body.appendChild(div);
        document.forms[0].submit();
      })
    },
    orderStatusText(val) {
      switch (val) {
        case 0:
          return "待付款";
        case 1:
          return "已付款";
        case 2:
          return "已取消";
        case 3:
          return "已完成";
        case 4:
          return "待评价";
      }
    },
    orderStatusBtnText(val) {
      switch (val) {
        case 0:
          return "立即付款";
        case 1:
          return "申请售后";
        case 4:
          return "评价";
      }
    },
    orderBtnUrl(val) {
      switch (val) {
        case 0:
          return this.payShow = true
          // return this.$router.push('/pay?institutionId=' + this.detailData.institutionId + '&courseId=' + this.detailData.courseId);
        case 1:
          return this.$router.push('/sales?data=' + JSON.stringify(this.detailData));
        case 4:
          return this.$router.push({
            path: '/evaluate',
            query: {
              data: JSON.stringify(this.detailData)
            }
          });
      }
    },
    payTypeClick(type) {
      this.payType = type
    },
    pay() {
      this.$api.home.payOrder({
        sn: this.sn,
        platform: 2,
        payType: this.payType
      }).then(res => {
        if (this.payType == 0) {
          this.payForm = res.data
          const div = document.createElement("divform");
          div.innerHTML = res.data;
          document.body.appendChild(div);
          document.forms[0].submit();
        } else {
          this.centerDialogVisible = true
          const qrCodeCanvas = document.createElement("canvas");
          qrCodeCanvas.width = 200;
          qrCodeCanvas.height = 200;
          QRCode.toCanvas(qrCodeCanvas, res.data.codeUrl, {width: 200, height: 200},(error) => {
            if (error) {
              console.log(error);
            } else {
              const url = qrCodeCanvas.toDataURL("image/png")
              this.wxPayCodeUrl = url
              this.setWxInterval = setInterval(() => {
                this.getOrderStatus()
              }, 1000);
            }
          })
        }
      })
    },
    getOrderStatus() {
      this.$api.order.getStatus({sn: this.sn}).then(res => {
        if (res.status == 1) {
          this.$message.success('支付成功')
          clearInterval(this.setWxInterval)
          setTimeout(() => {
            this.$router.push('/pay_detail?sn=' + this.sn);
          }, 800)
        }
      })
    },
  },
  mounted() {
    this.sn = this.$route.query.sn;
    this.getInfo(this.sn);
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  padding: 80px 350px;
}
.title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  padding-bottom: 16px;
}
.index_c_item {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
  overflow: hidden;
}
.index_c_item_cover {
  width: 56px;
  height: 56px;
  border-radius: 8px 8px 8px 8px;
  margin-right: 12px;
}
.index_c_item_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #999999;
}
.index_c_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.price {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 20px;
  color: #f95620;
}
.user_top {
  padding: 12px 16px;
  background-color: #f5f5f5;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_item {
  padding: 12px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.index_c_item_top_btn {
  background: #fdc570;
  border-radius: 366px 366px 366px 366px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  width: 104px;
  height: 40px;
  background: #FDC570;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.status {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #f95620;
}
.index_c_item_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 16px;
}
.index_c_item_list_item {
  width: 88px;
  height: 88px;
  background: #f5f5f5;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  margin-bottom: 9px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
  position: relative;
}
.index_c_item_list_item_close {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fdc570;
  font-size: 20px;
}
.tips {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #f95620;
}
.order_item_status {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #f95620;
}
.upload_item {
  width: 388px;
  padding: 14px 12px;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.upload_icon {
  width: 24px;
  height: 24px;
  background-color: #000;
  margin-right: 8px;
}
.upload_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.item_title_1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #fdc570;
  padding-left: 16px;
}
.index_c_bottom_item {
  background: #fafafa;
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.index_c_bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333;
}
.radio {
  width: 16px;
  height: 16px;
  border-radius: 75px 75px 75px 75px;
  border: 1px solid #dddddd;
  cursor: pointer;
  margin-right: 8px;
}
.index_c_bottom_btn {
  width: 216px;
  height: 48px;
  background: #fdc570;
  border-radius: 329px 329px 329px 329px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.index_c_bottom_coupon {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.index_c_bottom_coupon_item_top {
  width: 264px;
  padding: 12px 16px;
  box-sizing: border-box;
  background: #f95620;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}
.coupon_check {
  position: absolute;
  top: 10px;
  right: 20px;
}
.index_c_bottom_coupon_item_bottom {
  width: 264px;
  padding: 12px 12px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #999999;
}
.dialog_tab {
  display: flex;
  align-items: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #999999;
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
}
.dialog_tab div {
  cursor: pointer;
  margin-right: 24px;
  padding-bottom: 10px;
}
.dialog_tab .tab_active {
  border-bottom: 4px solid #fdc570;
  padding-bottom: 10px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}
.dialog_content {
  margin-top: 16px;
  word-break: break-all;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  /* color: #9e9e9e; */
  margin-bottom: 24px;
}
.dialog_btn {
  width: 298px;
  height: 48px;
  background: #f5f5f5;
  border-radius: 329px 329px 329px 329px;
  text-align: center;
  line-height: 48px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #cccccc;
  cursor: pointer;
}

.index_c_item_back {
	background: #f5f5f5;
	border-radius: 366px 366px 366px 366px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 8px 24px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #333333;
	cursor: pointer;
}
.item_c_item_top_icon {
  width: 24px;
  height: 24px;
  background: #000;
  margin-bottom: 8px;
}
.item_c_item_top_yuan {
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 2px solid #eee;
  border-radius: 50%;
}
.item_c_item_top_border {
  width: 226px;
  height: 2px;
  background: #EEEEEE;
}

.item_c_item_top_yuan_active {
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 2px solid #FDC570;
  border-radius: 50%;
}
.item_c_item_top_border_active {
  width: 226px;
  height: 2px;
  background: #FDC570;
}
</style>
