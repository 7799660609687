<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="0"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="flex-end" style="padding: 18px 0">
        <div class="flex">
          <div class="btn" @click="goBack">返回</div>
        </div>
      </div>
      <div class="index_c_item">
        <div class="login_box">
					<div v-if="!authUrl">
						<div class="flex-center">
						<!-- <div class="login_box_logo"></div> -->
						<el-image src="/static/logo.png" style="width: 300px;" fit="contain" class="login_box_logo"></el-image>
					</div>
					<div class="flex-center">
						<input type="text" maxlength="11" oninput="value=value.replace(/^0(0+|\d+)|[^\d]+/g,'')" v-model="params.phone" placeholder="请输入手机号码" class="login_input" />
					</div>
					<div class="flex-center" style="padding: 0 35px">
						<input type="text" v-model="verifyCode" placeholder="请输入图形验证码" class="code_input" />
						<el-image :src="captchaimg" style="border-radius: 8px; cursor: pointer; width: 90px; height: 50px" @click="getcaptcha()"></el-image>
					</div>
					<div class="flex-center" style="padding: 0 35px">
						<input type="text" v-model="params.code" placeholder="请输入验证码" class="code_input" />
						<el-button class="code_btn" :disabled="countdown > 0" @click="startCountdown">
							{{ countdown > 0 ? '已发送' + countdown + '秒' : '发送验证码' }}
						</el-button>
					</div>
					<div class="flex-center">
						<input type="text" placeholder="请输入推荐码（选填)" class="login_input" v-model="params.inviterCode" />
					</div>
					<div class="flex-center">
						<div style="cursor: pointer">
							<div class="check" v-if="!check" @click="check = !check"></div>
							<i @click="check = !check" v-if="check" class="el-icon-success" style="color: #fdc570; font-size: 20px"></i>
						</div>
						<div class="xiyi">
							已仔细阅读并同意
							<span @click="goUrl('/xieyi?type=register&title=用户协议')">《用户协议》</span>
							<span @click="goUrl('/xieyi?type=privacyPolicy&title=隐私协议')">《隐私政策》</span>
						</div>
					</div>
					<div class="login_btn" @click="login">登录</div>
					<div class="more_login">
						<div class="line"></div>
						<div>其他登录</div>
						<div class="line"></div>
					</div>
				</div>
				<div v-else>
					<div class="flex-end">
						<div class="back_btn" @click="authUrl = ''">返回</div>
					</div>
					<div class="flex-center">
						<canvas id="QRCode"></canvas>
					</div>
				</div>
				<div class="flex-ju-sb" style="justify-content: space-evenly">
					<el-image @click="loginInfo('weixin')" src="/static/icon/weixin.png" style="width: 45px; height: 45px" class="login_icon"></el-image>
					<el-image @click="loginInfo('qq')" src="/static/icon/QQ.png" style="width: 45px; height: 45px" class="login_icon"></el-image>
					<el-image @click="loginInfo('weibo')" src="/static/icon/weibo.png" style="width: 45px; height: 45px" class="login_icon"></el-image>
				</div>
			</div>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
			token: false,
			captchaimg: '',
			userData: {},
			params: {
				cid: '',
			},
			check: false,
			keywords: null,
			countdown: 0,
			timer: null,
			time: null,
			openId: '',
			areaId: '',
			inputKey: '',
			searchTimeout:null,//搜索防抖
			searchList: [],
			authUrl: '',
			userInfo: '',
			verifyCode: ''
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    goBack() {
			if (localStorage.getItem('bupaolu_roter')) {
				this.$router.go(-2);
				localStorage.removeItem('bupaolu_roter')
			} else {
				this.$router.go(-1);
			}
    },
		funyuming(msg) {
			this.$router.push(msg);
		},
		funRouter(index, url) {
			this.tabIdx = index;
			this.$router.push(url);
		},
		goUrl(url) {
			this.$router.push(url);
		},
		startCountdown() {
			this.countdown = 59;
			this.timer = setInterval(() => {
				if (this.countdown > 0) {
					this.countdown--;
				} else {
					clearInterval(this.timer);
					this.timer = null;
				}
			}, 1000);
			this.goCode();
		},
		goCode() {
			let params = {
				phone: this.params.phone,
				verifyCode: this.verifyCode,
				timestamp: 'pc_login' + this.time,
				type: 1
			};
			this.$api.login.sendCode(params).then(res => {
				this.$message.success(res);
			}).catch(err => {
				clearInterval(this.timer);
				this.timer = null;
				this.countdown = 0;
			})
		},
		// 获取验证码图片
		getcaptcha() {
			this.time = Number(new Date());
			this.captchaimg = this.$api.login.code('pc_login' + this.time);
		},
		login() {
			if (!this.check) {
				return this.$message.error('请先勾选协议');
			}
			if (!this.params.phone) {
				return this.$message.error('请输入手机号码');
			}
			if (!this.params.code) {
				return this.$message.error('请输入验证码');
			}
			this.$api.login.byMobile(this.params).then(res => {
				this.token = true;
				// this.$setCookie('token', res.token)
				setTimeout(() => {
					localStorage.setItem('bupaolu_token', res.token);
					localStorage.setItem('bupaolu_login', JSON.stringify(res));
					localStorage.setItem('bupaolu_bind', true)
					return this.$router.push('/')
				}, 0)
			});
		},
		loginOut() {
			localStorage.removeItem('bupaolu_token');
			localStorage.removeItem('bupaolu_login');
			localStorage.removeItem('bupaolu_userInfo');
			this.token = false;
			this.centerDialogVisible = true;
		},
		getUserInfo() {
			this.$api.login.baseInfo().then(res => {
				this.userData = res;
				localStorage.setItem('bupaolu_userInfo', JSON.stringify(res));
				this.token = true;
			});
		},
		loginInfo(type) {
			if (!this.check) {
				return this.$message.error('请先勾选协议');
			}
			localStorage.setItem('loginType', type)
			this.$api.login.authUrl(type).then(res => {
				setTimeout(() => {
					// 跳转页面
					localStorage.setItem('bupaolu_bind', true)
					window.location.href = res;
				}, 100)
			})
		},
		removeQueryParamAndRefresh(paramToRemove) {
		  // 获取当前URL
		  const currentUrl = new URL(window.location.href);
		  // 删除指定的查询参数
		  currentUrl.searchParams.delete(paramToRemove);
		  // 用修改后的URL替换当前URL，不在历史记录中创建新条目
		  window.history.replaceState({}, '', currentUrl.toString());
		  // 刷新页面
		  window.location.reload();
		}
  },
  mounted() {
		if (this.$route.query.token) {
			localStorage.setItem('bupaolu_token', this.$route.query.token);
			localStorage.setItem('bupaolu_bind', true)
			this.removeQueryParamAndRefresh('token')
		}
		if (localStorage.getItem('bupaolu_token')) {
			localStorage.setItem('bupaolu_bind', true)
			return this.$router.push('/')
		}
		this.getcaptcha();
	},
  created() {},
};
</script>
<style lang="scss" scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c {
	padding: 30px 700px;
}
.index_c_item {
	background: #ffffff;
	border-radius: 12px 12px 12px 12px;
	padding: 24px;
	box-sizing: border-box;
	margin-bottom: 24px;
	cursor: pointer;
	margin-right: 24px;
}
.nav_top_left,
.nav_top_right {
	display: flex;
	align-items: center;
}
.nav_top_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
}
.nav_top_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #fdc570;
	cursor: pointer;
}
.nav_bar {
	height: 80px;
	background: #ffffff;
	padding: 0 300px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.tab_list {
	display: flex;
	align-items: center;
}
.tab_item_active {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	cursor: pointer;
	margin-right: 68px;
	position: relative;
	width: 50px;
}
.tab_item_active::before {
	position: absolute;
	bottom: -10px;
	left: -8px;
	content: '';
	width: 48px;
	height: 4px;
	background: #fdc570;
	border-radius: 150px 150px 150px 150px;
}
.tab_item {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #999999;
	cursor: pointer;
	margin-right: 68px;
	width: 50px;
}
.logo {
	// width: 132px;
	// height: 17px;
	// background: #333333;
	margin-right: 56px;
}
.input_box {
	width: 240px;
	background: #f5f5f5;
	border-radius: 277px 277px 277px 277px;
	margin-right: 8px;
}
.input_box input {
	border: none;
	width: 100%;
	background: #f5f5f5;
	box-sizing: border-box;
}
.input_box input::placeholder {
	padding: 0 8px;
}
.sumbit_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 20px;
	background: #fdc570;
	border-radius: 277px 277px 277px 277px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
.sumbit_btn_add {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	background: #fdc570;
	border-radius: 277px 277px 277px 277px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
.login_box {
	padding: 20px 0;
}
.login_box_logo {
	margin-bottom: 24px;
}
.btn {
	background: #ffffff;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	padding: 8px 24px;
	margin-right: 16px;
	cursor: pointer;
}
.login_input {
	width: 360px;
	height: 46px;
	border-radius: 0px 0px 0px 0px;
	border: none;
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 16px;
}
.code_input {
	width: 258px;
	height: 46px;
	border-radius: 0px 0px 0px 0px;
	border: none;
	border-bottom: 1px solid #f5f5f5;
	margin-right: 10px;
	margin-bottom: 16px;
}
.code_btn {
	padding: 12px 10px;
	background: #fdc570;
	border-radius: 8px 8px 8px 8px;
	text-align: center;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
.xiyi {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	padding-left: 8px;
}
.xiyi span {
	color: #81d3f8;
	cursor: pointer;
}
.login_btn {
	background: #fdc570;
	border-radius: 442px 442px 442px 442px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #ffffff;
	padding: 13px;
	text-align: center;
	margin: 0 35px;
	cursor: pointer;
	margin-top: 12px;
	margin-bottom: 24px;
}
.more_login {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
}
.line {
	width: 130px;
	height: 0px;
	border: 1px solid #f5f5f5;
	margin: 0 20px;
}
.login_icon {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	cursor: pointer;
}
</style>
