import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import sumbit from "../views/sumbit.vue";
import detail from "../views/detail.vue";
import teacherDetail from "../views/teacher_detail.vue";
import community from "../views/community.vue";
import communityDetail from "../views/communityDetail.vue";
import subject from "../views/subject.vue";
import msg from "../views/msg.vue";
import help from "../views/help.vue";
import helpDetail from "../views/helpDetail.vue";
import course from "../views/course.vue";
import pay from "../views/pay.vue";
import community_sumbit from "../views/community_sumbit.vue";
import user_community from "../views/user_community.vue";
import userinfo from "../views/userinfo.vue";
import user from "../views/user.vue";
import chlid from "../views/chlid.vue";
import system from "../views/system.vue";
import xieyi from "../views/xieyi.vue";
import map from "../views/map.vue";
import evaluate from "../views/evaluate.vue";
import sales from "../views/sales.vue";
import payInfo from "../views/payInfo.vue";
import pay_detail from "../views/pay_detail.vue";
import login from "../views/login.vue";
import phoneBind from "../views/phoneBind.vue";
import demo from "../views/demo.vue";

import { MessageBox, Message } from 'element-ui'

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "index",
		component: index,
		meta: {
			title: "名谱网络-不跑鹿平台-首页",
		},
	},
	{
		path: "/sumbit",
		name: "sumbit",
		component: sumbit,
		meta: {
			title: "名谱网络-不跑鹿平台-搜索",
		},
	},
	{
		path: "/detail",
		name: "detail",
		component: detail,
		meta: {
			title: "名谱网络-不跑鹿平台-详情",
		},
	},
	{
		path: "/teacher_detail",
		name: "teacherDetail",
		component: teacherDetail,
		meta: {
			title: "名谱网络-不跑鹿平台-教师详情",
		},
	},
	{
		path: "/community",
		name: "community",
		component: community,
		meta: {
			title: "名谱网络-不跑鹿平台-社区",
		},
	},
	{
		path: "/community_sumbit",
		name: "community_sumbit",
		component: community_sumbit,
		meta: {
			title: "名谱网络-不跑鹿平台-社区搜索",
		},
	},
	{
		path: "/communityDetail",
		name: "communityDetail",
		component: communityDetail,
		meta: {
			title: "名谱网络-不跑鹿平台-社区详情",
		},
	},
	{
		path: "/user_community",
		name: "user_community",
		component: user_community,
		meta: {
			title: "名谱网络-不跑鹿平台-用户主页",
		},
	},
	{
		path: "/subject",
		name: "subject",
		component: subject,
		meta: {
			title: "名谱网络-不跑鹿平台-社区发布",
		},
	},
	{
		path: "/msg",
		name: "msg",
		component: msg,
		meta: {
			title: "名谱网络-不跑鹿平台-消息",
		},
	},
	{
		path: "/help",
		name: "help",
		component: help,
		meta: {
			title: "名谱网络-不跑鹿平台-帮助中心",
		},
	},
	{
		path: "/helpDetail",
		name: "helpDetail",
		component: helpDetail,
		meta: {
			title: "名谱网络-不跑鹿平台-帮助详情",
		},
	},
	{
		path: "/course",
		name: "course",
		component: course,
		meta: {
			title: "名谱网络-不跑鹿平台-课程详情",
		},
	},
	{
		path: "/pay",
		name: "pay",
		component: pay,
		meta: {
			title: "名谱网络-不跑鹿平台-下单页",
		},
	},
	{
		path: "/userinfo",
		name: "userinfo",
		component: userinfo,
		meta: {
			title: "名谱网络-不跑鹿平台-个人中心",
		},
	},
	{
		path: "/user",
		name: "user",
		component: user,
		meta: {
			title: "名谱网络-不跑鹿平台-个人信息",
		},
	},
	{
		path: "/chlid",
		name: "chlid",
		component: chlid,
		meta: {
			title: "名谱网络-不跑鹿平台-关联孩子",
		},
	},
	{
		path: "/system",
		name: "system",
		component: system,
		meta: {
			title: "名谱网络-不跑鹿平台-安全设置",
		},
	},
	{
		path: "/xieyi",
		name: "xieyi",
		component: xieyi,
		meta: {
			title: "名谱网络-不跑鹿平台-协议",
		},
	},
	{
		path: "/map",
		name: "map",
		component: map,
		meta: {
			title: "名谱网络-不跑鹿平台-地图",
		},
	},
	{
		path: "/evaluate",
		name: "evaluate",
		component: evaluate,
		meta: {
			title: "名谱网络-不跑鹿平台-评价",
		},
	},
	{
		path: "/sales",
		name: "sales",
		component: sales,
		meta: {
			title: "名谱网络-不跑鹿平台-申请售后",
		},
	},
	{
		path: "/payInfo",
		name: "payInfo",
		component: payInfo,
		meta: {
			title: "名谱网络-不跑鹿平台-支付详情",
		},
	},
	{
		path: "/pay_detail",
		name: "pay_detail",
		component: pay_detail,
		meta: {
			title: "名谱网络-不跑鹿平台-订单详情",
		},
	},
	{
		path: "/login",
		name: "login",
		component: login,
		meta: {
			title: "名谱网络-不跑鹿平台-登录",
		},
	},
	{
		path: "/phoneBind",
		name: "phoneBind",
		component: phoneBind,
		meta: {
			title: "名谱网络-不跑鹿平台-绑定手机号码",
		},
	},
	{
		path: "/demo",
		name: "demo",
		component: demo,
		meta: {
			title: "名谱网络-不跑鹿平台-demo",
		},
	}
];
// title

const router = new VueRouter({
	mode: 'history',
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "名谱网络-不跑鹿平台";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});