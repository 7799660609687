<!-- 七牛云照片墙上传 -->
<template>
  <div>
    <el-upload
      action="https://upload-z2.qiniup.com/"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="uploadSuccess"
      :before-upload="beforeUpload"
      list-type="picture-card"
      :file-list="ImageUrls"
      style="display: flex; position: relative;flex-wrap: wrap"
      class="upload"
      :limit="limit"
    >
      <i class="el-icon-plus" />
      <div style="position: absolute; bottom: 10px; font-size: 12px">上传图片</div>
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <!-- <img width="100%" :src="ImageUrl" alt="" class="myimage"/> -->
      <el-image
        style="width: 400px; height: 400px"
        :src="ImageUrl"
        fit="fill"
      />
    </el-dialog>
  </div>
</template>
<script>
import * as qiniu from 'qiniu-js'
import environment from '@/utils/environment'
export default {
  props: {
    width: {
      type: Number,
      default: 50
    },
    images: {
      type: Array,
      default() {
        return []
      }
    },
    limit: {
      type: Number,
      default: 99
    }
  },
  data() {
    return {
      // baseUrl: `${process.env.VUE_APP_BASE_URL}/service-admin/admin/file/upload`,
      uploadData: { token: '', key: '' },
      qiniuData: {
        token: '',
        domain: ''
      },
      baseUrl: '',
      ImageUrls: [],
      dialogVisible: false,
      dialogVisible2: false, // 视频
      isVideo: true,
      ImageUrl: '',
      video: false
    }
  },
  mounted() {
    this.baseUrl = environment.upLoadPath()
    this.getimages()
  },
  methods: {
    // 移除照片
    handleRemove(file, fileList) {
      var index = this.ImageUrls.findIndex((item) => item.url == file.url)
      if (index != -1) {
        this.ImageUrls.splice(index, 1)
      }
      if (file.name == 'video') {
        this.video = false
      }
      this.Mychage(this.ImageUrls)
    },
    // 预览照片
    handlePictureCardPreview(file) {
      this.ImageUrl = file.url
      if (this.ImageUrl.includes('mp4') || this.ImageUrl.includes('MOV')) {
        this.dialogVisible2 = true
      } else {
        this.dialogVisible = true
      }
    },
    // 上传成功函数
    uploadSuccess(response, file, fileList) {},
    // 加载图片
    getimages() {
      this.ImageUrls = []
      this.images.forEach((item) => {
        var params = {
          name: 'draw.jpg',
          url: item
        }
        this.ImageUrls.push(params)
      })
    },
    init(url) {
      console.log(url)
      this.ImageUrls = url
    },
    // 更改图片回调
    Mychage(items) {
      var _images = []
      items.forEach((item) => {
        _images.push(item.url)
      })
      this.$emit('changeImages', _images)
    },
    // 上传前
    beforeUpload(file, fileList) {
      const size = Math.floor(file.size / 1024)
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        this.$message.error('请选择png或者jpg格式的文件')
        return false
      }
      this.qiniuYunGetToken(file, file.type)
    },
    getKey(url) {
      //   命名规则default_时间戳.图片后缀格式，例如default_1609330164723140.png
      const ctime = new Date().getTime()
      return (
        'default_' +
        ctime +
        '.' +
        url.name.split('.')[url.name.split('.').length - 1]
      )
    },
    // 获取七牛云token
    qiniuYunGetToken(file, type) {
      // try {
      this.$api.common
        .getToken()
        .then((res) => {
          this.qiniuData.token = res.token
          this.qiniuData.domain = res.domain
          this.uploadFiles(file, this.qiniuData.token, this.qiniuData.domain, type)
        })
        .catch((err) => {
          this.$message.error(err || '服务器错误，请稍后重试')
        })
        .finally(() => {
          this.baseInfoLoading = false
        })
      // 上传七牛云

      // } catch (error) {}
    },
    // eslint-disable-next-line vue/no-dupe-keys
    getKey(url) {
      //   命名规则default_时间戳.图片后缀格式，例如default_1609330164723140.png
      const ctime = new Date().getTime()
      return (
        'default_' +
        ctime +
        '.' +
        url.name.split('.')[url.name.split('.').length - 1]
      )
    },
    // 上传七牛云
    uploadFiles(uploadtempFiles, qiniuyuntoken, domain, type) {
      const putExtra = {}
      const config = {}
      var that = this
      // 随机名字
      const key = this.getKey(uploadtempFiles)
      console.log(key)
      // 获取七牛云token
      const token = qiniuyuntoken
      //   console.log("uploadtempFiles", uploadtempFiles);
      //   console.log("key", key);
      //   console.log("token", qiniuyuntoken);
      var observable = qiniu.upload(
        uploadtempFiles,
        key,
        token,
        putExtra,
        config
      )
      var observer = {
        next(res) {},
        error(err) {
          console.log(err)
        },
        complete(res) {
          console.log(res)
          var item = {
            name: type === 'video/mp4' ? 'video' : 'draw.jpg',
            url: domain + '/' + res.key + '?vframe/jpg/offset/0'
          }
          that.ImageUrls.push(item)
          that.Mychage(that.ImageUrls)
          // localStorage.setItem("qiniuImage", "");
          // localStorage.setItem("qiniuImage", domain + "/" + res.key);
        }
      }
      var subscription = observable.subscribe(observer) // 上传开始
      this.$emit('changeImages')
      this.$emit('successPhoto', this.ImageUrls)
    }
  }
}
</script>
<style lang="scss" scoped>
.upload::v-deep {
  .el-upload--picture-card {
    width: 80px !important;
    height: 80px !important;
    // text-align: 1;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-upload-list {
    display: flex;
    flex-wrap: wrap;
    .el-upload-list__item {
      width: 80px !important;
      height: 80px !important;
    }
    .el-upload-list__item-thumbnail {
      width: 80px !important;
      height: 80px !important;
    }
    .el-upload-list__item-actions {
      font-size: 14px;
    }
  }
}
</style>
