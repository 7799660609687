<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="index_c_item">
				<div class="flex-end" style="padding-bottom: 24px">
					<div class="index_c_item_back" @click="goBack">返回</div>
				</div>
				<div class="index_c_item_title" style="padding-bottom: 24px">{{ title }}</div>
				<div class="flex-center">
					<div v-html="content"></div>
				</div>
				<!-- <div class="flex-center">
          <div class="index_c_item_cover"></div>
        </div>
				<div class="flex-center">
          <div class="index_c_item_main">富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本</div>
        </div>
				<div class="flex-center">
          <div class="index_c_item_cover"></div>
        </div>
				<div class="flex-center">
          <div class="index_c_item_main">富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本富文本</div>
        </div> -->
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			content: '',
			title: ''
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		goBack() {
			this.$router.go(-1);
		},
	},
	mounted() {
		this.content = this.$route.query.content;
		this.title = this.$route.query.title;
	},
	created() {},
};
</script>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c {
	padding: 80px 350px;
}
.index_c_item {
	background: #ffffff;
	border-radius: 16px 16px 16px 16px;
	padding: 24px;
}
.index_c_item_back {
	background: #f5f5f5;
	border-radius: 366px 366px 366px 366px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 8px 24px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #333333;
	cursor: pointer;
}
.index_c_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 24px;
	color: #333333;
	text-align: center;
}
.index_c_item_cover {
	width: 756px;
	height: 277px;
	background: #d8d8d8;
	border-radius: 0px 0px 0px 0px;
	margin-bottom: 16px;
}
.index_c_item_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #9e9e9e;
  width: 756px;
	padding-bottom: 16px;
}
</style>
