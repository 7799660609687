<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="1"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="title flex-ju-sb">
        <div>订单信息填充</div>
        <div class="index_c_item_back" @click="goBack">返回</div>
      </div>
      <div class="index_c_item">
        <div class="index_c_item_main" style="padding-bottom: 12px">
          {{ detailData.institutionName }}
        </div>
        <div class="flex">
          <div class="index_c_item_cover">
            <el-image :src="detailData.courseCoverImage" style="width: 56px; height: 56px; border-radius: 8px" fit="cover"></el-image>
          </div>
          <div style="width: 95%">
            <div class="index_c_item_title" style="padding-bottom: 4px">
              {{ detailData.courseName }}
            </div>
            <div class="flex-ju-sb">
              <div class="main">
                <span v-for="item in detailData.tabs" :key="item">{{ item }}</span>
              </div>
              <div class="price">￥{{ detailData.price }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="index_c_item" v-if="detailData.student.name">
        <div class="flex-ju-sb" style="padding-bottom: 16px">
          <div class="flex">
            <div class="yuan"></div>
            <div class="index_c_item_title">学生信息</div>
          </div>
        </div>
        <div class="user_top">
          <div>学生姓名</div>
          <div>证件号码</div>
          <div></div>
        </div>
        <div class="user_item">
          <div>{{ detailData.student.name }}</div>
          <div>{{ hideIdCard(detailData.student.idCard) }}</div>
          <div class="status"></div>
        </div>
      </div>
      <div class="index_c_item" v-if="detailData.guardian.name">
        <div class="flex" style="padding-bottom: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">监护人信息</div>
        </div>
        <div class="flex" style="padding-bottom: 16px">
          <div class="item_title" style="min-width: 70px; text-align: right;" v-if="detailData.guardian.name">
            姓名：{{ detailData.guardian.name }}
          </div>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="detailData.guardian.idCard">
          <div class="item_title">证件号码：{{ detailData.guardian.idCard }}</div>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="detailData.guardian.contact">
          <div class="item_title">联系电话：{{ detailData.guardian.contact }}</div>
        </div>
        <div class="flex" v-if="detailData.guardian.address">
          <div class="item_title">居住地址：{{ detailData.guardian.address }}</div>
        </div>
      </div>
      <div class="index_c_item" v-if="detailData.emergencyContact.name && detailData.emergencyContact.contact">
        <div class="flex" style="padding-bottom: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">紧急联系人信息</div>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="detailData.emergencyContact.name">
          <div class="item_title" style="min-width: 70px; text-align: right;">
            姓名：{{ detailData.emergencyContact.name }}
          </div>
        </div>
        <div class="flex" v-if="detailData.emergencyContact.contact">
          <div class="item_title">联系电话：{{ detailData.emergencyContact.contact }}</div>
        </div>
      </div>
      <div class="index_c_item" v-if="detailData.hukouImage && detailData.certificatesImage">
        <div class="flex" style="padding-bottom: 16px;padding-top: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">相关资料</div>
        </div>
        <div class="flex-ju-sb" style="padding-bottom: 16px" v-if="detailData.hukouImage">
          <div class="item_title">户口本电子版</div>
          <div class="flex" style="cursor: pointer;" @click="imageUrl = detailData.hukouImage, centerDialogVisible1 = true">
            <div class="main">查看</div>
            <i class="el-icon-arrow-right" style="font-weight: bold; color: #999;padding-left: 8px"></i>
          </div>
        </div>
        <div class="flex-ju-sb" v-if="detailData.certificatesImage">
          <div class="item_title">房产证电子版</div>
          <div class="flex" style="cursor: pointer;" @click="imageUrl = detailData.certificatesImage, centerDialogVisible1 = true">
            <div class="main">查看</div>
            <i class="el-icon-arrow-right" style="font-weight: bold; color: #999;padding-left: 8px"></i>
          </div>
        </div>
      </div>
      <div class="index_c_item">
        <div class="flex-ju-sb" style="padding-bottom: 20px; cursor: pointer;" v-for="(item, index) in payList" :key="index" @click="payTypeClick(item.id)">
          <div class="flex">
            <el-image :src="item.icon" style="width: 45px; height: 45px; padding-right: 20px"></el-image>
            <span>{{ item.name }}</span>
          </div>
          <i
            v-if="item.id == payType"
            class="el-icon-success"
            style="color: #fdc570; font-size: 20px"
          ></i>
        </div>
        <!-- <el-image src="/static/icon/zfb.png" style="width: 45px; height: 45px" class="login_icon"></el-image> -->
      </div>
      <div class="index_c_item">
        <div class="index_c_bottom_item">
          <div class="main">应付金额：</div>
          <div class="price" style="font-size: 24px">￥{{ detailData.price }}</div>
        </div>
        <div class="index_c_bottom">
          <!-- <div class="flex">
            <i
              class="el-icon-success"
              style="color: #fdc570; font-size: 20px; margin-right: 8px"
              @click="radioClick"
              v-if="radioShow"
            ></i>
            <div class="radio" @click="radioClick" v-else></div>
            <span class="main">
              我已阅读并同意
              <span style="color: #333;cursor: pointer" @click="htOpen">《中小学生校外培训服务合同》</span>
              ，知晓与签约机构之间的权力和义务信息，自愿接受合同的约束。
            </span>
          </div> -->
          <div class="index_c_bottom_btn" @click="pay">
            立即支付
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="30%"
      title="微信支付"
      center
      :show-close="false"
    >
      <div class="flex-center">
        <el-image :src="wxPayCodeUrl"></el-image>
		  </div>
    </el-dialog>
    <el-dialog :visible.sync="centerDialogVisible1" width="30%" :show-close="false">
      <el-image :src="imageUrl"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
import QRCode from 'qrcode'
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      sn: '',
      price: '',
      payType: 1,
      detailData: {},
      payList: [
        {
          id: 1,
          name: '微信支付',
          icon: '/static/icon/wx.png'
        },
        {
          id: 0,
          name: '支付宝支付',
          icon: '/static/icon/zfb.png'
        }
      ],
      centerDialogVisible: false,
      centerDialogVisible1: false,
      payForm: '',
      imageUrl: '',
      wxPayCodeUrl: '',
      setWxInterval: null
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    goBack() {
			this.$router.go(-1);
		},
    getInfo(sn) {
      this.$api.order.info({sn: sn}).then(res => {
        this.detailData = res
      })
    },
    hideIdCard(idCard) {
      return idCard.replace(/^(.{6})(?:\d+)(.{4})$/, '$1****$2');
    },
    payTypeClick(type) {
      this.payType = type
    },
    pay() {
      this.$api.home.payOrder({
        sn: this.sn,
        platform: 2,
        payType: this.payType
      }).then(res => {
        if (this.payType == 0) {
          this.payForm = res.data
          const div = document.createElement("divform");
          div.innerHTML = res.data;
          document.body.appendChild(div);
          document.forms[0].submit();
        } else {
          this.centerDialogVisible = true
          const qrCodeCanvas = document.createElement("canvas");
          qrCodeCanvas.width = 200;
          qrCodeCanvas.height = 200;
          QRCode.toCanvas(qrCodeCanvas, res.data.codeUrl, {width: 200, height: 200},(error) => {
            if (error) {
              console.log(error);
            } else {
              const url = qrCodeCanvas.toDataURL("image/png")
              this.wxPayCodeUrl = url
              this.setWxInterval = setInterval(() => {
                this.getOrderStatus()
              }, 1000);
            }
          })
        }
      })
    },
    getOrderStatus() {
      this.$api.order.getStatus({sn: this.sn}).then(res => {
        if (res.status == 1) {
          this.$message.success('支付成功')
          clearInterval(this.setWxInterval)
          setTimeout(() => {
            this.$router.push('/pay_detail?sn=' + this.sn);
          }, 800)
        }
      })
    },
    demo() {
      this.setWxInterval = setInterval(() => {
        // this.getOrderStatus()
      }, 1000);
      setTimeout(() => {
        this.$message.success('支付成功')
          clearInterval(this.setWxInterval)
          setTimeout(() => {
            this.$router.push('/pay_detail?sn=' + this.sn);
          }, 800)
      }, 3000)
    }
  },
  mounted() {
    this.sn = this.$route.query.sn;
    this.getInfo(this.sn);
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  padding: 80px 350px;
}
.title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  padding-bottom: 16px;
}
.index_c_item {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}
.index_c_item_cover {
  width: 56px;
  height: 56px;
  border-radius: 8px 8px 8px 8px;
  margin-right: 12px;
}
.index_c_item_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #999999;
}
.index_c_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.price {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 20px;
  color: #f95620;
}
.user_top {
  padding: 12px 16px;
  background-color: #f5f5f5;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_item {
  padding: 12px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.index_c_item_top_btn {
  background: #fdc570;
  border-radius: 366px 366px 366px 366px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 16px;
  cursor: pointer;
}
.item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.status {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #f95620;
}
.index_c_item_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 16px;
}
.index_c_item_list_item {
  width: 88px;
  height: 88px;
  background: #f5f5f5;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  margin-bottom: 9px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
  position: relative;
}
.index_c_item_list_item_close {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fdc570;
  font-size: 20px;
}
.tips {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #f95620;
}
.upload_item {
  width: 388px;
  padding: 14px 12px;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.upload_icon {
  width: 24px;
  height: 24px;
  background-color: #000;
  margin-right: 8px;
}
.upload_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.item_title_1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #fdc570;
  padding-left: 16px;
}
.index_c_bottom_item {
  background: #fafafa;
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.index_c_bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333;
}
.radio {
  width: 16px;
  height: 16px;
  border-radius: 75px 75px 75px 75px;
  border: 1px solid #dddddd;
  cursor: pointer;
  margin-right: 8px;
}
.index_c_bottom_btn {
  width: 216px;
  height: 48px;
  background: #fdc570;
  border-radius: 329px 329px 329px 329px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.index_c_bottom_coupon {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.index_c_bottom_coupon_item_top {
  width: 264px;
  padding: 12px 16px;
  box-sizing: border-box;
  background: #f95620;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}
.coupon_check {
  position: absolute;
  top: 10px;
  right: 20px;
}
.index_c_bottom_coupon_item_bottom {
  width: 264px;
  padding: 12px 12px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #999999;
}
.dialog_tab {
  display: flex;
  align-items: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #999999;
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
}
.dialog_tab div {
  cursor: pointer;
  margin-right: 24px;
  padding-bottom: 10px;
}
.dialog_tab .tab_active {
  border-bottom: 4px solid #fdc570;
  padding-bottom: 10px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}
.dialog_content {
  margin-top: 16px;
  word-break: break-all;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  /* color: #9e9e9e; */
  margin-bottom: 24px;
}
.dialog_btn {
  width: 298px;
  height: 48px;
  background: #f5f5f5;
  border-radius: 329px 329px 329px 329px;
  text-align: center;
  line-height: 48px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #cccccc;
  cursor: pointer;
}

.index_c_item_back {
	background: #f5f5f5;
	border-radius: 366px 366px 366px 366px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 8px 24px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #333333;
	cursor: pointer;
}
</style>
