<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="2"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="index_c_item_left">
        <div class="flex-ju-sb" style="padding-bottom: 16px">
          <div class="flex">
            <el-image :src="detailData.avatar" style="width:32px; height: 32px;" fit="cover" class="index_c_item_left_avatar"></el-image>
            <!-- <div class="index_c_item_left_avatar"></div> -->
            <div class="index_c_item_left_name">{{ detailData.nickname }}</div>
          </div>
          <div
            class="index_c_item_left_main"
            @click="centerDialogVisible = true"
          >
            投诉
          </div>
        </div>
        <div class="index_c_item_left_content">
          {{ detailData.content }}
        </div>
        <div class="index_c_item_left_content_img_list">
          <div
            class="index_c_item_left_content_img_item"
            v-for="item in detailData.images"
            :key="item"
          >
            <div style="position: relative">
              <el-image v-if="item.type == 'mp4'" :src="item.url + '?vframe/jpg/offset/0'" fit="cover" class="index_c_item_left_content_img_item" style="width: 210px; height: 210px;cursor: pointer;"  @click="videoUrl = item.url, dialogVisible2 = true"></el-image>
              <el-image v-else :src="item.url" :preview-src-list="[item.url]" fit="cover" class="index_c_item_left_content_img_item" style="width: 210px; height: 210px"></el-image>
              <el-image src="/static/pc/icon_bofang@2x.png" style="width: 25px; height: 25px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); cursor: pointer;" v-if="item.type == 'mp4'" @click="videoUrl = item.url, dialogVisible2 = true"></el-image>
            </div>
          </div>
        </div>
        <div class="border-b">
          <div class="flex" style="padding-bottom: 10px">
            <div class="flex">
              <el-image src="/static/pc/topic.png" fit="cover" style="width: 20px; height: 20px"></el-image>
              <div
                class="index_c_item_left_content_text"
                style="padding-left: 10px"
              >
                {{ detailData.topicName }}
              </div>
            </div>
          </div>
          <div class="index_c_item_left_content_text">{{ detailData.publishTime }}</div>
        </div>
        <div class="index_c_item_left_bottom">
          <div class="flex" style="cursor: pointer" @click="getZan()">
            <el-image :src="detailData.zan ? '/static/pc/zan1.png' : '/static/pc/zan.png'" fit="cover" style="width: 20px; height: 20px"></el-image>
            <div
              class="index_c_item_left_content_text"
              style="padding-left: 10px"
            >
              {{ detailData.zanCount }}
            </div>
          </div>
          <!-- <div class="flex" style="padding-left: 26px;cursor: pointer" @click="handCollect">
            <el-image :src="detailData.isCollect ? '/static/pc/shou1.png' : '/static/pc/shou.png'" fit="cover" style="width: 20px; height: 20px"></el-image>
            <div
              class="index_c_item_left_content_text"
              style="padding-left: 10px"
            >
              {{ detailData.commentCount }}
            </div>
          </div> -->
        </div>
      </div>
      <div
        class="index_c_item_right"
        style="padding-left: 0; padding-right: 0; padding-bottom: 0"
      >
        <div
          class="flex border-b"
          style="padding-left: 24px; padding-right: 24px"
        >
          <div class="yuan"></div>
          <div class="index_c_item_right_title">评论 {{commitList.length}}</div>
        </div>
        <div
          class="index_c_item_right_list"
          style="padding-left: 24px; padding-right: 24px"
        >
          <div
            class="border-b"
            style="padding-top: 16px"
            v-for="item in commitList"
            :key="item"
          >
            <div class="flex" style="padding-bottom: 10px">
              <el-image :src="item.avatar" style="width: 24px; height: 24px;" fit="cover" class="index_c_item_right_avatar"></el-image>
              <div class="index_c_item_right_name">{{ item.nickname }}</div>
              <div class="index_c_item_right_time">{{ item.publishTime }}</div>
            </div>
            <div class="index_c_item_right_content">
              {{ item.content }}
            </div>
            <div class="index_c_item_right_bottom">
              <el-image :src="item.zan ? '/static/pc/zan1.png' : '/static/pc/zan.png'" fit="cover" style="width: 20px; height: 20px;padding-right: 10px"></el-image>
              <el-image src="/static/pc/commit.png" fit="cover" style="width: 20px; height: 20px;padding-right: 10px"></el-image>
            </div>
          </div>
        </div>
        <div class="index_c_item_right_b">
          <el-input placeholder="请输入内容" v-model="content"></el-input>
          <div class="index_c_item_right_b_btn" style="margin-left: 10px" @click="sumbit()">
            发送
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="50%"
      :show-close="false"
    >
      <div class="dialog">
        <div class="flex-ju-sb border-b" style="margin-top: -30px">
          <div class="index_c_item_left_name">投诉</div>
          <i
            class="el-icon-error"
            style="color: #fdc570; font-size: 24px; cursor: pointer"
            @click="centerDialogVisible = false"
          ></i>
        </div>
        <div class="dialog_content border-b">
          <div style="padding-bottom: 16px">
            <el-radio v-model="complainParams.type" label="0">侵犯隐私</el-radio>
          </div>
          <div style="padding-bottom: 16px">
            <el-radio v-model="complainParams.type" label="1">骚扰或垃圾信息</el-radio>
          </div>
          <div style="padding-bottom: 16px">
            <el-radio v-model="complainParams.type" label="2">侵犯知识产权</el-radio>
          </div>
          <div style="padding-bottom: 16px">
            <el-radio v-model="complainParams.type" label="3">虚假信息</el-radio>
          </div>
          <div style="padding-bottom: 16px">
            <el-radio v-model="complainParams.type" label="4">违法行为</el-radio>
          </div>
          <div style="padding-bottom: 16px">
            <el-radio v-model="complainParams.type" label="5">其他</el-radio>
          </div>
          <el-input type="textarea" v-model="complainParams.memo" :rows="5" placeholder="请输入其他补充内容…">
          </el-input>
        </div>
        <div class="flex-end" style="padding-top: 16px">
          <div  @click="centerDialogVisible = false" class="close_btn" style="margin-right: 16px">取消</div>
          <div  @click="complainSumbit()" class="sumbit_btn">确定</div>
        </div>
      </div>
    </el-dialog>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
    <el-dialog
      :visible.sync="dialogVisible2"
      append-to-body
      @close="videoUrl = ''"
      :close-on-click-modal="false"
    >
      <video
        controls
        name="media"
        :src="videoUrl"
        style="width: 100%"
      />
    </el-dialog>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      centerDialogVisible: false,
      detailData: {},
      params: {
        id: '',
	      pageNumber: 1,
	      pageSize: 20
      },
      commitList: [],
      content: '',
      complainParams: {
        memo: '',
        type: '',
        postsId: ''
      },
      videoUrl: '',
      dialogVisible2: false
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    getDetail(id) {
      this.$api.detail.postsInfo({ id: id }).then(res => {
        this.detailData = res
        let imgList = this.detailData.images
        let imgData = []
        for (const key in imgList) {
          // 去掉?vframe/jpg/offset/0字符串s
          imgList[key] = imgList[key].replace('?vframe/jpg/offset/0', '')
          // 获取后缀名
          let suffix = imgList[key].substr(imgList[key].lastIndexOf('.') + 1)
          imgData.push({
            url: imgList[key],
            type: suffix
          })
        }
        this.detailData.images = imgData
        console.log(this.detailData);
      })
    },
    getCommitList() {
      this.$api.detail.commentList(this.params).then(res =>
        this.commitList = res.records
      )
    },
    sumbit() {
      this.$api.detail.submitComment({postsId: this.params.id, content: this.content}).then(res => {
        this.$message.success('评论成功，请等待审核~')
        this.content = ''
      })
    },
    complainSumbit() {
      this.$api.detail.complaint(this.complainParams).then(res => {
        this.$message.success('投诉成功，请等待审核~')
        this.centerDialogVisible = false
      })
    },
    getZan() {
      this.$api.community.zan({ id: this.detailData.postsId }).then((res) => {
        this.$message.success(res);
        if (this.detailData.zan) {
          this.detailData.zan = false;
          this.detailData.zanCount--;
        } else {
          this.detailData.zan = true;
          this.detailData.zanCount++;
        }
        this.$forceUpdate();
      });
    },
    handCollect() {
			this.$api.home.collect({ id: this.detailData.postsId }).then(res => {
        if (this.detailData.isCollect) {
          this.detailData.isCollect = false;
          this.detailData.commentCount--;
        } else {
          this.detailData.isCollect = true;
          this.detailData.commentCount++;
        }
				this.$forceUpdate();
				this.$message.success(res);
			});
		},
  },
  mounted() {
    this.getDetail(this.$route.query.id)
    this.params.id = this.$route.query.id
    this.complainParams.postsId = this.$route.query.id
    this.getCommitList()
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  padding: 40px 350px;
  display: flex;
  justify-content: space-between;
}
.index_c_item_left {
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  padding: 24px;
  width: 68%;
  box-sizing: border-box;
}
.index_c_item_right {
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  padding: 24px;
  width: 30%;
  box-sizing: border-box;
}
.index_c_item_left_avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
}
.index_c_item_left_name {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.index_c_item_left_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
}
.index_c_item_left_content {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  word-break: break-all;
  padding-bottom: 16px;
}
.index_c_item_left_content_img_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.index_c_item_left_content_img_item {
  width: 210px;
  height: 210px;
  border-radius: 12px 12px 12px 12px;
  margin-right: 16px;
  margin-bottom: 16px;
}
.index_c_item_left_content_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.border-b {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 16px;
}
.index_c_item_left_bottom {
  padding-top: 16px;
  display: flex;
  align-items: center;
}

.index_c_item_right_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.index_c_item_right_avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}
.index_c_item_right_name {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  padding-right: 23px;
}
.index_c_item_right_time {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.index_c_item_right_content {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  word-break: break-all;
  padding-bottom: 8px;
}
.index_c_item_right_bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.index_c_item_right_list {
  height: 702px;
  overflow-y: auto;
}
.index_c_item_right_list::-webkit-scrollbar {
  width: 0;
}
.index_c_item_right_b {
  padding: 16px;
  border-top: 1px solid #f5f5f5;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.index_c_item_right_b_btn {
  background: #fdc570;
  border-radius: 277px 277px 277px 277px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 0;
  width: 76px;
  text-align: center;
  cursor: pointer;
}
.dialog_content {
  margin-top: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.radio {
  width: 16px;
  height: 16px;
  border-radius: 75px 75px 75px 75px;
  border: 1px solid #dddddd;
  cursor: pointer;
  margin-right: 8px;
}

.sumbit_btn {
	background: #fdc570;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	padding: 8px 24px;
	cursor: pointer;
}
.close_btn {
	background: #f5f5f5;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #333;
	padding: 8px 24px;
	cursor: pointer;
}
</style>
