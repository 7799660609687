<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="flex-ju-sb">
				<div class="title">评价</div>
			</div>
			<div class="index_c_item">
      		  <div class="index_c_item_main" style="padding-bottom: 12px">
      		    {{ detailData.institutionName }}
      		  </div>
      		  <div class="flex">
						<img :src="detailData.courseCoverImage" alt=""  class="index_c_item_cover" style="width: 56px; height: 56px">
				<!-- <el-image :src="detailData.courseCoverImage" fit="cover" class="index_c_item_cover" style="width: 56px; height: 56px"></el-image> -->
      		    <div style="width: 95%">
      		      <div class="index_c_item_title" style="padding-bottom: 4px">
      		        {{ detailData.courseName }}
      		      </div>
      		      <div class="flex-ju-sb">
      		        <div class="main">{{ detailData.classTime }}</div>
      		        <div class="price">￥{{ detailData.price }}</div>
      		      </div>
      		    </div>
      		  </div>
      		</div>
			<div class="index_c_item">
				<div class="flex border-b">
            	  <div class="item_title">评分</div>
            	  <el-rate v-model="params.point" text-color="#ff9900"></el-rate>
            	</div>
				<div class="flex border-b">
            	  <div class="item_title">选择标签</div>
            	  <div class="flex">
                    <div :class="item.check ? 'tag_active' : 'tag'" v-for="(item, index) in tabList" :key="index" @click="tabClick(index, item)">
                      {{ item.name }}
                    </div>
                  </div>
            	</div>
				<div class="flex-center" style="padding-top: 16px">
					<div class="sumbit_btn" @click="sumbit" style="margin-right: 16px">提交</div>
					<div class="close_btn" @click="goBack">取消</div>
				</div>
			</div>
		</div>

		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			tabList: [],
			params: {
				sn: '',
				point: '',
				tabs: []
			},
			detailData: {}
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		goBack() {
			this.$router.go(-1);
		},
		getTypeList() {
    	  this.$api.common.tabList().then((res) => {
    	    const result = res.map((item) => {
    	      return {
    	        name: item,
    	        check: false,
    	      };
    	    });
    	    this.tabList = result;
    	  });
    	},
		tabClick(index) {
    	  this.tabList[index].check = !this.tabList[index].check;
    	  this.$forceUpdate();
    	},
		sumbit() {
			let tabs = []
			for (const key in this.tabList) {
				if (this.tabList[key].check) {
					tabs.push(this.tabList[key].name)
				}
			}
			this.params.tabs = tabs
			this.$api.order.orderComment(this.params).then(res => {
				this.$message.success(res)
				setTimeout(() => {
					this.$router.go(-1)
				}, 1000)
			})
		}
	},
	mounted() {
		this.detailData = JSON.parse(this.$route.query.data)
		this.params.sn = this.detailData.sn
		this.getTypeList()
		// console.log(layui);
	},
	created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  padding: 80px 350px;
}
.title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  padding-bottom: 16px;
}
.index_c_item {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}
.index_c_item_cover {
  width: 56px;
  height: 56px;
  border-radius: 8px 8px 8px 8px;
  margin-right: 12px;
}
.index_c_item_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #999999;
}
.index_c_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.price {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 20px;
  color: #f95620;
}
.user_top {
  padding: 12px 16px;
  background-color: #f5f5f5;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_item {
  padding: 12px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.index_c_item_top_btn {
  background: #fdc570;
  border-radius: 366px 366px 366px 366px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 16px;
  cursor: pointer;
}
.item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  padding-top: 10px;
  padding-right: 10px;
}
.tag {
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #f5f5f5;
  padding: 4px 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  margin-right: 12px;
  margin-top: 10px;
  cursor: pointer;
}
.tag_active {
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #fdc570;
  padding: 4px 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background: #fdc570;
  margin-right: 12px;
  margin-top: 10px;
  cursor: pointer;
}
.sumbit_btn {
	background: #fdc570;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	padding: 8px 24px;
	cursor: pointer;
}
.close_btn {
	background: #f5f5f5;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #333;
	padding: 8px 24px;
	cursor: pointer;
}
</style>
