<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="title">帮助中心</div>
			<div class="index_c_item">
				<div class="index_c_item_list" v-for="(item, index) in dataList" :key="index" @click="goUrl('/helpDetail?content=' + item.content + '&title=' + item.articleName)">
					<div class="flex-ju-sb">
						<div class="index_c_item_title">{{ item.articleName }}</div>
						<i class="el-icon-arrow-right" style="font-weight: bold; font-size: 20px"></i>
					</div>
				</div>
        <!-- <div class="flex-center" style="padding-top: 12px">
					<el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
				</div> -->
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			params: {
				keywords: '',
				categoryId: ''
			},
			dataList: []
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		getList() {
			this.$api.message.helpArticleList(this.params).then(res => {
				this.dataList = res
			})
		}
	},
	mounted() {
		// console.log(layui);
		this.getList()
	},
	created() {},
};
</script>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c {
	padding: 80px 350px;
}
.title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 24px;
	color: #333333;
	padding-bottom: 16px;
}
.index_c_item {
	padding: 24px;
	padding-top: 8px;
	background-color: #ffffff;
	border-radius: 12px;
}
.index_c_item_list {
	border-bottom: 1px solid #f5f5f5;
	padding: 16px 0;
	cursor: pointer;
}
.index_c_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
}
</style>
