export var environment = {
  // 上传路径
  upLoadPath() {
    // return `/api/admin/file/upload` // 测服
    return `https://pc.bupaolu123.com/gw/admin/file/upload` // 正式服
  },
  // 富文本路径
  richTextPath() {
    // return `/api/admin/file/ueditor/upload` // 测服
    return `https://pc.bupaolu123.com/gw/admin/file/ueditor/upload` // 正式服
  },
  // 图片验证码
  verification() {
    // const api = `/api/captcha/index` // 测服
    const api = `https://pc.bupaolu123.com/gw/captcha/code` // 正式服
    return api
  }
}
export default environment
// 打包前记得改request的baseURL
// 本地：  baseURL: '/api',
// 部署：  baseURL: '/',

// 测试服：https://www.gc-shopping.com/
// 正式服：https://gs.globalshopapp.com/

// onebuy/message   setting/globalText  gonggao  question
// 本地： UEDITOR_HOME_URL: "/normalManager/ueditor/"
// 部署：UEDITOR_HOME_URL: "/normalManager/ueditor/"

// 防止被替换：
// 本地： UEDITOR_HOME_URL:"/ueditor/"
// 部署：UEDITOR_HOME_URL:"/normalManager/ueditor/"
