<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="2"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="index_c_item">
				<div class="index_c_item_title" style="padding-bottom: 20px">上传多张图片/1个视频</div>
				<div class="index_c_item_list">
					<uploadImg ref="upload"></uploadImg>
					<!-- <div class="index_c_item_list_item" v-for="item in 3" :key="item">
						<div class="index_c_item_list_item_close">
							<i class="el-icon-error"></i>
						</div>
						图片
					</div> -->
				</div>
				<div class="index_c_item_textarea">
					<el-input type="textarea" :rows="7" v-model="params.content" placeholder="请填写想发布的内容…"></el-input>
				</div>
				<div class="flex border-b">
					<div class="index_c_item_title" style="padding-right: 12px">选择话题</div>
					<div class="index_c_item_tag_list">
						<div :class="typeIdx == index ? 'index_c_item_tag_item_active' : 'index_c_item_tag_item'" v-for="(item, index) in typeList" :key="index" @click="typeClick(item, index)">{{ item.topicName }}</div>
					</div>
				</div>
				<div class="border-b" style="padding-top: 16px">
					<div class="index_c_item_title" style="padding-bottom: 26px">发布设置</div>
					<div class="flex" style="padding-bottom: 24px">
						<div style="cursor: pointer;">
							<div class="check" v-if="!params.showCity" @click="params.showCity = !params.showCity"></div>
          					<i
          					  @click="params.showCity = !params.showCity"
          					  v-if="params.showCity"
          					  class="el-icon-success"
          					  style="color: #fdc570; font-size: 20px"
          					></i>
						</div>
						<div class="index_c_item_title" style="padding: 0 8px">显示当前城市</div>
						<!-- <el-input placeholder="请输入城市" style="width: 320px"></el-input> -->
      					<el-cascader
						  :disabled="params.showCity"
      					  size="large"
      					  :options="cityList"
						  v-model="cityData">
      					</el-cascader>
					</div>
					<div class="flex">
						<div style="cursor: pointer;">
							<div class="check" v-if="!params.question" @click="params.question = !params.question"></div>
          					<i
          					  @click="params.question = !params.question"
          					  v-if="params.question"
          					  class="el-icon-success"
          					  style="color: #fdc570; font-size: 20px"
          					></i>
						</div>
						<!-- <i class="el-icon-success" style="color: #fdc570; font-size: 20px"></i> -->
						<div class="index_c_item_title" style="padding: 0 8px">提问贴（仅认证专家可回复）</div>
					</div>
				</div>
				<div class="flex-center" style="padding-top: 16px">
					<div class="close_btn" style="margin-right: 16px" @click="goBack">取消</div>
					<div class="sumbit_btn" @click="sumbit">发布</div>
				</div>
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
import { pcTextArr } from 'element-china-area-data'
import uploadImg from '@/components/Upload/qiniuImage.vue';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
		uploadImg,
	},
	data() {
		return {
			typeList: [],
			typeIdx: null,
			params: {
      		  city: null,
      		  content: '',
      		  images: [],
      		  postsType: 0,
      		  question: false,
      		  showCity: true,
      		  topicId: '',
      		  videoPath: null,
      		},
			cityList: pcTextArr,
			cityData: ['广东省', '广州市']
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		getTypeList() {
			this.$api.community.topicList().then(res => {
				this.typeList = res
			})
		},
		typeClick(data, index) {
			this.typeIdx = index
			this.params.topicId = data.id
		},
		sumbit() {
			this.params.city = this.cityData[1]
			let list = []
			for (const key in this.$refs.upload.ImageUrls) {
				list.push(this.$refs.upload.ImageUrls[key].url)
			}
			this.params.images = list
			if (!this.params.topicId) {
				return this.$message.error('请选择话题')
			}
			if (!this.params.content) {
				return this.$message.error('请输入发布内容')
			}
			this.$api.community.publish(this.params).then(res => {
				this.$message.success(res)
				this.$router.go(-1);
			})
		},
		goBack() {
			this.$router.go(-1);
		},
		dataReset() {
			this.params = {
      		  city: null,
      		  content: '',
      		  images: [],
      		  postsType: 0,
      		  question: false,
      		  showCity: true,
      		  topicId: '',
      		  videoPath: null,
      		}
			this.typeIdx = null
			this.cityData = ['广东省', '广州市']
			this.$refs.upload.ImageUrls = []
		}
	},
	mounted() {
		this.getTypeList()
		// console.log(layui);
	},
	created() {},
};
</script>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c {
	padding: 80px 350px;
}
.index_c_item {
	background: #ffffff;
	border-radius: 12px 12px 12px 12px;
	padding: 24px;
}
.index_c_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
}
.index_c_item_list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	border-bottom: 1px solid #f5f5f5;
	padding-bottom: 16px;
}
.index_c_item_list_item {
	width: 79px;
	height: 79px;
	background: #f5f5f5;
	border-radius: 8px 8px 8px 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 9px;
	margin-bottom: 9px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #9e9e9e;
	position: relative;
}
.index_c_item_list_item_close {
	position: absolute;
	top: -10px;
	right: -10px;
	color: #fdc570;
	font-size: 20px;
}
.index_c_item_textarea {
	margin-top: 16px;
	padding-bottom: 28px;
}
.index_c_item_tag_list {
	display: flex;
	align-items: center;
}
.index_c_item_tag_item {
	border-radius: 4px 4px 4px 4px;
	border: 1px solid #f5f5f5;
	padding: 4px 8px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #999999;
	margin-right: 12px;
	cursor: pointer;
}
.index_c_item_tag_item_active {
	border-radius: 4px 4px 4px 4px;
	border: 1px solid #fdc570;
	padding: 4px 8px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
	background-color: #fdc570;
	margin-right: 12px;
	cursor: pointer;
}
.border-b {
	padding-bottom: 16px;
	border-bottom: 1px solid #f5f5f5;
}
.sumbit_btn {
	background: #fdc570;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	padding: 8px 24px;
	cursor: pointer;
}
.close_btn {
	background: #f5f5f5;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #333;
	padding: 8px 24px;
	cursor: pointer;
}
</style>
