<template>
  <div>
    <div class="tabs">
      <button @click="startCountdown(0)" :disabled="isCountdownActive">选项卡1</button>
      <button @click="startCountdown(1)" :disabled="isCountdownActive">选项卡2</button>
      <button @click="startCountdown(2)" :disabled="isCountdownActive">选项卡3</button>
    </div>
    <div v-if="isCountdownActive" class="countdown">
      <span>{{ countdownValue }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countdownValue: 3,
      isCountdownActive: false,
    };
  },
  methods: {
    startCountdown(index) {
      this.isCountdownActive = true;

      setTimeout(() => {
        this.isCountdownActive = false;
        this.countdownValue -= 1;

        if (index < this.tabs.length - 1) {
          this.startCountdown(index + 1);
        } else {
          this.isCountdownActive = false;
        }
      }, 1000);
    },
  },
  computed: {
    tabs() {
      return ["选项卡1", "选项卡2", "选项卡3"];
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 2rem;
}
</style>
