<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="0"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="index_c_item_top flex">
        <img :src="userData.avatar"
          style="
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin-right: 16px;
          ">
        <div>
          <div class="flex">
            <div class="name">{{ userData.nickname }}</div>
            <div class="sp">邀请码：{{ userData.inviterCode }}</div>
            <i
              class="el-icon-document-copy"
              style="
                color: #fdc570;
                font-size: 20px;
                margin-left: 8px;
                cursor: pointer;
              "
              v-clipboard:copy="userData.inviterCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            ></i>
          </div>
          <!-- <div class="user_main flex">
            <div>{{ userData.zanCount }}获赞</div>
            <div style="padding: 0 8px">·</div>
            <div>{{ userData.collectCount }}收藏</div>
            <div style="padding: 0 8px">·</div>
            <div>{{ userData.commentCount }}评论</div>
          </div> -->
        </div>
      </div>
      <div class="flex" style="align-items: baseline">
        <div class="index_c_item_left">
          <div
            :class="
              tabIdx == index
                ? 'index_c_item_left_item_active'
                : 'index_c_item_left_item'
            "
            v-for="(item, index) in tabList"
            :key="index"
            @click="tabClick(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="index_c_item_right" v-if="tabIdx == 0">
          <div class="index_c_item_right_title flex-ju-sb">
            <div>我的订单</div>
            <div class="index_c_item_right_input">
              <input
                type="text"
                placeholder="搜索课程名称"
                v-model="orderParams.keywords"
                @change="getOrderList('keywords')"
              />
            </div>
          </div>
          <div class="index_c_item_right_box">
            <div class="order_list_tag">
              <div
                :class="
                  orderParams.status == item.status
                    ? 'order_list_tag_item_active'
                    : 'order_list_tag_item'
                "
                @click="orderStatusClick(item.status)"
                v-for="item in orderStatus"
                :key="item.status"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="order_item" v-for="item in orderList" :key="item" @click="goUrl('/pay_detail?sn=' + item.sn)">
              <div class="order_item_top">
                <div class="flex">
                  <!-- <div>{{ item.createDate }}</div> -->
                  <div>订单号：{{ item.sn }}</div>
                </div>
                <div class="order_item_status">
                  {{ orderStatusText(item.status) }}
                </div>
              </div>
              <div class="order_index_c">
                <div style="padding-bottom: 12px">
                  {{ item.institutionName }}
                </div>
                <div class="flex-ju-sb" style="align-items: flex-end">
                  <div class="flex">
                    <el-image
                      :src="item.courseCoverImage"
                      class="order_item_cover"
                      style="width: 48px; height: 48px"
                      fit="cover"
                    ></el-image>
                    <div>
                      <div class="order_item_title">{{ item.courseName }}</div>
                      <div style="padding-top: 4px">{{ item.classHour }}</div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="order_item_price">
                      ￥{{ item.price }}
                    </div>
                    <div class="order_item_btn" style="margin-left: 16px" v-if="item.status != 2 && item.status != 3" @click.stop="orderBtnUrl(item.status, item)">
                     {{ orderStatusBtnText(item.status) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-center" style="padding-top: 12px">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="orderTotal"
                @current-change="currentChange"
              ></el-pagination>
            </div>
          </div>
        </div>
        <div class="index_c_item_right" v-if="tabIdx == 1">
          <div class="index_c_item_right_title flex-ju-sb">
            <div>我的课程/服务</div>
            <div class="index_c_item_right_input">
              <input
                type="text"
                placeholder="搜索课程/服务"
                v-model="courseParams.keywords"
                @change="getCourseList('keywords')"
              />
            </div>
          </div>
          <div class="index_c_item_right_box">
            <div class="order_list_tag">
              <div
                :class="
                  courseParams.status == item.status
                    ? 'order_list_tag_item_active'
                    : 'order_list_tag_item'
                "
                @click="courseStatusClick(item.status)"
                v-for="item in courseStatus"
                :key="item.status"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="order_item" v-for="(item, index) in courseList" :key="index">
              <div class="order_item_top">
                <div class="flex">
                  <div>订单号：{{ item.sn }}</div>
                </div>
                <div class="order_item_status">
                  {{ orderStatusBtnSalesText(item.status) }}
                </div>
              </div>
              <div class="order_index_c">
                <div style="padding-bottom: 12px">
                  {{ item.institutionName }}
                </div>
                <div class="flex-ju-sb" style="align-items: flex-end">
                  <div class="flex">
                    <el-image
                      :src="item.courseCoverImage"
                      class="order_item_cover"
                      style="width: 48px; height: 48px"
                      fit="cover"
                    ></el-image>
                    <div>
                      <div class="order_item_title">{{ item.courseName }}</div>
                      <div style="padding-top: 4px">{{ item.classHour }}</div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="order_item_price">
                      ￥{{ item.price }}
                    </div>
                    <!-- <div class="order_item_btn" style="margin-left: 16px">
                     {{ orderStatusBtnSalesText(item.status) }}
                    </div> -->
                    <div class="order_item_btn" style="margin-left: 16px" v-if="item.status == 0" @click.stop="orderBtnUrl(1, item)">
                      申请售后
                    </div>
                    <el-popconfirm
                        confirm-button-text='确认'
                        cancel-button-text='拒绝'
                        icon="el-icon-info"
                        icon-color="red"
                        title="是否对课程进行核销"
                        @confirm="handSales(item, 1, index)"
                        @cancel="handSales(item, 2, index)"
                        style="margin-left: 16px"
                      >
                        <div class="order_item_btn" v-if="item.status == 0" slot="reference">
                          确认核销
                        </div>
                      </el-popconfirm>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-center" style="padding-top: 12px">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="courseTotal"
                @current-change="currentChangeCourse"
              ></el-pagination>
            </div>
          </div>
        </div>
        <div class="index_c_item_right" v-if="tabIdx == 2">
          <div class="index_c_item_right_title flex-ju-sb">
            <div>退款/售后</div>
            <div class="index_c_item_right_input">
              <input
                type="text"
                placeholder="搜索退款/售后"
                v-model="salesParams.keywords"
                @change="getSalesList('keywords')"
              />
            </div>
          </div>
          <div class="index_c_item_right_box">
            <div class="order_list_tag">
              <div
                :class="
                  salesParams.status == item.status
                    ? 'order_list_tag_item_active'
                    : 'order_list_tag_item'
                "
                @click="salesStatusClick(item.status)"
                v-for="item in salesStatus"
                :key="item.status"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="order_item" v-for="item in salesList" :key="item"  @click="goUrl('/pay_detail?sn=' + item.sn)">
              <div class="order_item_top">
                <div class="flex">
                  <!-- <div>{{ item.createDate }}</div> -->
                  <div>订单号：{{ item.sn }}</div>
                </div>
              </div>
              <div class="order_index_c">
                <div style="padding-bottom: 12px">
                  {{ item.institutionName }}
                </div>
                <div class="flex-ju-sb" style="align-items: flex-end">
                  <div class="flex">
                    <el-image
                      :src="item.courseCoverImage"
                      class="order_item_cover"
                      style="width: 48px; height: 48px"
                      fit="cover"
                    ></el-image>
                    <div>
                      <div class="order_item_title">{{ item.courseName }}</div>
                      <div style="padding-top: 4px">{{ item.classHour }}</div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="order_item_price">
                      ￥{{ item.price }}
                    </div>
                    <div class="order_item_btn" style="margin-left: 16px" v-if="item.status != 2 && item.status != 3" @click.stop="orderBtnUrl(item.status, item)">
                     {{ orderStatusBtnText(item.status) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-center" style="padding-top: 12px">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="salesTotal"
                @current-change="currentChangeSales"
              ></el-pagination>
            </div>
          </div>
        </div>
        <div class="index_c_item_right" v-if="tabIdx == 3">
          <div class="index_c_item_right_title flex-ju-sb">
            <div>我的评价</div>
            <div class="index_c_item_right_input">
              <input
                type="text"
                placeholder="搜索评价"
                v-model="commitParams.keywords"
                @change="getCommitList('keywords')"
              />
            </div>
          </div>
          <div class="index_c_item_right_box">
            <!-- <div class="order_list_tag">
							<div :class="commitParams.status == item.status ? 'order_list_tag_item_active' : 'order_list_tag_item'" @click="commitStatusClick(item.status)" v-for="item in salesStatus" :key="item.status">{{ item.name }}</div>
						</div> -->
            <div class="order_item" v-for="item in commitList" :key="item"  @click="goUrl('/pay_detail?sn=' + item.orderSn)">
              <div class="order_item_top">
                <div class="flex">
                  <!-- <div>{{ item.createDate }}</div> -->
                  <div>
                    订单号：{{ item.orderSn }}
                  </div>
                </div>
              </div>
              <div class="order_index_c">
                <div style="padding-bottom: 12px">
                  {{ item.institutionName }}
                </div>
                <div class="flex-ju-sb border-b" style="align-items: flex-end">
                  <div class="flex">
                    <el-image
                      :src="item.courseCoverImage"
                      class="order_item_cover"
                      style="width: 48px; height: 48px"
                      fit="cover"
                    ></el-image>
                    <div>
                      <div class="order_item_title">{{ item.courseName }}</div>
                      <div style="padding-top: 4px">{{ item.classHour }}</div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="order_item_price">￥{{ item.price }}</div>
                  </div>
                </div>
                <div style="padding-top: 16px" class="order_item_title flex">
                  <div style="padding-right: 8px; padding-top: 4px">评分</div>
                  <el-rate
                    disabled
                    show-score
                    v-model="item.point"
                  ></el-rate>
                </div>
                <div style="padding-top: 12px" class="order_item_title flex">
                  <div style="padding-right: 8px">标签</div>
                  <div class="flex">
                    <div
                      :class="
                        item == '签约'
                          ? 'content_item_bottom_tag_item1'
                          : 'content_item_bottom_tag_item'
                      "
                      v-for="ite in item.tabs"
                      :key="ite"
                    >
                      {{ ite }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-center" style="padding-top: 12px">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="commitTotal"
                 @current-change="currentChangeCommit"
              ></el-pagination>
            </div>
          </div>
        </div>
        <div class="index_c_item_right" v-if="tabIdx == 4">
          <div class="index_c_item_right_title">我的帖子</div>
          <div class="index_c_item_right_box">
            <div class="order_list_tag">
              <div
                :class="
                  postsParams.type == item.type
                    ? 'order_list_tag_item_active'
                    : 'order_list_tag_item'
                "
                @click="postsStatusClick(item.type)"
                v-for="item in postsStatus"
                :key="item.type"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="index_c_list">
              <div
                class="index_c_item"
                v-for="(item, index) in postsList"
                :key="index"
                @click="goUrl('/communityDetail?id=' + item.postsId)"
              >
                <!-- <div class="index_c_item_cover"></div> -->
                <el-image
                  :src="item.avatar"
                  class="index_c_item_cover"
                  style="width: 216px; height: 216px"
                  fit="cover"
                ></el-image>
                <div class="index_c_item_title">{{ item.content }}</div>
                <div class="flex">
                  <div class="flex" style="padding-right: 10px">
                    <el-image src="/static/pc/commit.png" fit="cover" style="width: 20px; height: 20px"></el-image>
                    <div class="index_c_item_num">{{ item.commentCount }}</div>
                  </div>
                  <div class="flex" style="padding-right: 10px" @click.stop="getZan(item.postsId, index)">
                    <el-image :src="item.zan ? '/static/pc/zan1.png' : '/static/pc/zan.png'" fit="cover" style="width: 20px; height: 20px"></el-image>
                    <div class="index_c_item_num">{{ item.zanCount }}</div>
                  </div>
                  <!-- <div class="flex">
										<i class="el-icon-thumb"></i>
										<div class="index_c_item_num">1515</div>
									</div> -->
                </div>
              </div>
            </div>
            <div class="flex-center" style="padding-top: 12px">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="postsTotal"
                @current-change="currentChangePosts"
              ></el-pagination>
            </div>
          </div>
        </div>
        <div class="index_c_item_right" v-if="tabIdx == 5">
          <div class="index_c_item_right_title">浏览历史</div>
          <div class="index_c_item_right_box">
            <div class="index_c_wrap">
              <div
                class="index_c_wrap_item"
                v-for="item in reviewList"
                :key="item"
                style="width: auto"
                @click="goUrl('/course?courseId=' + item.courseId + '&institutionId=' + item.institutionId)"
              >
                <el-image
                  :src="item.coverImage"
                  class="index_c_wrap_item_cover"
                  style="width: 296px; height: 196px"
                  fit="cover"
                ></el-image>
                <div class="index_c_wrap_item_bottom">
                  <div class="index_c_wrap_item_bottom_title">
                    {{ item.courseName }}
                  </div>
                  <div class="index_c_wrap_item_bottom_main">
                    {{ item.classTime }}
                  </div>
                  <div class="flex-ju-sb">
                    <div class="index_c_wrap_item_bottom_price">
                      ￥{{ item.price }}
                    </div>
                    <div
                      class="index_c_wrap_item_bottom_btn"
                      v-if="item.allowedBuy"
                    >
                      购买
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="flex-center" style="padding-top: 12px">
							<el-pagination background layout="prev, pager, next" :total="reviewTotal"></el-pagination>
						</div> -->
          </div>
        </div>
        <div class="index_c_item_right" v-if="tabIdx == 6">
          <div class="index_c_item_right_title flex-ju-sb">
            <div>我的收藏</div>
            <div class="index_c_item_right_input">
              <input
                type="text"
                placeholder="搜索收藏"
                v-model="collectParams.keywords"
              />
            </div>
          </div>
          <div class="index_c_item_right_box">
            <el-row>
              <el-col :span="8" v-for="item in collectList" :key="item">
                <div
                class="content_item"
                @click="goUrl('/detail?id=' + item.institutionId)"
              >
                <div
                  class="content_item_cover"
                  :style="{
                    background: `url(${item.coverImage || ''})`,
                    backgroundSize: 'cover',
                  }"
                >
                  <div class="content_item_cover_text">{{ item.address }}</div>
                </div>
                <div class="content_item_bottom">
                  <div class="content_item_bottom_title">
                    {{ item.institutionName }}
                  </div>
                  <div class="flex" style="padding-top: 4px">
                    <div
                      :class="
                        item == '签约'
                          ? 'content_item_bottom_tag_item1'
                          : 'content_item_bottom_tag_item'
                      "
                      v-for="item in tabs"
                      :key="item"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <div
                    class="flex"
                    style="padding-top: 12px"
                    v-for="ite in item.courseBaseVos"
                    :key="ite"
                  >
                    <span class="content_item_bottom_price"
                      >￥{{ ite.price }}</span
                    >
                    <span class="content_item_bottom_title_1">{{
                      ite.courseName
                    }}</span>
                  </div>
                </div>
              </div>
              </el-col>
            </el-row>
            <!-- <div class="flex-center" style="padding-top: 12px">
							<el-pagination background layout="prev, pager, next" :total="collectTotal"></el-pagination>
						</div> -->
          </div>
        </div>
        <div class="index_c_item_right" v-if="tabIdx == 7">
          <div class="index_c_item_right_title">我的礼券</div>
          <div class="index_c_item_right_box">
            <div class="order_list_tag">
              <div
                :class="
                  couponParams.status == item.status
                    ? 'order_list_tag_item_active'
                    : 'order_list_tag_item'
                "
                @click="couponStatusClick(item.status)"
                v-for="item in couponStatus"
                :key="item.status"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="index_c_bottom_coupon">
              <div
                style="margin-right: 24px; margin-bottom: 16px"
                v-for="item in couponList"
                :key="item"
                @click="goUrl('/sumbit?type=nav')"
              >
                <div class="index_c_bottom_coupon_item_top">
                  <div class="flex">
                    <span style="font-size: 12px; font-weight: 500">￥</span>
                    <span>{{ item.amount }}</span>
                    <span style="padding-left: 8px">{{ item.name }}</span>
                  </div>
                  <div style="padding-top: 6px">
                    有效期至：{{ formatDate(item.startDate) }} ~
                    {{ formatDate(item.endDate) }}
                  </div>
                </div>
                <div class="index_c_bottom_coupon_item_bottom">
                  <div
                    class="index_c_bottom_coupon_item_bottom_btn"
                    v-if="!item.isUsed"
                  >
                    立即使用
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="flex-center" style="padding-top: 12px">
							<el-pagination background layout="prev, pager, next" :total="couponTotal"></el-pagination>
						</div> -->
          </div>
        </div>
        <!-- <div class="index_c_item_right" v-if="tabIdx == 8">
          <div class="index_c_item_right_title">意见反馈</div>
          <div class="index_c_item_right_box">
            <div class="feedback_item" v-for="item in feedbackList" :key="item">
              <div class="feedback_item_title">{{ item.createDate }}</div>
              <div class="feedback_item_content">
                {{ item.content }}
              </div>
            </div>
            <div class="flex-center" style="padding-top: 12px">
              <el-input
                type="textarea"
                rows="5"
                placeholder="写下你的宝贵意见..."
                v-model="feedbackText"
              >
              </el-input>
            </div>
            <div class="flex-end" style="padding-top: 12px">
              <div class="order_item_btn" @click="feebBackAdd">提交</div>
            </div>
          </div>
        </div> -->
        <div class="index_c_item_right" v-if="tabIdx == 9">
          <div class="index_c_item_right_title">设置</div>
          <div class="index_c_item_right_box">
            <!-- <div
              class="flex-ju-sb border-b"
              style="cursor: pointer"
              @click="goUrl('/chlid')"
            >
              <div class="">关联孩子</div>
              <i
                class="el-icon-arrow-right"
                style="color: #333; font-size: 20px"
              ></i>
            </div> -->
            <div
              class="flex-ju-sb border-b"
              style="cursor: pointer"
              @click="goUrl('/user')"
            >
              <div class="">个人信息</div>
              <i
                class="el-icon-arrow-right"
                style="color: #333; font-size: 20px"
              ></i>
            </div>
            <div
              class="flex-ju-sb border-b"
              style="padding-top: 16px; cursor: pointer"
              @click="goUrl('/system')"
            >
              <div class="">安全设置</div>
              <i
                class="el-icon-arrow-right"
                style="color: #333; font-size: 20px"
              ></i>
            </div>
            <div
              class="flex-ju-sb border-b"
              style="padding-top: 16px; cursor: pointer"
              @click="goUrl('/xieyi')"
            >
              <div class="">平台协议</div>
              <i
                class="el-icon-arrow-right"
                style="color: #333; font-size: 20px"
              ></i>
            </div>
            <div
              class="flex-ju-sb border-b"
              style="padding-top: 16px; cursor: pointer"
              @click="goUrl('/xieyi?type=privacyPolicy&title=隐私协议')"
            >
              <div class="">隐私协议</div>
              <i
                class="el-icon-arrow-right"
                style="color: #333; font-size: 20px"
              ></i>
            </div>
            <div
              class="flex-ju-sb"
              style="padding-top: 16px; cursor: pointer"
              @click="logoff"
            >
              <div class="">注销账号</div>
              <i
                class="el-icon-arrow-right"
                style="color: #333; font-size: 20px"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
    <el-dialog :visible.sync="payShow" width="30%" :show-close="false">
      <div class="index_c_item">
        <div class="flex-ju-sb" style="padding-bottom: 20px; cursor: pointer;" v-for="(item, index) in payList" :key="index" @click="payTypeClick(item.id)">
          <div class="flex">
            <el-image :src="item.icon" style="width: 45px; height: 45px; padding-right: 20px"></el-image>
            <span>{{ item.name }}</span>
          </div>
          <i
            v-if="item.id == payType"
            class="el-icon-success"
            style="color: #fdc570; font-size: 20px"
          ></i>
        </div>
      </div>
      <div class="flex-center">
        <div class="index_c_bottom">
          <div class="index_c_bottom_btn" @click="pay">
            立即支付
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="30%"
      title="微信支付"
      center
      :show-close="false"
    >
      <div class="flex-center">
        <el-image :src="wxPayCodeUrl"></el-image>
		  </div>
    </el-dialog>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
import { scrollTo } from '@/utils/scroll-to'
import QRCode from 'qrcode'
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      tabList: [
        {
          name: "我的订单",
        },
        {
          name: "我的课程/服务",
        },
        {
          name: "退款/售后",
        },
        {
          name: "我的评价",
        },
        {
          name: "我的帖子",
        },
        {
          name: "浏览历史",
        },
        {
          name: "我的收藏",
        },
        {
          name: "我的礼券",
        },
        // {
        //   name: "意见反馈",
        // },
        {
          name: "关联小孩",
        },
        {
          name: "设置",
        },
      ],
      tabIdx: 0,
      userData: {},
      // 订单
      orderParams: {
        keywords: "",
        pageSize: 10,
        pageNumber: 1,
        status: null,
      },
      orderTotal: 0,
      orderList: [],
      orderStatus: [
        {
          name: "全部",
          status: null,
        },
        {
          name: "待付款",
          status: 0,
        },
        {
          name: "已付款",
          status: 1,
        },
        {
          name: "已取消",
          status: 2,
        },
        {
          name: "已完成",
          status: 3,
        },
        {
          name: "待评价",
          status: 4,
        },
      ],
      // 课程
      courseParams: {
        keywords: null,
        pageSize: 10,
        pageNumber: 1,
        status: null,
      },
      courseStatus: [
        {
          name: "全部",
          status: null,
        },
        {
          name: "未核销",
          status: "1",
        },
        {
          name: "已核销",
          status: "2",
        },
      ],
      courseTotal: 0,
      courseList: [],

      // 退款/售后
      salesParams: {
        keywords: "",
        pageSize: 10,
        pageNumber: 1,
        status: null,
      },
      salesTotal: 0,
      salesList: [],
      salesStatus: [
        {
          name: "全部",
          status: null,
        },
        {
          name: "申请中",
          status: "1",
        },
        {
          name: "处理中",
          status: "2",
        },
        {
          name: "已完成",
          status: "3",
        },
      ],
      // 我的评价
      commitParams: {
        keywords: "",
        pageSize: 10,
        pageNumber: 1,
      },
      commitTotal: 0,
      commitList: [],
      // 我的帖子
      postsParams: {
        pageSize: 10,
        pageNumber: 1,
        type: "0",
      },
      postsTotal: 0,
      postsList: [],
      postsStatus: [
        {
          name: "闲聊",
          type: "0",
        },
        {
          name: "同城",
          type: "1",
        },
        {
          name: "话题",
          type: "2",
        },
        {
          name: "专家说",
          type: "3",
        },
      ],
      // 浏览历史
      reviewParams: {},
      reviewTotal: 0,
      reviewList: [],
      // 我的收藏
      collectParams: {
        keywords: "",
      },
      collectTotal: 0,
      collectList: [],
      // 我的礼券
      couponParams: {
        status: 0,
      },
      couponTotal: 0,
      couponList: [],
      couponStatus: [
        {
          name: "未使用",
          status: "0",
        },
        {
          name: "已使用",
          status: "1",
        },
        {
          name: "已过期",
          status: "2",
        },
      ],
      // 意见反馈
      feedbackParams: {
        pageSize: 10,
        pageNumber: 1,
      },
      feedbackTotal: 0,
      feedbackList: [],
      feedbackText: "",
      payType: 1,
      payList: [
        {
          id: 1,
          name: '微信支付',
          icon: '/static/icon/wx.png'
        },
        {
          id: 0,
          name: '支付宝支付',
          icon: '/static/icon/zfb.png'
        }
      ],
      payShow: false,
      sn: '',
      centerDialogVisible: false,
      wxPayCodeUrl: '',
      payForm: '',
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    tabClick(index) {
      this.tabIdx = index;
      console.log(this.tabIdx);
      switch (this.tabIdx) {
        case 0:
          this.getOrderList();
          break;
        case 1:
          this.getCourseList();
          break;
        case 2:
          this.getSalesList();
          break;
        case 3:
          this.getCommitList();
          break;
        case 4:
          this.getPostsList();
          break;
        case 5:
          this.getReviewList();
          break;
        case 6:
          this.getCollectList();
          break;
        case 7:
          this.getCouponList();
          break;
        case 8:
          this.goUrl('/chlid')
          break;
        // case 9:
        //   this.getFeedbackList();
        //   break;
      }
    },
    onCopy(e) {
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    onError(e) {},
    // 订单列表
    getOrderList(type) {
      if (type) {
        this.orderParams.pageNumber = 1;
      }
      this.$api.me.myList(this.orderParams).then((res) => {
        this.orderList = res.records;
        this.orderTotal = res.total;
      });
    },
    currentChange(e) {
      this.orderParams.pageNumber = e
      scrollTo(0, 800)
      this.getOrderList()
    },
    currentChangeCourse(e) {
      this.orderParams.pageNumber = e;
      scrollTo(0, 800)
      this.courseList();
    },
    currentChangeSales(e) {
      this.salesParams.pageNumber = e
      scrollTo(0, 800)
      this.getSalesList();
    },
    // 订单状态切换
    orderStatusClick(status) {
      this.orderParams.status = status;
      this.orderParams.pageNumber = 1;
      this.getOrderList();
    },
    // 课程列表
    getCourseList(type) {
      if (type) {
        this.courseParams.pageNumber = 1;
      }
      this.$api.me.courseList(this.courseParams).then((res) => {
        this.courseList = res.records;
        this.courseTotal = res.total;
      });
    },
    // 课程状态切换
    courseStatusClick(status) {
      this.courseParams.status = status;
      this.courseParams.pageNumber = 1;
      this.getCourseList();
    },
    getSalesList(type) {
      if (type) {
        this.salesParams.pageNumber = 1;
      }
      this.$api.me.salesList(this.salesParams).then((res) => {
        this.salesList = res.records;
        this.salesTotal = res.total;
      });
    },
    // 课程状态切换
    salesStatusClick(status) {
      this.salesParams.status = status;
      this.salesParams.pageNumber = 1;
      this.getSalesList();
    },
    // 我的评价
    getCommitList(type) {
      if (type) {
        this.commitParams.pageNumber = 1;
      }
      this.$api.me.orderCommentList(this.commitParams).then((res) => {
        this.commitList = res.records;
        this.commitTotal = res.total;
      });
    },
    currentChangeCommit(e) {
      this.commitParams.pageNumber = e
      scrollTo(0, 800)
      this.getCommitList();
    },
    // 我的贴子
    getPostsList() {
      this.$api.me.postsList(this.postsParams).then((res) => {
        this.postsList = res.records;
        this.postsTotal = res.total;
      });
    },
    currentChangePosts(e) {
      this.postsParams.pageNumber = e
      scrollTo(0, 800)
      this.getPostsList();
    },
    // 我的贴子切换
    postsStatusClick(type) {
      this.postsParams.type = type;
      if (Number(type) == 1) {
        this.postsParams.city = "";
      } else {
        this.postsParams.city = "";
      }
      this.postsParams.pageNumber = 1;
      this.getPostsList();
    },
    // 浏览历史
    getReviewList() {
      this.$api.me.reviewList().then((res) => {
        this.reviewList = res;
      });
    },
    // 我的收藏
    getCollectList() {
      this.$api.me.collectList(this.collectParams).then((res) => {
        this.collectList = res;
      });
    },
    // 我的礼券
    getCouponList() {
      this.$api.me.couponList(this.couponParams).then((res) => {
        this.couponList = res;
      });
    },
    couponStatusClick(status) {
      this.couponParams.status = status;
      this.getCouponList();
    },
    // 意见反馈
    getFeedbackList() {
      this.$api.me.feedbackList(this.feedbackParams).then((res) => {
        this.feedbackList = res;
      });
    },
    // 提交意见
    feebBackAdd() {
      this.$api.me.feedbackAdd({ content: this.feedbackText }).then((res) => {
        this.$message.success("提交成功");
        this.feedbackText = "";
        this.getFeedbackList();
      });
    },
    orderStatusText(val) {
      switch (val) {
        case 0:
          return "待付款";
        case 1:
          return "已付款";
        case 2:
          return "已取消";
        case 3:
          return "已完成";
        case 4:
          return "待评价";
      }
    },
    orderStatusBtnSalesText(val) {
      switch (val) {
        case 0:
          return "未核销";
        case 1:
          return "已核销";
        case 2:
          return "已拒绝";
      }
    },
    orderStatusBtnText(val) {
      switch (val) {
        case 0:
          return "立即付款";
        case 1:
          return "申请售后";
        case 4:
          return "评价";
      }
    },
    orderBtnUrl(val, data) {
      switch (val) {
        case 0:
          this.sn = data.sn
          return this.payShow = true
          // return this.$router.push('/pay?institutionId=' + data.institutionId + '&courseId=' + data.courseId);
        case 1:
          return this.$router.push('/sales?data=' + JSON.stringify(data));
        case 4:
          return this.$router.push({
            path: '/evaluate',
            query: {
              data: JSON.stringify(data)
            }
          });
      }
    },
    handSales(data, type, index) {
      this.$api.order.audit({salesCourseId: data.salesCourseId, type}).then(res => {
        this.$message.success('操作成功')
        this.courseList[index].status = type;
        this.$forceUpdate();
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const formatter = new Intl.DateTimeFormat("zh-CN", options);
      return formatter.format(date);
    },
    getZan(id, index) {
			this.$api.community.zan({ id: id }).then(res => {
				console.log(res);
				this.$message.success(res);
				if (this.postsList[index].zan) {
					this.postsList[index].zan = false;
					this.postsList[index].zanCount--;
				} else {
					this.postsList[index].zan = true;
					this.postsList[index].zanCount++;
				}
				this.$forceUpdate();
			});
		},
    payTypeClick(type) {
      this.payType = type
    },
    pay() {
      this.$api.home.payOrder({
        sn: this.sn,
        platform: 2,
        payType: this.payType
      }).then(res => {
        if (this.payType == 0) {
          this.payForm = res.data
          const div = document.createElement("divform");
          div.innerHTML = res.data;
          document.body.appendChild(div);
          document.forms[0].submit();
        } else {
          this.centerDialogVisible = true
          const qrCodeCanvas = document.createElement("canvas");
          qrCodeCanvas.width = 200;
          qrCodeCanvas.height = 200;
          QRCode.toCanvas(qrCodeCanvas, res.data.codeUrl, {width: 200, height: 200},(error) => {
            if (error) {
              console.log(error);
            } else {
              const url = qrCodeCanvas.toDataURL("image/png")
              this.wxPayCodeUrl = url
              this.setWxInterval = setInterval(() => {
                this.getOrderStatus()
              }, 1000);
            }
          })
        }
      })
    },
    getOrderStatus() {
      this.$api.order.getStatus({sn: this.sn}).then(res => {
        if (res.status == 1) {
          this.$message.success('支付成功')
          clearInterval(this.setWxInterval)
          setTimeout(() => {
            this.$router.push('/pay_detail?sn=' + this.sn);
          }, 800)
        }
      })
    },
    logoff() {
      this.$confirm(`是否注销账号`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.me.logoff().then((res) => {
				  this.$message.success(res)
          this.$router.push('/login')
				});
      })
    }
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("bupaolu_userInfo"));
    this.getOrderList();
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  padding: 80px 350px;
}
.index_c_item_top {
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  padding: 24px;
  margin-bottom: 24px;
}
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 12px;
}
.name {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  padding-right: 12px;
}
.sp {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #fdc570;
}
.user_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.index_c_item_left {
  width: 200px;
  border-radius: 12px;
  margin-right: 16px;
}
.index_c_item_left_item_active {
  background: #fdc570;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.index_c_item_left_item {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
}
.index_c_item_right {
  width: 100%;
}
.index_c_item_right_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  padding-bottom: 16px;
}
.index_c_item_right_box {
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  padding: 24px;
}
.order_list_tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 16px;
}
.order_list_tag_item {
  border-radius: 264px 264px 264px 264px;
  border: 1px solid #f5f5f5;
  padding: 4px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
  margin-right: 12px;
}
.order_list_tag_item_active {
  border-radius: 264px 264px 264px 264px;
  border: 1px solid #f5f5f5;
  padding: 4px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  background: #f5f5f5;
  cursor: pointer;
  margin-right: 12px;
}
.order_item {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}
.order_item_top {
  padding: 8px 16px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
  border-bottom: 1px solid #eeeeee;
}
.order_item_status {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #f95620;
}
.order_index_c {
  padding: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
}
.order_item_cover {
  width: 48px;
  height: 48px;
  /* background: #d8d8d8; */
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
}
.order_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.order_item_price {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #f95620;
}
.order_item_btn {
  background: #fdc570;
  border-radius: 277px 277px 277px 277px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 24px;
  cursor: pointer;
}
.order_item_btn_1 {
  background: #f5f5f5;
  border-radius: 277px 277px 277px 277px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  padding: 10px 24px;
  cursor: pointer;
}
.el-rate__icon {
  font-size: 26px !important;
}

.content_item_bottom_tag_item {
  background: #f5f5f5;
  border-radius: 2px 2px 2px 2px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 16px;
}
.content_item_bottom_tag_item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(253, 197, 112, 0.1);
  border-radius: 2px 2px 2px 2px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #fdc570;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 16px;
}

.index_c_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.index_c_item {
  margin-bottom: 28px;
}
.index_c_item_cover {
  width: 216px;
  height: 216px;
  border-radius: 16px 16px 16px 16px;
  /* background-color: #000; */
  margin-bottom: 12px;
}
.index_c_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  padding-bottom: 8px;
}
.index_c_item_avatar {
  width: 24px;
  height: 24px;
  background: #000;
  border-radius: 50%;
  margin-right: 8px;
}
.index_c_item_name {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.index_c_item_num {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  padding-left: 6px;
}

.index_c_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.index_c_wrap_item {
  width: 296px;
  margin-bottom: 16px;
  margin-right: 26px;
  cursor: pointer;
}
.index_c_wrap_item_cover {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  /* background: #d8d8d8; */
  width: 296px;
  height: 166px;
}
.index_c_wrap_item_bottom {
  padding: 16px;
  background-color: #fff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.index_c_wrap_item_bottom_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  padding-bottom: 8px;
}
.index_c_wrap_item_bottom_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  padding-bottom: 16px;
}
.index_c_wrap_item_bottom_price {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #f95620;
}
.index_c_wrap_item_bottom_btn {
  background: #fdc570;
  border-radius: 366px 366px 366px 366px;
  padding: 4px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}

.content_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 24px;
}
.content_item {
  background-color: #ffffff;
  cursor: pointer;
}
.content_item_cover {
  width: 296px;
  height: 296px;
  /* background: #d8d8d8; */
  background-size: cover;
  border-radius: 12px 12px 0 0;
  position: relative;
}
.content_item_cover_text {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  padding: 10px 15px;
  box-sizing: border-box;
}

.content_item_bottom {
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;
  box-sizing: border-box;
  padding: 16px;
}
.content_item_bottom_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  padding-bottom: 12px;
}
.content_item_bottom_tag_item {
  background: #f5f5f5;
  border-radius: 2px 2px 2px 2px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 16px;
}
.content_item_bottom_tag_item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(253, 197, 112, 0.1);
  border-radius: 2px 2px 2px 2px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #fdc570;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 16px;
}
.content_item_bottom_price {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 12px;
  color: #f95620;
  padding-bottom: 4px;
}
.content_item_bottom_title_1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding-left: 8px;
  padding-bottom: 4px;
}
.index_c_bottom_coupon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 16px;
}
.index_c_bottom_coupon_item_top {
  padding: 12px 16px;
  box-sizing: border-box;
  background: #f95620;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
}
.index_c_bottom_coupon_item_bottom {
  padding: 12px 12px;
  box-sizing: border-box;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #f5f5f5;
}
.index_c_bottom_coupon_item_bottom_btn {
  border-radius: 272px 272px 272px 272px;
  border: 1px solid #fdc570;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #fdc570;
  padding: 8px;
  text-align: center;
  cursor: pointer;
}
.feedback_item {
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #f5f5f5;
  padding: 16px;
  margin-bottom: 16px;
}
.feedback_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  padding-bottom: 8px;
}
.feedback_item_content {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  word-break: break-all;
}
.index_c_item_right_input {
  box-sizing: border-box;
}
.index_c_item_right_input input {
  border-radius: 50px;
  border: none;
  padding: 5px 20px;
  font-weight: 400;
  font-size: 16px;
}
.index_c_bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333;
}
.radio {
  width: 16px;
  height: 16px;
  border-radius: 75px 75px 75px 75px;
  border: 1px solid #dddddd;
  cursor: pointer;
  margin-right: 8px;
}
.index_c_bottom_btn {
  width: 216px;
  height: 48px;
  background: #fdc570;
  border-radius: 329px 329px 329px 329px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
::v-deep .el-rate__text {
  color: #fdc570 !important;
}
</style>
