<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="index_c_item_left">
				<div :class="tabIdx == 0 ? 'index_c_item_left_item_active' : 'index_c_item_left_item'" @click="tabClick(0)">
					系统通知
					<!-- <div class="index_c_item_left_item_msg">1</div> -->
				</div>
				<div :class="tabIdx == 1 ? 'index_c_item_left_item_active' : 'index_c_item_left_item'" @click="tabClick(1)">
					互动消息
					<!-- <div class="index_c_item_left_item_msg">1</div> -->
				</div>
				<div :class="tabIdx == 2 ? 'index_c_item_left_item_active' : 'index_c_item_left_item'" @click="tabClick(2)">
					课程消息
					<!-- <div class="index_c_item_left_item_msg">1</div> -->
				</div>
				<div :class="tabIdx == 3 ? 'index_c_item_left_item_active' : 'index_c_item_left_item'" @click="tabClick(3)">
					客服消息
					<!-- <div class="index_c_item_left_item_msg">1</div> -->
				</div>
			</div>
			<div class="index_c_item_right">
				<div class="index_c_item_right_content" v-for="(item, index) in dataList" :key="index">
					<div class="flex-ju-sb" style="padding-bottom: 4px;">
						<div class="index_c_item_right_title">{{ item.title }}</div>
						<div class="index_c_item_right_main">{{ item.modifyDate }}</div>
					</div>
					<div class="index_c_item_right_main">{{ item.subTitle }}</div>
				</div>
				<div class="flex-center" style="padding-top: 12px">
					<el-pagination background layout="prev, pager, next" :total="total"  @current-change="currentChange"></el-pagination>
				</div>
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
import { scrollTo } from '@/utils/scroll-to'
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
      params: {
        keywords: "",
        pageNumber: 1,
        pageSize: 20,
        status: 0,
      },
      dataList: [],
			tabIdx: 0,
			total: 0
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		goTypeUrl(type, e) {
      // 1查看文章详情，2跳转课程，3跳转机构
      if (type == 1) {
        uni.navigateTo({
          url: `/helpDetail?content=${this.dataList[e].articleInfo}`
        })
      }
      if (type == 2) {
        uni.navigateTo({
          url: `/course?courseId=${this.dataList[e].courseId}&institutionId=${this.dataList[e].institutionId}`
        })
      }
      if (type == 3) {
        uni.navigateTo({
          url: `/detail?id=${this.dataList[e].institutionId}`
        })
      }
    },
		tabClick(index) {
			this.tabIdx = index
			this.dataList = []
			this.params.status = index
			this.getList()
		},
    getList() {
      this.$api.message.list(this.params).then((res) => {
        this.dataList = [...this.dataList, ...res.records]
				this.total = res.total
      });
    },
		currentChange(e) {
			this.params.pageNumber = e
			scrollTo(0, 800)
			this.getList()
		}
	},
	mounted() {
		this.getList();
		// console.log(layui);
	},
	created() {},
};
</script>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c {
	padding: 80px 350px;
	display: flex;
	justify-content: space-between;
}
.index_c_item_left {
	width: 200px;
	background: #ffffff;
	border-radius: 12px;
	text-align: center;
}
.index_c_item_left_item {
	width: 100%;
	padding: 17px 0;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	cursor: pointer;
}
.index_c_item_left_item_active {
	background: #fdc570;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	width: 100%;
	padding: 17px 0;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #ffffff;
	position: relative;
	cursor: pointer;
}
.index_c_item_right {
	background-color: #fff;
	width: 82%;
	border-radius: 12px 12px 12px 12px;
	padding: 24px;
	padding-top: 8px;
	box-sizing: border-box;
}
.index_c_item_left_item_msg {
	position: absolute;
	right: 10px;
	top: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	background: #f95620;
	border-radius: 50%;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
}
.index_c_item_right_content {
	border-bottom: 1px solid #f5f5f5;
	padding: 16px 0;
	cursor: pointer;
}
.index_c_item_right_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
}
.index_c_item_right_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
}
</style>
