<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="2"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="index_c_user">
        <div class="flex">
          <!-- <div class="index_c_user_avatar"></div> -->
          <el-image :src="userData.avatar" fit="cover" style="width: 64px; height: 64px" class="index_c_user_avatar"></el-image>
          <div>
            <div class="index_c_user_name">{{ userData.nickname }}</div>
            <div class="index_c_user_main flex">
              <div>{{ userData.zanCount }}获赞</div>
              <div style="padding-left: 23px">{{ userData.collectCount }}收藏</div>
            </div>
          </div>
        </div>
      </div>
      <div class="index_c_list">
        <div
          class="index_c_item"
          v-for="(item, index) in dataList"
          :key="index"
          @click="goUrl('/communityDetail?id=' + item.postsId)"
        >
          <div class="index_c_item_cover">
            <div class="index_c_item_video">
              <i
                class="el-icon-video-play"
                style="color: #ccc; font-size: 30px"
              ></i>
            </div>
            <div class="index_c_item_top_right" v-if="item.question">提问</div>
          </div>
          <div class="index_c_item_title">{{ item.content }}</div>
          <div class="flex-ju-sb">
            <div class="flex">
              <el-image :src="item.avatar" fit="cover" style="width: 24px; height: 24px" class="index_c_item_avatar"></el-image>
              <div class="index_c_item_name">{{ item.nickname }}</div>
            </div>
            <div class="flex" @click.stop="getZan(item.postsId, index)">
              <i class="el-icon-thumb"></i>
              <div class="index_c_item_num">{{ item.zanCount }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      detailId: '',
      userData: {},
      dataList: [],
      params: {
        id: '',
        pageNumber: 1,
        pageSize: 10,
      },
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    getDetail() {
      this.$api.detail.detail({id: this.detailId}).then(res => {
        this.userData = res.data
      })
    },
    getTaList() {
      this.$api.detail.taList(this.params).then(res => {
        this.dataList = [...this.dataList, ...res.data.records]
      })
    },
    getZan(id, index) {
      this.$api.detail.zan({ id: id }).then(res => {
        uni.$u.toast(res.data)
        if (this.dataList[index].zan) {
          this.dataList[index].zan = false
          this.dataList[index].zanCount--
        } else {
          this.dataList[index].zan = true
          this.dataList[index].zanCount++
        }
        this.$forceUpdate()
      })
    }
  },
  mounted() {
    // console.log(layui);
    this.detailId = this.$route.query.id
    this.params.id = this.$route.query.id
    this.getDetail()
    this.getTaList()
  },
  created() {},
};
</script>
<style scoped>
.index_c {
  padding: 40px 350px;
}
.index_c_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.index_c_item {
  margin-bottom: 48px;
}
.index_c_user {
  padding-bottom: 50px;
}
.index_c_user_avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 12px;
}
.index_c_user_name {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.index_c_user_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.index_c_item_cover {
  width: 282px;
  height: 282px;
  border-radius: 16px 16px 16px 16px;
  background-color: #000;
  margin-bottom: 16px;
  position: relative;
}
.index_c_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  padding-bottom: 8px;
}
.index_c_item_avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}
.index_c_item_name {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.index_c_item_num {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  padding-left: 6px;
}
.index_c_item_video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.index_c_item_top_right {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 28px;
  background: #f95620;
  border-radius: 0px 16px 0px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
}
</style>
