<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1"></navbar>

		<!-- center -->
		<div class="index_c_top flex-ju-sb">
			<!-- <div class="index_c_top_cover"></div> -->
			<el-image :src="detailData.coverImage" class="index_c_top_cover" fit="cover" style="width: 680px; height: 380px"></el-image>
			<div class="index_c_top_right">
				<div class="flex-ju-sb" style="align-items: baseline">
					<div class="index_c_top_title m-text-line-2" style="width: 80%">{{ detailData.courseName }}</div>
					<div class="flex shou" @click="handCollect">
						<span style="padding-right: 8px; font-weight: bold; font-size: 16px" v-if="!detailData.isCollect">+</span>
						<span>{{ detailData.isCollect ? '已收藏' : '收藏' }}</span>
					</div>
				</div>
				<div class="flex" style="padding-top: 15px; padding-bottom: 16px; border-bottom: 1px solid #f5f5f5">
					<div :class="item == '签约' ? 'content_item_bottom_tag_item1' : 'content_item_bottom_tag_item'" v-for="item in detailData.tabs" :key="item">{{ item }}</div>
				</div>
				<div style="padding-top: 16px">
					<div class="flex" style="padding-bottom: 16px">
						<div class="index_c_top_price" style="padding-right: 8px">￥{{ detailData.price }}</div>
						<div class="main" style="padding-right: 8px" @click="centerDialogVisible1 = true">平台托管</div>
						<i class="el-icon-question" @click="centerDialogVisible1 = true" style="color: #fdc570; font-size: 20px; cursor: pointer"></i>
					</div>
					<div class="flex" style="padding-bottom: 8px">
						<div class="main" style="padding-right: 8px">学生数</div>
						<div class="" style="padding-right: 27px">{{ detailData.studentNum }}</div>
						<div class="main" style="padding-right: 8px">课时</div>
						<div class="">{{ detailData.classHour }}</div>
					</div>
					<div class="flex">
						<div class="main" style="padding-right: 8px">上课时间</div>
						<div>{{ detailData.classTime }}</div>
					</div>
					<div class="main" style="padding-top: 16px">
						<div class="flex" style="align-items: baseline">
							<i class="el-icon-phone" style="padding-right: 11px"></i>
							{{ detailData.mobile }}
						</div>
					</div>
				</div>
				<div class="index_c_top_btn" @click="goUrl(`/pay?institutionId=${detailData.institutionId}&courseId=${detailData.courseId}`)">立即购买</div>
			</div>
		</div>
		<div class="index_c">
			<div>
				<div class="flex" style="padding-bottom: 16px">
					<div class="yuan"></div>
					<div class="index_c_title">课程表</div>
				</div>
				<el-row>
					<el-col span="6">
						<div class="index_c_time_top_item">日期</div>
					</el-col>
					<el-col span="6">
						<div class="index_c_time_top_item">上午</div>
					</el-col>
					<el-col span="6">
						<div class="index_c_time_top_item">下午</div>
					</el-col>
					<el-col span="6">
						<div class="index_c_time_top_item">晚上</div>
					</el-col>
				</el-row>
				<div v-for="item in detailData.curriculumList" :key="item">
					<el-row>
						<el-col span="6">
							<div class="index_c_time_content_item">{{ item.curriculumDate }}</div>
						</el-col>
						<el-col span="6">
							<div class="index_c_time_content_item">{{ item.morningClassHours }}</div>
						</el-col>
						<el-col span="6">
							<div class="index_c_time_content_item">{{ item.afternoonClassHours }}</div>
						</el-col>
						<el-col span="6">
							<div class="index_c_time_content_item">{{ item.eveningClassHours }}</div>
						</el-col>
					</el-row>
				</div>
			</div>

			<div style="padding: 16px 0">
				<div class="flex" style="padding-bottom: 16px">
					<div class="yuan"></div>
					<div class="index_c_title">课程详情</div>
				</div>
				<div class="index_c_tab">
					<div @click="tabClick(0)" :class="tabIdx == 0 ? 'index_c_tab_item_active' : 'index_c_tab_item'">课程介绍</div>
					<div @click="tabClick(1)" :class="tabIdx == 1 ? 'index_c_tab_item_active' : 'index_c_tab_item'">购买须知</div>
					<div @click="tabClick(2)" :class="tabIdx == 2 ? 'index_c_tab_item_active' : 'index_c_tab_item'">收费详情</div>
					<div @click="tabClick(3)" :class="tabIdx == 3 ? 'index_c_tab_item_active' : 'index_c_tab_item'">教师信息</div>
					<div @click="tabClick(4)" :class="tabIdx == 4 ? 'index_c_tab_item_active' : 'index_c_tab_item'">用户评价({{ commitList.length }})</div>
				</div>
			</div>
			<div class="index_c_tab_box" v-if="tabIdx == 0">
				<div style="padding-bottom: 24px">
					<div class="">适用对象</div>
					<div class="main" style="padding-top: 8px">{{ detailData.studentTab }}</div>
				</div>
				<div class="" style="padding-bottom: 8px">课程介绍</div>
				<div v-html="detailData.introduction"></div>
			</div>
			<div class="index_c_tab_box" v-if="tabIdx == 1">
				<div>
					<div style="padding-bottom: 8px">购买须知</div>
					<div v-html="detailData.purchaseNotes"></div>
				</div>
			</div>
			<div class="index_c_tab_box" v-if="tabIdx == 2">
				<!-- <div class="index_c_tab_three"></div> -->
				<div v-html="detailData.chargeInfo"></div>
			</div>
			<div class="index_c_tab_box" v-if="tabIdx == 3">
				<div v-for="item in detailData.institutionMemberList" :key="item" style="padding-bottom: 24px">
					<div class="flex" style="padding-bottom: 24px; align-items: flex-start">
						<!-- <div class="index_c_item_cover"></div> -->
						<div class="index_c_item_cover">
							<el-image :src="item.memberImage" style="width: 110px; height: 154px; border-radius: 4px" fit="cover"></el-image>
						</div>
						<div style="width: 95%">
							<div class="index_c_title" style="padding-bottom: 18px">{{ item.memberName }}</div>
							<div class="index_c_item_main m-text-line-3">{{ item.message }}</div>
						</div>
					</div>
					<div class="index_c_item_title">教师特点</div>
					<div class="index_c_item_main m-text-line-3" style="padding-bottom: 24px">{{ item.feature }}</div>
					<div class="index_c_item_title">老师寄语</div>
					<div class="index_c_item_main m-text-line-3">{{ item.introduction }}</div>
				</div>
			</div>
			<div class="index_c_commit" v-if="tabIdx == 4">
				<div class="index_c_commit_top">
					<div class="index_c_commit_tag" v-for="(item, index) in tabsVos" :key="index">{{ item.tab }}({{ item.cnt }})</div>
				</div>
				<div class="index_c_commit_item" v-for="(item, index) in commitList" :key="index">
					<div class="flex">
						<el-image :src="item.avatar" fit="cover" style="width: 40px; height: 40px" class="index_c_commit_item_cover"></el-image>
						<div>
							<div class="flex" style="padding-bottom: 10px">
								<div class="index_c_commit_item_title">{{ item.nickname }}</div>
							</div>
							<div class="flex">
								<div :class="ite == '签约' ? 'content_item_bottom_tag_item1' : 'content_item_bottom_tag_item'" v-for="(ite, idx) in item.tabs" :key="idx">{{ ite }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-center" style="padding-top: 12px">
					<el-pagination background layout="prev, pager, next" :total="commitTotal" @current-change="currentChange"></el-pagination>
				</div>
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
		<el-dialog
      :visible.sync="centerDialogVisible1"
      width="70%"
      title="平台托管"
      center
      :show-close="false"
    >
      <div class="dialog">
        <div class="flex-end" style="margin-top: -50px">
          <i
            class="el-icon-error"
            style="color: #fdc570; font-size: 24px; cursor: pointer"
            @click="centerDialogVisible1 = false"
          ></i>
        </div>
        <div class="dialog_content">
					<iframe :src="ptUrl" frameborder="0" style="width: 100%;border: none"></iframe>
        </div>
      </div>
    </el-dialog>
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
import { scrollTo } from '@/utils/scroll-to'
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			tabIdx: 0,
			detailData: {},
			commitParams: {
				institutionId: '',
				pageNumber: 1,
				pageSize: 3,
			},
			commitList: [],
			tabsVos: [],
			commitTotal: 0,
			centerDialogVisible1: false,
			ptUrl: 'https://pc.bupaolu123.com/gw/h5/platformHosting'
		};
	},
	computed: {},
	methods: {
		tabClick(idx) {
			this.tabIdx = idx;
		},
		goUrl(url) {
			localStorage.removeItem('payInfo')
			this.$router.push(url);
		},
		getDetail(institutionId, courseId) {
			this.$api.home
				.courseInfo({
					courseId: courseId,
					institutionId: institutionId,
				})
				.then(res => {
					this.detailData = res;
				});
		},
		handCollect() {
			this.$api.home.collect({ id: this.detailData.institutionId }).then(res => {
				this.detailData.isCollect = !this.detailData.isCollect;
				this.$forceUpdate();
				this.$message.success(res);
			});
		},
		getCommitList() {
			this.$api.home.commentList(this.commitParams).then(res => {
				if (res) {
					this.commitList = res.commentPage.records;
					this.tabsVos = res.tabsVos;
					this.commitTotal = res.commentPage.total
				}
			});
		},
		currentChange(e) {
			this.commitParams.pageNumber = e
      scrollTo(0, 800)
      this.getCommitList();
		},
	},
	mounted() {
		this.commitParams.institutionId = this.$route.query.institutionId;
		this.getDetail(this.$route.query.institutionId, this.$route.query.courseId);
		this.getCommitList();
		// console.log(layui);
	},
	created() {},
};
</script>
<style>
.el-rate__icon {
	font-size: 26px !important;
}
</style>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c_top {
	padding: 80px 300px;
	padding-bottom: 24px;
}
.index_c_top_cover {
	width: 680px;
	height: 380px;
	border-radius: 16px 16px 16px 16px;
	/* background-color: #000; */
}
.index_c_top_right {
	width: 600px;
	height: 380px;
	border-radius: 16px 16px 16px 16px;
	background-color: #fff;
	padding: 24px;
	box-sizing: border-box;
}
.index_c_top_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 20px;
	color: #333;
}
.index_c {
	padding: 0 300px;
}
.index_c_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #333333;
}
.index_c_content {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	margin-bottom: 24px;
	word-break: break-all;
}
.shou {
	background: #fdc570;
	border-radius: 8px 8px 8px 8px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #ffffff;
	padding: 8px 12px;
	cursor: pointer;
}
.rate_text {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #fdc570;
	padding-top: 5px;
}
.content_item_bottom_tag_item {
	background: #f5f5f5;
	border-radius: 2px 2px 2px 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 12px;
	color: #333333;
	padding: 2px 8px;
	box-sizing: border-box;
	margin-right: 16px;
}
.content_item_bottom_tag_item1 {
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(253, 197, 112, 0.1);
	border-radius: 2px 2px 2px 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 12px;
	color: #fdc570;
	padding: 2px 8px;
	box-sizing: border-box;
	margin-right: 16px;
}
.main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
}

.index_c_wrap {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}
.index_c_wrap_item {
	width: 282px;
	margin-bottom: 16px;
}
.index_c_wrap_item_cover {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	background: #d8d8d8;
	width: 282px;
	height: 158px;
}
.index_c_wrap_item_bottom {
	padding: 16px;
	background-color: #fff;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}
.index_c_wrap_item_bottom_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #333333;
	padding-bottom: 8px;
}
.index_c_wrap_item_bottom_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	padding-bottom: 16px;
}
.index_c_wrap_item_bottom_price {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #f95620;
}
.index_c_wrap_item_bottom_btn {
	background: #fdc570;
	border-radius: 366px 366px 366px 366px;
	padding: 4px 16px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
.index_c_tab {
	background: #fff;
	border-radius: 16px;
	display: flex;
	align-items: center;
	padding: 16px;
}
.index_c_tab_item {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #999999;
	cursor: pointer;
	text-align: center;
	min-width: 96px;
	height: 46px;
	margin-right: 32px;
	line-height: 46px;
}
.index_c_tab_item_active {
	min-width: 96px;
	height: 46px;
	margin-right: 32px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	position: relative;
	cursor: pointer;
	text-align: center;
	line-height: 46px;
	border-bottom: 4px solid #fdc570;
}

.index_c_top_price {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 20px;
	color: #f95620;
}
.index_c_top_btn {
	margin-top: 48px;
	background: #fdc570;
	border-radius: 329px 329px 329px 329px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #ffffff;
	padding: 13px;
	text-align: center;
	cursor: pointer;
}
.index_c_time_top_item {
	padding: 10px 0;
	background: #fdc570;
	border-radius: 0px 0px 0px 0px;
	text-align: center;
	margin-right: 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #ffffff;
}
.index_c_time_content {
	display: flex;
	align-items: center;
}
.index_c_time_content_item {
	padding: 10px 0;
	border-radius: 0px 0px 0px 0px;
	text-align: center;
	margin-right: 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
}
.index_c_time_content div {
	padding: 10px 0;
	border-radius: 0px 0px 0px 0px;
	text-align: center;
	width: 390px;
	margin-right: 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	margin-right: 2px;
}
.index_c_time_content_s {
	display: flex;
	align-items: center;
}
.index_c_time_content_s div {
	padding: 10px 0;
	border-radius: 0px 0px 0px 0px;
	text-align: center;
	width: 390px;
	margin-right: 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	background: #fff1dc;
	margin-right: 2px;
}

.index_c_tab_box {
	padding: 24px;
	background: #fff;
	border-radius: 12px;
	margin-bottom: 24px;
}
.index_c_tab_banner {
	height: 250px;
	background: #d8d8d8;
	border-radius: 0px 0px 0px 0px;
	margin-bottom: 8px;
}
.index_c_tab_three {
	width: 300px;
	height: 500px;
	background-color: #000;
}
.index_c_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	padding-bottom: 8px;
}
.index_c_item_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #9e9e9e;
	word-break: normal;
}
.index_c_item_cover {
	width: 110px;
	height: 154px;
	border-radius: 4px 4px 4px 4px;
	margin-right: 12px;
}
.index_c_commit {
	padding: 16px;
	background-color: #fff;
	border-radius: 12px;
}
.index_c_commit_top {
	display: flex;
	align-items: center;
}
.index_c_commit_tag {
	background: #f5f5f5;
	border-radius: 578px 578px 578px 578px;
	padding: 4px 12px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 12px;
	color: #333333;
	margin-right: 8px;
	cursor: pointer;
}
.index_c_commit_item {
	border-bottom: 1px solid #f5f5f5;
	padding-top: 14px;
	padding-bottom: 16px;
}
.index_c_commit_item_cover {
	width: 40px;
	height: 40px;
	background-color: #000;
	margin-right: 8px;
	border-radius: 50%;
}
.index_c_commit_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	padding-right: 16px;
}
</style>
