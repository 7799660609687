<!-- 裁剪照片 -->
<template>
  <div>
    <el-upload
      class="avatar-uploader"
      action="https://upload-z2.qiniup.com/"
      :show-file-list="false"
      :style="'width:' + width + 'px;height:' + height + 'px'"
      :class="myUrl ? 'pic' : ''"
      :on-success="handleSuccess"
      :on-change="onHandelImageSelect"
      :before-upload="beforeUpload"
      list-type="picture"
    >
      <el-image
        v-if="myUrl && videoFlag == false && isVideo == true"
        :src="`${myUrl}?vframe/jpg/offset/0`"
        class="avatar"
        fit="fill"
        :style="'width:' + width + 'px;height:' + height + 'px'"
      />
      <el-image
        v-else-if="myUrl && videoFlag == false && isVideo == false"
        :src="myUrl"
        class="avatar round"
        fit="fill"
        :style="'width:' + width + 'px;height:' + height + 'px'"
      />
      <i
        v-else-if="!myUrl && videoFlag == false"
        class="el-icon-plus avatar-uploader-icon"
        :style="'font-size:' + width / 4"
      />
      <el-progress
        v-if="videoFlag == true"
        type="circle"
        :percentage="videoUploadPercent"
        style="margin-top: 30px"
      />
    </el-upload>
  </div>
</template>
<script>
/**
 * @myUrl 当前图片地址或者上传后的图片地址
 * @width 组件的宽度
 */
import environment from '@/utils/environment'
import { type } from 'os'
import * as qiniu from 'qiniu-js'
var avatar = ''
if (localStorage.getItem('bupaolu_userInfo')) {
  avatar = JSON.parse(localStorage.getItem('bupaolu_userInfo')).avatar
}
export default {
  props: {
    width: {
      type: Number,
      default: 40
    },
    height: {
      type: [Number, String],
      default: 40
    },
    imageurl: {
      // 默认展示的图片Url
      type: String,
      default: avatar
    },
    memberId: {
      type: Number
      //   required: true,
    }
  },
  data() {
    return {
      uploadData: { token: '', key: '' },
      qiniuData: {
        token: '',
        domain: ''
      },
      myUrl: '',
      videoFlag: false, // 进度条
      isVideo: false,
      videoUploadPercent: null
    }
  },
  mounted() {
    console.log(this.imageurl, 'imageurl');
    this.myUrl = this.imageurl
  },
  methods: {
    getKey(url) {
      //   命名规则default_时间戳.图片后缀格式，例如default_1609330164723140.png
      const ctime = new Date().getTime()
      return (
        'default_' +
        ctime +
        '.' +
        url.name.split('.')[url.name.split('.').length - 1]
      )
    },
    // 图片上传成功
    handleSuccess(res, file) {
      this.videoFlag = false
      console.log(res, file)
    },
    // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    beforeUpload(file) {
      // try {
      // })
      const size = Math.floor(file.size)
      if (size > 50 * 1024 * 1024) {
        // alert("请选择5M以内的图片");
        this.$message.error('请选择50M以内的文件')
        return false
      }
      if (this.isVideo) {
        if (file.type !== 'video/mp4') {
          this.$message.error('请选择MAP4格式的文件')
          return false
        }
      }

      this.qiniuYunGetToken(file)
    },
    // 获取七牛云token
    qiniuYunGetToken(file) {
      // try {
      this.$api.common
        .getToken(this.memberId)
        .then((res) => {
          this.qiniuData.token = res.token
          this.qiniuData.domain = res.domain
          this.uploadFiles(file, this.qiniuData.token, this.qiniuData.domain)
        })
        .catch((err) => {
          this.$message.error(err || '服务器错误，请稍后重试')
        })
        .finally(() => {
          this.baseInfoLoading = false
        })
      // 上传七牛云

      // } catch (error) {}
    },
    getKey(url) {
      //   命名规则default_时间戳.图片后缀格式，例如default_1609330164723140.png
      const ctime = new Date().getTime()
      return (
        'default_' +
        ctime +
        '.' +
        url.name.split('.')[url.name.split('.').length - 1]
      )
    },
    // 上传七牛云
    uploadFiles(uploadtempFiles, qiniuyuntoken, domain) {
      const putExtra = {}
      const config = {}
      // 随机名字
      const key = this.getKey(uploadtempFiles)
      console.log(key)
      this.myUrl = domain + '/' + key
      this.$emit('successPhoto', domain + '/' + key)
      // 获取七牛云token
      const token = qiniuyuntoken
      //   console.log("uploadtempFiles", uploadtempFiles);
      //   console.log("key", key);
      //   console.log("token", qiniuyuntoken);
      var observable = qiniu.upload(
        uploadtempFiles,
        key,
        token,
        putExtra,
        config
      )
      var observer = {
        next(res) {
        },
        error(err) {
          console.log(err)
        },
        complete(res) {
          this.myUrl = domain + '/' + res.key
          localStorage.setItem('photo', domain + '/' + res.key)
          this.$emit('successPhoto', domain + '/' + res.key)
          //   console.log("在完成方法中拿photo",localStorage.getItem("photo"))
        }
      }
      var subscription = observable.subscribe(observer) // 上传开始
    },
    init(url) {
      this.myUrl = url
    },
    onHandelImageSelect(e, file) {
      this.$emit('changeImage', e, file)
    }
  }
}
</script>
<style lang="scss" scoped>
.avatar-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #999999;
  margin-left: 10px;
  // border-radius: 10px;
}
.pic {
  border: none;
}
</style>
