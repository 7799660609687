// 首页
import request from '@/utils/request'

// banner
export function banner(data) {
  return request({
    url: '/api/member/index/banner',
    method: 'post',
    data: data
  })
}

export function category(data) {
  return request({
    url: '/api/member/index/institution/category',
    method: 'post',
    data: data
  })
}

export function list(data) {
  return request({
    url: '/api/member/pc/institution/list',
    method: 'post',
    data: data
  })
}

export function hot(data) {
  return request({
    url: '/api/member/pc/institution/hot',
    method: 'post',
    data: data
  })
}

export function searchPoi(data) {
  return request({
    url: '/api/member/index/institution/search_poi',
    method: 'post',
    data,
  })
}

// 机构级联分类
export function getInstitutionCategoryList(data){
  return request({
    url: '/api/member/index/institution/category/search',
    method: 'post',
    data,
  })
}

// 机构搜索
export function getInstitutionSearch (data){
  return request({
    url: '/api/member/index/institution/search',
    method: 'post',
    data,
  })
}

// 机构详情
export function getInstitutionInfo(data) {
  return request({
    url: '/api/member/institution/info',
    method: 'post',
    data,
  })
}


// 价目表接口
export function getCourseList(data) {
  return request({
    url: '/api/member/institution/courseList',
    method: 'post',
    data,
  })
}

// 用户评论接口
export function commentList(data) {
  return request({
    url: '/api/member/institution/commentList',
    method: 'post',
    data,
  })
}

// 收藏
export function collect(data) {
  return request({
    url: '/api/member/institution/collect',
    method: 'post',
    data,
  })
}

// 教师列表接口
export function teachList(data) {
  return request({
    url: '/api/member/institution/teachList',
    method: 'post',
    data,
  })
}

// 课程详情接口
export function courseInfo(data) {
  return request({
    url: '/api/member/course/info',
    method: 'post',
    data,
  })
}

// 查看机构资质
export function viewZizhi(data) {
  return request({
    url: '/api/member/institution/viewZizhi',
    method: 'post',
    data,
  })
}

// 教师详情
export function teachInfoDetail(data) {
  return request({
    url: '/api/member/institution/teachInfo',
    method: 'post',
    data,
  })
}

// 预下单接口
export function preOrder(data) {
  return request({
    url: '/api/member/course/pre/order',
    method: 'post',
    data,
  })
}

// 创建订单接口
export function createOrder(data) {
  return request({
    url: '/api/member/course/createOrder',
    method: 'post',
    data,
  })
}

// 支付接口
export function payOrder(data) {
  return request({
    url: '/api/member/course/order/pay',
    method: 'post',
    data,
  })
}

// 进首页前置接口，要判断是否bind了手机号
export function checkBindPhone(data) {
  return request({
    url: '/api/member/index/checkBindPhone',
    method: 'post',
    data,
  })
}