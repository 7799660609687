<template>
	<div>
		<!-- ---------- -->

		<div class="footer_warp">
			<div class="footer_cont">
				<!-- 上部 -->
				<div class="footer_cont_l">
					<div class="footer_cont_l_title">联系我们</div>
					<div class="footer_cont_l_title_line"></div>
					<div class="footer_cont_l_address">地址：广州市天河区海安路13号888</div>
					<div class="footer_cont_l_address">邮箱：zangyu@famedbook.com</div>
				</div>
				<!-- top 中 -->
				<div class="footer_cont_c">
					<div class="footer_cont_c_tag">专属服务热线</div>
					<div class="footer_cont_c_phone">133-3286-0706</div>
				</div>
				<!-- top 右 -->
				<div class="footer_cont_r">
					<div class="footer_cont_r_code">
						<img src="/static/pc/1.jpg" alt="" style="width: 88px;height: 88px;borde-radius: 8px">
					</div>
					<div class="footer_cont_r_code" style="margin-right: 0">
						<img src="/static/pc/2.jpg" alt="" style="width: 88px;height: 88px;borde-radius: 8px">
					</div>
				</div>
			</div>
			<!-- 下部 -->
			<div class="footer_warp_btm">
				<p>
					2023-2024 © Copyright. 广州市名谱网络技术有限公司 - All Rights Reserved 粤ICP备2024226062号-1
				</p>
				<div class="flex">
					<el-image src="/static/pc/ga.png" style="width: 20px;height:20px;padding-right: 8px" fit="cover"></el-image>
					<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2024226062号-1</a>
				</div>
			</div>
		</div>

		<!-- --------- -->
	</div>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {
			arrList: [
				{
					title: "关于企业",
					url: "/jituangaikuo",
					id: "about",
				},
				{
					title: "企业实力",
					url: "/jituangaikuo",
					id: "CultureInfo",
				},
				{
					title: "发展历程",
					url: "/jituangaikuo",
					id: "history",
				},
			],
			arrList2: [
				{
					title: "金融板块",
					url: "/jinrong",
				},
				{
					title: "科技板块",
					url: "/keji",
				},
				{
					title: "电商板块",
					url: "/dianshang",
				},
				{
					title: "职教板块",
					url: "/zhijiao",
				},
				{
					title: "幼教板块",
					url: "/youjiao",
				},
			],
			arrList3: [
				{
					title: "集团要闻",
					url: "/xinwen",
				},
				{
					title: "媒体报道",
					url: "/xinwen/MediaCoverage",
				},
				{
					title: "行业资讯",
					url: "/xinwen/TradeNews",
				},
			],
		};
	},

	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},

	methods: {
		// 锚点跳转
		toGuid(path, id) {
			localStorage.setItem("toId", id);
			this.$router.push(path);
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},

		toPages(url) {
			this.$router.push(url);
		},
		toNews(url) {
			this.$router.push(url);
		},
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.footer_warp {
	width: 100vw;
	height: 274px;
	background: #fff;
}
.footer_cont {
	padding: 0 274px;
	display: flex;
	justify-content: space-between;
}
.footer_cont_l {
	font-size: 16px;
	font-weight: 500;
	color: #dddddd;
	line-height: 22px;
	padding-top: 77px;
	padding-bottom: 18px;
}
.footer_cont_l_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 24px;
	color: #333333;
	line-height: 34px;
	font-style: normal;
	text-transform: none;
}
.footer_cont_l_title_line {
	width: 48px;
	height: 4px;
	background: #FDC570;
	border-radius: 223px 223px 223px 223px;
	margin: 8px 0;
}
.footer_cont_l_address {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #333333;
	line-height: 20px;
	font-style: normal;
	text-transform: none;
}
.footer_cont_l_ul {
	margin-top: 36px;
}
.footer_cont_l_ul li {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	margin-top: 10px;
	cursor: pointer;
}
.footer_cont_l_div {
	display: flex;
}
.footer_cont_l_div_item {
	margin-right: 72px;
}
.footer_cont_c {
	margin-top: 70px;
	margin-left: 80px;
}
.footer_cont_c_tag {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #FFFFFF;
	line-height: 22px;
	font-style: normal;
	text-transform: none;
	background: #FDC570;
	border-radius: 24px 24px 24px 0px;
	padding: 8px 0;
	width: 128px;
	text-align: center;
	position: relative;
}
.footer_cont_c_tag::before {
	position: absolute;
	z-index: 1;
	left: 0;
	bottom: -18px;
	content: '';
	width: 0;
    height: 0;
    border-right: 24px solid transparent; /*右边透明*/
    border-top: 19px solid #FDC570; /*底部为红色*/
}
.footer_cont_c_phone {
	font-family: Alimama ShuHeiTi, Alimama ShuHeiTi;
	font-weight: 700;
	font-size: 48px;
	color: #333333;
	line-height: 59px;
	padding-left: 10px;
}

.footer_cont_r {
	margin-top: 79px;
	margin-left: 70px;
	display: flex;
	align-items: center;
}
.footer_cont_r_code {
	width: 88px;
	height: 88px;
	border-radius: 8px 8px 8px 8px;
	border: 1px solid #F5F5F5;
	margin-right: 16px;
}
.footer_warp_btm {
	width: calc(100vw - 550px);
	margin: 0 auto;
	height: 65px;
	margin-top: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #F5F5F5;
}
.footer_warp_btm a {
	font-size: 12px;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 48px;
}
</style>
