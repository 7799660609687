<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar></navbar>
		<!-- banner -->
		<el-carousel height="400px" style="margin-top: 20px">
      <el-carousel-item v-for="item in bannerList" :key="item">
				<el-image :src="item.coverImage" class="banner" style="width: 100%; height: 100%" fit="cover" @click="goBannerUrl(item)"></el-image>
      </el-carousel-item>
    </el-carousel>
		<!-- center -->
		<div class="index_c">
			<div class="index_tag_list">
				<!-- 动态绑定背景图片 -->
				<div class="index_tag_item" :style="{background: `url(${indexBg(item.categoryName)})`, backgroundSize: 'cover'}" v-for="(item, index) in categoryList" :key="index" @click="goUrl('/sumbit?institutionCategoryId=' + item.id)">
				</div>
				<!-- <div class="index_tag_item">
					<div class="index_tag_title">幼儿园</div>
					<div class="index_tag_main">Kindergarten</div>
				</div>
				<div class="index_tag_item">
					<div class="index_tag_title">托 育</div>
					<div class="index_tag_main">Trusteeship education</div>
				</div>
				<div class="index_tag_item">
					<div class="index_tag_title">月子中心</div>
					<div class="index_tag_main">Care center</div>
				</div> -->
			</div>
			<div class="flex-ju-sb" style="padding-bottom: 16px; padding-top: 16px">
				<div class="flex">
					<div class="tuijian">热门推荐</div>
					<div class="tuijian_en">Hot recommendation</div>
				</div>
				<div class="more" @click="goUrl('/sumbit')">更多</div>
			</div>
			<div class="content_list">
				<el-row :gutter="20">
					<el-col :span="6" v-for="(item, index) in hotList" :key="index">
						<div class="content_item" @click="goUrl('/detail?id=' + item.institutionId)">
							<div class="content_item_cover" :style="{background: `url(${item.coverImage || ''})`, backgroundSize: 'cover'}">
								<div class="content_item_cover_text m-text-line-2">{{ item.address }}</div>
							</div>
							<div class="content_item_bottom">
								<div class="content_item_bottom_title m-text-line-2">{{ item.institutionName }}</div>
								<div class="flex">
									<div :class="tabs == '签约' ? 'content_item_bottom_tag_item1' : 'content_item_bottom_tag_item'" v-for="tabs in item.tabs" :key="tabs">{{ tabs }}</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div v-for="(item, index) in dataList" :key="index">
				<div class="flex-ju-sb" style="padding-bottom: 16px; padding-top: 16px">
					<div class="flex">
						<div class="tuijian">{{ item.institutionCategory }}</div>
						<div class="tuijian_en">{{ item.enTitle }}</div>
					</div>
					<div class="more" @click="goUrl('/sumbit?institutionCategoryId=' + item.institutionCategoryId)">更多</div>
				</div>
				<div class="content_list">
					<el-row :gutter="20">
						<el-col :span="6" v-for="(item, index) in item.institutions" :key="index">
							<div class="content_item" @click="goUrl('/detail?id=' + item.institutionId)">
								<div class="content_item_cover" :style="{background: `url(${item.coverImage || ''})`, backgroundSize: 'cover'}">
									<div class="content_item_cover_text m-text-line-2">{{ item.address }}</div>
								</div>
								<div class="content_item_bottom">
									<div class="content_item_bottom_title m-text-line-2">{{ item.institutionName }}</div>
									<div class="flex">
										<div :class="tabs == '签约' ? 'content_item_bottom_tag_item1' : 'content_item_bottom_tag_item'" v-for="tabs in item.tabs" :key="tabs">{{ tabs }}</div>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
export default {
	name: 'index',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			bannerList: [],
			categoryList: [],
			dataList: [],
			hotList: [],
			params: {
				city: "广州市",
				country: "中国",
				district: "",
				lat: 23.132791,
				lon: 113.263177,
				province: "广东省",
			},
		};
	},
	computed: {
		title() {
			return this.$store.state.title;
		},
	},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		getBanner() {
			this.$api.home.banner().then(async res => {
				this.bannerList = res
				await this.getCategory()
				await this.getHot()
				await this.getList()
			})
		},
		getCategory() {
			this.$api.home.category().then(res => {
			  this.categoryList = res
			})
		},
		getHot() {
			this.$api.home.hot(this.params).then(res => {
			  this.hotList = res
			})
		},
		getList() {
			this.$api.home.list(this.params).then(res => {
			  this.dataList = res
				for (const key in this.dataList) {
					if (this.dataList[key].institutionCategory == '早教') {
						this.dataList[key].enTitle = 'Early education'
					}
					if (this.dataList[key].institutionCategory == '幼儿园') {
						this.dataList[key].enTitle = 'kindergarten'
					}
					if (this.dataList[key].institutionCategory == '托育') {
						this.dataList[key].enTitle = 'Trusteeship education'
					}
					if (this.dataList[key].institutionCategory == '月子中心') {
						this.dataList[key].enTitle = 'Care center'
					}
				}
			})
		},
		getAxiosList() {
			this.getBanner()
		},
		goBannerUrl(item) {
			// 1查看文章详情，2跳转课程，3跳转机构
      if (item.jumpType == 1) {
				this.$router.push({
					path: '/helpDetail',
					query: {
						title: '文章详情',
						content: item.articleInfo
					}
				})
      }
      if (item.jumpType == 2) {
				this.$router.push({
					path: '/course',
					query: {
						courseId: item.courseId,
						institutionId: item.institutionId
					}
				})
      }
      if (item.jumpType == 3) {
				this.$router.push({
					path: '/detail',
					query: {
						id: item.institutionId
					}
				})
      }
			
		},
		indexBg(name) {
			switch(name) {
				case '月子中心':
					return '/static/4.png'
				case '托育':
					return '/static/3.png'
				case '早教':
					return '/static/1.png'
				case '幼儿园':
					return '/static/2.png'
			}
		}
	},
	mounted() {
		if (localStorage.getItem('bupaolu_bind')) {
			localStorage.removeItem('bupaolu_bind')
			window.location.reload();
		}
		if (localStorage.getItem('bupaolu_address')) {
			let address = JSON.parse(localStorage.getItem('bupaolu_address'))
			console.log(address, 'address');
			this.params = {
				city: address.city || '广州市',
				country: address.country,
				district: address.district,
				lat: address.lat,
				lon: address.lon,
				province: address.province
			}
		}
		this.getAxiosList()
		
	},
	created() {
		// localStorage.setItem('bupaolu_address', JSON.stringify(this.params))
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: '微软雅黑', '宋体', Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: '';
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.homepage_wrap {
	width: 100vw;
	margin: 0 auto;
	background: #fafafa;
}

.banner {
	/* width: 100%;
	height: 500px; */
	cursor: pointer;
}

.index_c {
	width: 100%;
	padding: 40px 250px;
	box-sizing: border-box;
}
.index_tag_list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 44px;
}
.index_tag_item {
	width: 320px;
	height: 140px;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: 12px 12px 12px 12px;
	cursor: pointer;
}
.index_tag_title {
	font-family: Yuanti SC, Yuanti SC;
	font-weight: bold;
	font-size: 44px;
	color: #ffffff;
	text-align: center;
	padding-top: 22px;
	padding-bottom: 2px;
}
.index_tag_main {
	font-family: Yuanti SC, Yuanti SC;
	font-weight: 400;
	font-size: 14px;
	color: #ffffff;
	text-align: center;
}
.tuijian {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 28px;
	color: #333333;
	padding-right: 12px;
}
.tuijian_en {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 22px;
	color: #cccccc;
}
.more {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #999999;
	cursor: pointer;
}

.content_list {
	/* display: flex;
	align-items: center;
	justify-content: space-between; */
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 24px;
}
.content_item {
	background-color: #ffffff;
	cursor: pointer;
}
.content_item_cover {
	width: 340px;
	height: 340px;
	background: #d8d8d8;
	border-radius: 12px 12px 0 0;
	position: relative;
}
.content_item_cover_text {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #ffffff;
	padding: 10px 15px;
	box-sizing: border-box;
}

.content_item_bottom {
	background: #ffffff;
	border-radius: 0px 0px 12px 12px;
	box-sizing: border-box;
	padding: 16px;
}
.content_item_bottom_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 20px;
	color: #333333;
	padding-bottom: 12px;
}
.content_item_bottom_tag_item {
	background: #f5f5f5;
	border-radius: 2px 2px 2px 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 12px;
	color: #333333;
	padding: 2px 8px;
	box-sizing: border-box;
	margin-right: 16px;
}
.content_item_bottom_tag_item1 {
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(253, 197, 112, 0.1);
	border-radius: 2px 2px 2px 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 12px;
	color: #fdc570;
	padding: 2px 8px;
	box-sizing: border-box;
	margin-right: 16px;
}
</style>
