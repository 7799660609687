<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="1"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="title">订单信息填充</div>
      <div class="index_c_item">
        <div class="index_c_item_main" style="padding-bottom: 12px">
          {{ preOrderData.institutionName }}
        </div>
        <div class="flex">
          <div class="">
            <el-image :src="preOrderData.coverImage" style="width: 56px; height: 56px; border-radius: 8px;margin-right: 12px" fit="cover"></el-image>
          </div>
          <div style="width: 95%">
            <div class="index_c_item_title" style="padding-bottom: 4px">
              {{ preOrderData.courseName }}
            </div>
            <div class="flex-ju-sb">
              <div class="main">
                <span v-for="item in preOrderData.tabs" :key="item">{{ item }}</span>
              </div>
              <div class="price">￥{{ preOrderData.price }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="index_c_item" v-if="preOrderData.submitStuInfo">
        <div class="flex-ju-sb" style="padding-bottom: 16px">
          <div class="flex">
            <div class="yuan"></div>
            <div class="index_c_item_title">学生信息</div>
          </div>
          <div class="index_c_item_top_btn" @click="goUrl('/chlid?type=order')">新增孩子信息</div>
        </div>
        <div class="user_top">
          <div>学生姓名</div>
          <div>证件号码</div>
          <div></div>
        </div>
        <div class="user_item">
          <div>{{ student.name }}</div>
          <div>{{ hideIdCard(student.idCard) }}</div>
          <div class="status"></div>
        </div>
      </div>
      <div class="index_c_item" v-if="preOrderData.submitGuardianInfo">
        <div class="flex" style="padding-bottom: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">监护人信息</div>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="preOrderData.submitGuardianName">
          <div class="item_title" style="width: 70px; text-align: right">
            姓名：
          </div>
          <el-input
            placeholder="请填写监护人姓名"
            v-model="guardian.name"
            style="width: 320px"
          ></el-input>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="preOrderData.submitGuardianIdCard">
          <div class="item_title">证件号码：</div>
          <el-input
            placeholder="请填写监护人证件号码"
            v-model="guardian.idCard"
            style="width: 320px"
          ></el-input>
        </div>
        <div class="flex" style="padding-bottom: 16px" v-if="preOrderData.submitGuardianContact">
          <div class="item_title">联系电话：</div>
          <el-input
            placeholder="请填写监护人联系电话"
             v-model="guardian.contact"
            style="width: 320px"
          ></el-input>
        </div>
        <div class="flex" v-if="preOrderData.submitGuardianAddr">
          <div class="item_title">居住地址：</div>
          <el-input
            placeholder="请填写监护人居住地址"
            v-model="guardian.address"
            style="width: 320px"
          ></el-input>
        </div>
      </div>
      <div class="index_c_item" v-if="preOrderData.submitEmergencyContact">
        <div class="flex" style="padding-bottom: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">紧急联系人信息</div>
        </div>
        <div class="flex" style="padding-bottom: 16px">
          <div class="item_title" style="width: 70px; text-align: right">
            姓名：
          </div>
          <el-input
            placeholder="请填写紧急联系人姓名"
            v-model="emergencyContact.name"
            style="width: 320px"
          ></el-input>
        </div>
        <div class="flex">
          <div class="item_title">联系电话：</div>
          <el-input
            placeholder="请填写紧急联系人联系电话"
            v-model="emergencyContact.contact"
            style="width: 320px"
          ></el-input>
        </div>
      </div>
      <div class="index_c_item" v-if="preOrderData.submitCertificates">
        <div class="flex" style="padding-bottom: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">相关资料</div>
        </div>
        <div class="flex" style="padding-bottom: 12px">
          <div>户口本电子版</div>
          <div class="main" style="padding-left: 8px">上传户口本主页照片</div>
        </div>
        <div class="index_c_item_list">
          <upload :imageurl="hukouImage"  @successPhoto="hukouImageSuc" ref="hukouImage"></upload>
          <!-- <div class="index_c_item_list_item" v-for="item in hukouImage" :key="item">
            <div class="index_c_item_list_item_close">
              <i class="el-icon-error"></i>
            </div>
            图片
          </div> -->
        </div>
        <div class="flex" style="padding-top: 16px; padding-bottom: 12px">
          <div>房产证</div>
          <div class="main" style="padding-left: 8px">上传房产证主页照片</div>
        </div>
        <div class="index_c_item_list" style="border: none">
          <upload :imageurl="certificatesImage"  @successPhoto="certificatesImageSuc" ref="certificatesImage"></upload>
          <!-- <div class="index_c_item_list_item" v-for="item in certificatesImage" :key="item">
            <div class="index_c_item_list_item_close">
              <i class="el-icon-error"></i>
            </div>
            图片
          </div> -->
        </div>
        <div class="tips">
          提示：如果需求直接向机构提供纸质版户口本房产证复印件，而非在线提供电子版，可以点击跳过按钮并在之后线下提供
        </div>
      </div>
      <div class="index_c_item">
        <div class="flex" style="padding-bottom: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">合同文件</div>
          <div class="main" style="padding-left: 8px">
            请下载合同文件打印签字后拍照上传
          </div>
        </div>
        <template v-if="preOrderData.hetongVos.length">
          <div class="upload_item" v-for="item in preOrderData.hetongVos" :key="item">
            <div class="flex">
              <el-image src="/static/pc/ht.png" style="width: 24px; height: 24px" class="upload_icon"></el-image>
              <div class="upload_title">{{ item.hetongTitle }}</div>
            </div>
            <div class="flex">
              <div class="item_title" @click="heOpen(item)" style="cursor: pointer;">查看</div>
              <div class="item_title_1" @click="heDownLoad(item)" style="cursor: pointer;">下载</div>
            </div>
          </div>
        </template>
      </div>
      <div class="index_c_item">
        <div class="flex" style="padding-bottom: 16px">
          <div class="yuan"></div>
          <div class="index_c_item_title">购买须知</div>
        </div>
        <div class="item_title" style="word-break: break-all; font-weight: 400">
          <div v-html="detailData.purchaseNotes"></div>
        </div>
      </div>
      <div class="index_c_item">
        <div
          class="flex-ju-sb"
          style="padding-bottom: 16px; border-bottom: 1px solid #f5f5f5"
        >
          <div class="flex" style="cursor: pointer;" @click="coupon = !coupon">
            <div class="yuan"></div>
            <div class="index_c_item_title">使用现金券/优惠券</div>
            <div style="padding-left: 8px">
              <i
                :class="coupon ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                style="font-size: 20; font-weight: bold; color: #000"
              ></i>
            </div>
          </div>
          <div class="price" v-if="couponData.amount">￥{{ couponData.amount }}</div>
        </div>
        <div v-if="coupon && couponList.length != 0">
          <div class="flex-end" style="padding-top: 16px; padding-bottom: 8px">
            <div class="main">合计：</div>
            <div class="item_title">￥{{ detailData.price - (couponData.amount || 0) <= 0 ? 0 :  detailData.price - (couponData.amount || 0) }}</div>
          </div>
          <div class="flex-end" style="padding-bottom: 16px">
            <div class="main">优惠：</div>
            <div class="item_title">￥{{ couponData.amount }}</div>
          </div>
        </div>
        <div class="index_c_bottom_item">
          <div class="main">应付金额：</div>
          <div class="price" style="font-size: 24px">￥{{ detailData.price - (couponData.amount || 0) <= 0 ? 0 :  detailData.price - (couponData.amount || 0) }}</div>
          <div class="main" style="padding: 0 8px">平台托管</div>
          <i
            class="el-icon-question"
            style="color: #fdc570; font-size: 20px;cursor: pointer;"
            @click="ptOpen"
          ></i>
        </div>
        <div class="index_c_bottom_coupon" v-if="coupon && couponList.length != 0">
          <div style="margin-right: 24px" v-for="(item, index) in couponList" :key="index" @click="couponClick(item, index)">
            <div class="index_c_bottom_coupon_item_top">
              <div class="flex">
                <span style="font-size: 12px; font-weight: 500">￥</span>
                <span>{{ item.amount }}</span>
                <span style="padding-left: 8px">{{ item.name }}</span>
              </div>
              <!--  -->
              <div style="padding-top: 6px">有效期至：{{ formatDate(item.endDate) }}</div>
              <i class="el-icon-success coupon_check" v-if="couponIdx == index"></i>
            </div>
            <div class="index_c_bottom_coupon_item_bottom">
              仅限于新用户使用
            </div>
          </div>
        </div>
        <div class="index_c_bottom">
          <div class="flex">
            <i
              class="el-icon-success"
              style="color: #fdc570; font-size: 20px; margin-right: 8px"
              v-if="isReadHetong"
            ></i>
            <div class="radio" @click="radioClick" v-else></div>
            <span class="main">
              我已阅读并同意
              <span style="color: #333;cursor: pointer" @click="htOpen">《中小学生校外培训服务合同》</span>
              ，知晓与签约机构之间的权力和义务信息，自愿接受合同的约束。
            </span>
          </div>
          <div
            class="index_c_bottom_btn"
            :style="{
              background: !isReadHetong ? '#f5f5f5' : '#fdc570',
              color: !isReadHetong ? '#cccccc' : '#fff',
            }"
            @click="pay"
          >
            提交订单
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="70%"
      center
      :show-close="false"
    >
      <div class="dialog">
        <div class="flex-end" style="margin-top: -30px">
          <i
            class="el-icon-error"
            style="color: #fdc570; font-size: 24px; cursor: pointer"
            @click="centerDialogVisible = false"
          ></i>
        </div>
        <div class="dialog_content">
          <div v-html="heData.content"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="centerDialogVisible2"
      width="70%"
      center
      :show-close="false"
    >
      <div class="dialog">
        <div class="flex-end" style="margin-top: -30px">
          <i
            class="el-icon-error"
            style="color: #fdc570; font-size: 24px; cursor: pointer"
            @click="htClose()"
          ></i>
        </div>
        <template v-if="preOrderData.hetongVos.length">
          <div class="dialog_tab">
            <div :class="htIndex == index ? 'tab_active' : ''" v-for="(item, index) in preOrderData.hetongVos" :key="index" @click="htClick(index)">{{ item.hetongTitle }}</div>
          </div>
          <div class="dialog_content" v-if="preOrderData.hetongVos[htIndex]">
            <div v-html="preOrderData.hetongVos[htIndex].content"></div>
          </div>
        </template>
        <div class="flex-center">
          <div
            class="dialog_btn"
            :style="btnStyle"
            @click="htCheck()"
          >
            {{ dialogBtnText }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="centerDialogVisible1"
      width="70%"
      title="平台托管"
      center
      :show-close="false"
    >
      <div class="dialog">
        <div class="flex-end" style="margin-top: -50px">
          <i
            class="el-icon-error"
            style="color: #fdc570; font-size: 24px; cursor: pointer"
            @click="centerDialogVisible1 = false"
          ></i>
        </div>
        <div class="dialog_content">
          <iframe :src="ptUrl" frameborder="0" style="width: 100%;border: none"></iframe>
        </div>
      </div>
    </el-dialog>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
    <div id="pdf" v-show="pdfShow">
      <div v-html="heData.content"></div>
    </div>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import upload from '../components/Upload/qiniuUpload_s.vue'
import dayjs from 'dayjs'
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
    upload
  },
  data() {
    return {
      radioShow: false,
      centerDialogVisible: false,
      centerDialogVisible1: false,
      centerDialogVisible2: false,
      dialogBtnText: "我已阅读并同意本合同（等待3秒）",
      dialogBtn: false,
      btnStyle: {},
      courseId: '',
      institutionId: '',
      detailData: {},
      preOrderData: {},
      student: {
        gender: 0,
        idCard: '',
        idCardType: '',
        name: '',
        childId: ''
      },
      guardian: {
        address: '',
        contact: '',
        idCard: '',
        name: '',
      },
      emergencyContact: {
        contact: '',
        name: '',
      },
      hukouImage: '',
      certificatesImage: '',
      isReadHetong: false,
      couponList: [],
      coupon: false,
      couponIdx: null,
      couponData: {},
      couponId: null,
      heData: {},
      htIndex: 0,
      time: 3,
      pdfShow: false,
      ptUrl: 'https://pc.bupaolu123.com/gw/h5/platformHosting',
      timer: null
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      let params = {
        isReadHetong: this.isReadHetong,
        signImage: '',
        courseId: this.courseId,
        hukouImage: this.hukouImage,
        certificatesImage: this.certificatesImage,
        student: this.student,
        guardian: this.guardian,
        emergencyContact: this.emergencyContact,
        couponId: this.couponId || ''
      }
      localStorage.setItem('payInfo', JSON.stringify(params))
      this.$router.push(url);
    },
    radioClick() {
      let length = 0
			for (const key in this.preOrderData.hetongVos) {
				if (this.preOrderData.hetongVos[key].check) {
					length++
					if (length == this.preOrderData.hetongVos.length) {
						this.centerDialogVisible2 = false
            this.isReadHetong = true
            this.htIndex = 0
					} else {
            this.centerDialogVisible2 = true
            this.isReadHetong = false
            this.htIndex = 0;
          }
				} else {
            this.centerDialogVisible2 = true
            this.isReadHetong = false
            this.htIndex = 0;
             this.timer = setInterval(() => {
		      		if (this.time == 0) {
		      			clearInterval(this.timer);
		      			this.dialogBtnText = `我已阅读并同意本页合同`;
		      			this.btnStyle = {
		      				background: '#fdc570',
		      				color: '#fff',
		      			};
		      			this.preOrderData.hetongVos[this.htIndex].check = true
		      			return;
		      		}
		      		this.time--;
		      		this.dialogBtnText = `我已阅读并同意本页合同（等待${this.time}秒)`;
		      }, 1000);
        }
 			}
    },
    getPreOrder() {
      this.$api.home.preOrder({ id: this.courseId }).then((res) => {
        this.preOrderData = res
        if (this.preOrderData.hetongVos.length) {
          this.preOrderData.hetongVos.forEach(item => {
              item.check = false
            });
        }
      })
    },
    getDetail() {
      this.$api.home.courseInfo({
        courseId: this.courseId,
        institutionId: this.institutionId,
      }).then((res) => {
        this.detailData = res
      })
    },
    hideIdCard(idCard) {
      return idCard.replace(/^(.{6})(?:\d+)(.{4})$/, '$1****$2');
    },
    // 我的礼券
    getCouponList() {
      this.$api.me.couponList({ status: 0 }).then((res) => {
        this.couponList = res;
      });
    },
    couponClick(data, index) {
      this.couponIdx = index
      this.couponId = data.id
      this.couponData = data
    },
    heOpen(data) {
      this.heData = data
      this.centerDialogVisible = true
      console.log(data);
    },
    ptOpen() {
      this.centerDialogVisible1 = true
    },
    htClick(index) {
      this.htIndex = index
    },
    heDownLoad(item) {
      this.heData = item
      // 获取要导出为PDF的元素（此处假设我们要导出整个页面）
      const element = document.getElementById('pdf')
      // 使用html2canvas将元素转换为canvas对象
      this.pdfShow = true
      let that = this
      setTimeout(() => {
        html2canvas(element, {useCORS: true}).then((canvas) => {
        const imageData = canvas.toDataURL('image/png');
        // 创建PDF实例
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imageData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        console.log(imageData);
        // 将canvas画布作为图像添加到PDF
        pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        // 导出PDF文件
        pdf.save(`${item.hetongTitle}.pdf`);
        that.pdfShow = false
      });
      }, 10)
    },
    htOpen() {
      this.centerDialogVisible2 = true
       this.timer = setInterval(() => {
				if (this.time == 0) {
					clearInterval(this.timer);
					this.dialogBtnText = `我已阅读并同意本页合同`;
					this.btnStyle = {
						background: '#fdc570',
						color: '#fff',
					};
					this.preOrderData.hetongVos[this.htIndex].check = true
					return;
				}
				this.time--;
				this.dialogBtnText = `我已阅读并同意本页合同（等待${this.time}秒)`;
		}, 1000);
      // this.htCheck()
    },
    htCheck() {
      if (this.dialogBtnText == '我已阅读并同意本页合同') {
				this.time = 3;
				if (this.htIndex < this.preOrderData.hetongVos.length) {
					this.htIndex++
					this.dialogBtnText = '我已阅读并同意本页合同（等待3秒)',
					this.btnStyle = {
						background: '#f5f5f5',
						color: '#999999',
					}
					 this.timer = setInterval(() => {
						if (this.time == 0) {
							clearInterval(this.timer);
							this.dialogBtnText = `我已阅读并同意本页合同`;
							this.btnStyle = {
								background: '#fdc570',
								color: '#fff',
							};
              if (this.preOrderData.hetongVos[this.htIndex]) {
                this.preOrderData.hetongVos[this.htIndex].check = true
              }
							return;
						}
						this.time--;
						this.dialogBtnText = `我已阅读并同意本页合同（等待${this.time}秒)`;
					}, 1000);
				}
			}
			let length = 0
			for (const key in this.preOrderData.hetongVos) {
				if (this.preOrderData.hetongVos[key].check) {
					length++
					if (length == this.preOrderData.hetongVos.length) {
						this.centerDialogVisible2 = false
            this.isReadHetong = true
            this.htIndex = 0
            clearInterval(this.timer);
					}
				}
 			}
    },
    htClose() {
      let that = this
			let length = 0
			for (const key in this.preOrderData.hetongVos) {
				if (!this.preOrderData.hetongVos[key].check) {
          this.$message.error('还有合同没看完，请稍等')
				} else {
					length++
					if (length == this.preOrderData.hetongVos.length) {
						this.centerDialogVisible2 = false
            this.isReadHetong = true
            this.htIndex = 0
					}
				}
 			}
    },
    hukouImageSuc(e) {
      console.log(e, 'hukouImageSuc');
      this.hukouImage = e
    },
    certificatesImageSuc(e) {
      console.log(e, 'certificatesImageSuc');
      this.certificatesImage = e
    },
    pay() {
      if (this.isReadHetong) {
        let params = {
          isReadHetong: this.isReadHetong,
          signImage: '',
          courseId: this.courseId,
          hukouImage: this.hukouImage,
          certificatesImage: this.certificatesImage,
          student: this.student,
          guardian: this.guardian,
          emergencyContact: this.emergencyContact,
          couponId: this.couponId || ''
        }
        localStorage.setItem('payInfo', JSON.stringify(params))
        this.$api.home.createOrder(params).then((res) => {
          this.$router.push('/payInfo?sn=' + res.sn + '&price=' + res.price)
        })
      } else {
        this.$message.error('请先勾选协议')
      }
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
  },

  mounted() {
    this.institutionId = this.$route.query.institutionId
    this.courseId = this.$route.query.courseId
    this.getPreOrder()
    this.getDetail()
    this.getCouponList()
    const dataReceived = JSON.parse(sessionStorage.getItem('chlidData'));
    if (localStorage.getItem('payInfo')) {
      const params = JSON.parse(localStorage.getItem('payInfo'))
      this.hukouImage = params.hukouImage
      this.certificatesImage = params.certificatesImage
      this.student = params.student
      this.guardian = params.guardian
      this.emergencyContact = params.emergencyContact
      this.isReadHetong = params.isReadHetong
    }
    if (dataReceived) {
      this.student = dataReceived
      this.student.idCardType = this.student.cardType
      this.student.name = this.student.childName
      delete this.student.cardType
      delete this.student.childName
      // 处理接收到的数据
      console.log(this.student);
      // 清除存储的数据，避免重复读取
      sessionStorage.removeItem('chlidData');
    }
 
    // console.log(layui);
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  padding: 80px 350px;
}
.title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  padding-bottom: 16px;
}
.index_c_item {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}
.index_c_item_cover {
  width: 56px;
  height: 56px;
  border-radius: 8px 8px 8px 8px;
  margin-right: 12px;
}
.index_c_item_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #999999;
}
.index_c_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.price {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 20px;
  color: #f95620;
}
.user_top {
  padding: 12px 16px;
  background-color: #f5f5f5;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_item {
  padding: 12px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.index_c_item_top_btn {
  background: #fdc570;
  border-radius: 366px 366px 366px 366px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 16px;
  cursor: pointer;
}
.item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.status {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #f95620;
}
.index_c_item_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 16px;
}
.index_c_item_list_item {
  width: 88px;
  height: 88px;
  background: #f5f5f5;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  margin-bottom: 9px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
  position: relative;
}
.index_c_item_list_item_close {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fdc570;
  font-size: 20px;
}
.tips {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #f95620;
}
.upload_item {
  width: 388px;
  padding: 14px 12px;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.upload_icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.upload_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.item_title_1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #fdc570;
  padding-left: 16px;
}
.index_c_bottom_item {
  background: #fafafa;
  padding: 22px 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.index_c_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333;
}
.radio {
  width: 16px;
  height: 16px;
  border-radius: 75px 75px 75px 75px;
  border: 1px solid #dddddd;
  cursor: pointer;
  margin-right: 8px;
}
.index_c_bottom_btn {
  width: 216px;
  height: 48px;
  background: #f5f5f5;
  border-radius: 329px 329px 329px 329px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #cccccc;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.index_c_bottom_coupon {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.index_c_bottom_coupon_item_top {
  width: 264px;
  padding: 12px 16px;
  box-sizing: border-box;
  background: #f95620;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}
.coupon_check {
  position: absolute;
  top: 10px;
  right: 20px;
}
.index_c_bottom_coupon_item_bottom {
  width: 264px;
  padding: 12px 12px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #999999;
}
.dialog_tab {
  display: flex;
  align-items: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #999999;
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
}
.dialog_tab div {
  cursor: pointer;
  margin-right: 24px;
  padding-bottom: 10px;
}
.dialog_tab .tab_active {
  border-bottom: 4px solid #fdc570;
  padding-bottom: 10px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}
.dialog_content {
  margin-top: 16px;
  word-break: break-all;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  /* color: #9e9e9e; */
  margin-bottom: 24px;
}
.dialog_btn {
  width: 298px;
  height: 48px;
  background: #f5f5f5;
  border-radius: 329px 329px 329px 329px;
  text-align: center;
  line-height: 48px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #cccccc;
  cursor: pointer;
}
</style>
