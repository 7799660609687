<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="2"></navbar>

		<!-- center -->
		<div class="index_c">
			<div class="index_c_tab">
				<div @click="tagClick(0)" :class="params.type == 0 ? 'index_c_tab_item_active' : 'index_c_tab_item'">闲聊</div>
				<div @click="tagClick(1)" :class="params.type == 1 ? 'index_c_tab_item_active' : 'index_c_tab_item'">同城</div>
				<div @click="tagClick(2)" :class="params.type == 2 ? 'index_c_tab_item_active' : 'index_c_tab_item'">话题</div>
				<div @click="tagClick(3)" :class="params.type == 3 ? 'index_c_tab_item_active' : 'index_c_tab_item'">专家说</div>
			</div>
			<div class="index_c_tab">
				<div @click="tabClick(item.id)" v-for="item in tabList" :key="item" :class="params.topicId == item.id ? 'index_c_tab_item_active' : 'index_c_tab_item'">{{ item.topicName }}</div>
			</div>
			<div class="index_c_list">
				<div class="index_c_item" v-for="(item, index) in dataList" :key="index" @click="goUrl('/communityDetail?id=' + item.postsId)">
					<el-image fit="cover" :src="item.images[0]" style="width: 282px; height: 282px" class="index_c_item_cover"></el-image>
					<div class="index_c_item_title m-text-line-2">
						{{ item.content }}
					</div>
					<div class="flex-ju-sb">
						<div class="flex">
							<el-image fit="cover" :src="item.avatar" style="width: 24px; height: 24px" class="index_c_item_avatar" @click.stop="goUrl('/user_community?id=' + item.memberId)"></el-image>
							<div class="index_c_item_name">{{ item.nickname }}</div>
						</div>
						<div class="flex" @click.stop="getZan(item.postsId, index)">
							<el-image :src="item.zan ? '/static/pc/zan1.png' : '/static/pc/zan.png'" fit="cover" style="width: 20px; height: 20px"></el-image>
							<div class="index_c_item_num">{{ item.zanCount }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
export default {
	name: 'demo',
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			tabIdx: 0,
			params: {
				city: '广州市',
				keywords: null,
				pageNumber: 1,
				pageSize: 20,
				topicId: 0,
				type: 0,
			},
			tabList: [],
			dataList: [],
		};
	},
	computed: {},
	methods: {
		goUrl(url) {
			this.$router.push(url);
		},
		typeList() {
			this.$api.community.topicList().then(res => {
				this.tabList = res;
				this.params.topicId = res[0].id;
				this.getList();
			});
		},
		async tagClick(index) {
			this.params.type = index;
			this.params.pageNumber = 1;
			this.dataList = [];
			if (index == 2) {
				this.typeList();
			} else {
				this.getList();
			}
		},
		tabClick(id) {
			this.params.topicId = id;
			this.dataList = [];
			this.params.pageNumber = 1;
			this.getList();
		},
		getList() {
			this.$api.community.postsList(this.params).then(res => {
				if (res.records != null) {
					// 合并数组
					this.dataList = [...this.dataList, ...res.records];
				}
			});
		},
		getZan(id, index) {
			this.$api.community.zan({ id: id }).then(res => {
				console.log(res);
				this.$message.success(res);
				if (this.dataList[index].zan) {
					this.dataList[index].zan = false;
					this.dataList[index].zanCount--;
				} else {
					this.dataList[index].zan = true;
					this.dataList[index].zanCount++;
				}
				this.$forceUpdate();
			});
		},
	},
	mounted() {
		// console.log(layui);
		if (localStorage.getItem('bupaolu_address')) {
			this.city = JSON.parse(localStorage.getItem('bupaolu_address')).city
		} else {
			this.city = '广州市';
		}
		this.getList();
	},
	created() {},
};
</script>
<style scoped>
.index_c {
	padding: 40px 200px;
  padding-left: 320px;
}
.index_c_tab {
	padding: 0 400px;
	padding-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.index_c_tab_item_active {
	background: #f5f5f5;
	border-radius: 264px 264px 264px 264px;
	padding: 8px 24px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	cursor: pointer;
}
.index_c_tab_item {
	border-radius: 264px 264px 264px 264px;
	border: 1px solid #f5f5f5;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #999999;
	padding: 8px 24px;
	cursor: pointer;
}
.index_c_list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.index_c_item {
	margin-bottom: 48px;
	cursor: pointer;
  margin-right: 28px;
}
.index_c_item_cover {
	width: 282px;
	height: 282px;
	border-radius: 16px 16px 16px 16px;
	margin-bottom: 16px;
}
.index_c_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	margin-bottom: 8px;
	width: 282px;
}
.index_c_item_avatar {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	margin-right: 8px;
}
.index_c_item_name {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #333333;
}
.index_c_item_num {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #333333;
	padding-left: 6px;
}
</style>
