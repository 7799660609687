// 消息接口
import https from "@/utils/request";

export const list = (data) => {
  return https({
    url: "/api/member/message/list",
    method: 'post',
    data,
  });
};

export const helpArticleList = (data) => {
  return https({
    url: "/api/member/helpArticleList",
    method: 'post',
    data,
  });
};