<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="1"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="flex-ju-sb" style="padding-bottom: 18px">
        <div class="title">安全设置</div>
        <div class="flex">
          <div class="btn" @click="goBack">返回</div>
        </div>
      </div>
      <div class="index_c_item">
        <div class="flex-ju-sb" style="padding-bottom: 40px">
          <div style="text-align: center">
            <div :class="index == 0 ? 'index_yuan_active' : 'index_yuan'">
              1
            </div>
            <div :class="index >= 0 ? 'yuan_title' : 'main'">身份认证</div>
          </div>
          <div class="index_line"></div>
          <div style="text-align: center">
            <div :class="index == 1 ? 'index_yuan_active' : 'index_yuan'">
              2
            </div>
            <div :class="index >= 1 ? 'yuan_title' : 'main'">设置新号码</div>
          </div>
          <div class="index_line"></div>
          <div style="text-align: center">
            <div :class="index == 2 ? 'index_yuan_active' : 'index_yuan'">
              3
            </div>
            <div :class="index >= 2 ? 'yuan_title' : 'main'">完成</div>
          </div>
        </div>
        <div v-if="index == 0">
          <div
            class="index_c_item_title flex-center"
            style="padding-bottom: 40px"
          >
            当前登录手机号：{{ userInfo.phone }}
          </div>
          <div class="flex" style="padding-bottom: 24px; padding-left: 70px">
            <div class="input_title" style="padding-right: 42px">验证码</div>
            <el-input
							v-model="verifyCode"
              placeholder="请输入验证码"
              style="width: 320px"
            ></el-input>
            <!-- <div class="code_img"></div> -->
            <el-image
              :src="codeUrl"
              class="code_img"
              style="width: 102px; height: 36px"
              @click="getCode('bing_phone_change1')"
            ></el-image>
          </div>
          <div class="flex" style="padding-bottom: 24px; padding-left: 70px">
            <div class="input_title">短信验证码：</div>
            <el-input
							v-model="code"
              placeholder="请输入验证码"
              style="width: 320px"
            ></el-input>
            <el-button class="code_btn" :disabled="countdown > 0" @click="startCountdown">
              {{ countdown > 0 ? '已发送' + countdown + '秒' : '发送验证码' }}
            </el-button>
          </div>
          <div class="sumbit" @click="sumbit('1')">下一步</div>
        </div>
        <div v-if="index == 1">
          <div
            class="flex"
            style="padding-bottom: 24px; padding-left: 70px; padding-top: 40px"
          >
            <div class="input_title">新手机号码：</div>
            <el-input
							v-model="phone"
              maxlength="11"
              placeholder="请输入验证码"
              style="width: 320px"
            ></el-input>
          </div>
          <div class="flex" style="padding-bottom: 24px; padding-left: 70px">
            <div class="input_title" style="padding-right: 42px">验证码</div>
            <el-input
							v-model="verifyCode"
              placeholder="请输入验证码"
              style="width: 320px"
            ></el-input>
            <el-image
              :src="codeUrl"
              class="code_img"
              style="width: 102px; height: 36px"
              @click="getCode('bing_phone_change2')"
            ></el-image>
          </div>
          <div class="flex" style="padding-bottom: 24px; padding-left: 70px">
            <div class="input_title">短信验证码：</div>
            <el-input
							v-model="code"
              placeholder="请输入验证码"
              style="width: 320px"
            ></el-input>
						<el-button class="code_btn" :disabled="countdown > 0" @click="startCountdown">
              {{ countdown > 0 ? '已发送' + countdown + '秒' : '发送验证码' }}
            </el-button>
            <!-- <div class="code_btn">获取验证码</div> -->
          </div>
          <div class="sumbit" @click="sumbit('2')">下一步</div>
        </div>
        <div v-if="index == 2">
          <div
            class="index_c_item_title flex-center"
            style="padding-bottom: 20px"
          >
            您的登录手机号已更换为：
          </div>
          <div class="index_c_item_title flex-center">{{ phone }}</div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      index: 0,
      codeUrl: "", // 验证码图片
      userInfo: JSON.parse(localStorage.getItem("bupaolu_userInfo")),
			countdown: 0,
      timer: null,
			code: '',
			verifyCode: '',
			time: null,
			phone: ''
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    goBack() {
      this.$router.go(-1);
    },
    sumbit(type) {
			if (Number(type) == 1) {
				this.$api.me.changePhoneCheck({ code: this.code }).then(res => {
					this.index++;
					this.code = ''
					this.verifyCode = ''
      	})
			} else if (Number(type) == 2) {
				this.$api.me.changePhone({ code: this.code, phone: this.phone }).then(res => {
					this.index++;
				})
			} else {
				this.index++;
			}
    },
    getCode(type) {
      this.time = type + Number(new Date());
      this.codeUrl = this.$api.login.code(this.time);
    },
		startCountdown() {
      this.countdown = 59;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
      this.goCode()
    },
    goCode() {
      let params = {
        phone: this.userInfo.phone,
        verifyCode: this.verifyCode,
        timestamp: this.time,
        type: 4
      };
      this.$api.login.sendCode1(params).then((res) => {
        this.$message.success(res);
      });
    },
  },
  mounted() {
    this.getCode();
    // console.log(layui);
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  padding: 80px 300px;
}
.title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.index_c_item {
  padding: 40px 312px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 12px;
}
.btn {
  background: #ffffff;
  border-radius: 366px 366px 366px 366px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  padding: 8px 24px;
  margin-right: 16px;
  cursor: pointer;
}
.index_yuan_active {
  width: 48px;
  height: 48px;
  background: #fdc570;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
  border-radius: 50%;
}
.index_yuan {
  width: 48px;
  height: 48px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #999999;
  border-radius: 50%;
}
.index_line {
  width: 176px;
  height: 2px;
  background-color: #f5f5f5;
}
.main {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #999999;
  margin-left: -10px;
  padding-top: 8px;
}
.yuan_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  margin-left: -10px;
  padding-top: 8px;
}
.index_c_item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.input_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.input_title::before {
  content: "*";
  font-family: PingFang SC, PingFang SC;
  font-size: 14px;
  color: #f95620;
}
.code_img {
  width: 102px;
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  margin-left: 16px;
  cursor: pointer;
}
.code_btn {
  width: 102px;
	padding: 0px 0;
  background: #fdc570;
  border-radius: 4px 4px 4px 4px;
  margin-left: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.sumbit {
  cursor: pointer;
  background: #fdc570;
  border-radius: 366px 366px 366px 366px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 13px;
  margin: 0 70px;
  text-align: center;
}
</style>
