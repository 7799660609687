<template>
  <div id="app">
    <!-- <daohang> </daohang> -->

    <router-view></router-view>
    <!-- <dibudaohang> </dibudaohang> -->
  </div>
</template>

<script>
// import daohang from "./components/index/daohang.vue";
// import dibudaohang from "./components/dibudaohang/dibudaohang";

// import ZoomRatio from '@/utils/zoom'
// import DevicePixelRatio from '@/utils/devicePixelRatio'
export default {
  name: "App",
  components: {
    // daohang,
    // dibudaohang
  },
  created() {
    // new ZoomRatio().init()
    // new DevicePixelRatio().init()
  }
};
</script>

<style>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: '微软雅黑', '宋体', Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: '';
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/
body {
  margin: 0;
  overflow-x: hidden;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-ju-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-ju-se {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flex-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #FDC570 !important;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
  background: #FFFFFF !important;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #EEEEEE;
}
.yuan {
  width: 16px;
  height: 16px;
  background: #fdc570;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
}
.el-radio__input.is-checked .el-radio__inner {
  background: #fdc570;
  border-color: #fdc570;
}
.el-radio__input.is-checked+.el-radio__label {
  color: #333;
}
.yuan::before {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}
.border-b {
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
}
.el-dialog {
  border-radius: 12px !important;
}
.popover_login {
    top: 12px !important;
    background-color: unset !important;
    border: none !important;
    box-shadow: unset !important;
}
.popover_login .popper__arrow,
.el-popper .popper__arrow::after {
    display: none !important;
}

.popover_drow {
  left: 25px !important;
}
.popover_drow .popper__arrow,
.el-popper .popper__arrow::after {
    display: none !important;
}
/* start--文本行数限制--start */
.m-text-line-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.m-text-line-2 {
  -webkit-line-clamp: 2;
}

.m-text-line-3 {
  -webkit-line-clamp: 3;
}

.m-text-line-4 {
  -webkit-line-clamp: 4;
}

.m-text-line-5 {
  -webkit-line-clamp: 5;
}

.m-text-line-2, .m-text-line-3, .m-text-line-4, .m-text-line-5 {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.check {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  border: 1px solid #333;
}
.amap-info-close, .amap-info-sharp{
    display: none !important;
  }
.amap-info-content {
  padding: 0 !important;
}
.amap-icon img {
  position: absolute;
  z-index: -1;
  width: 40px;
  height: 40px;
  left: -8px !important;
}
/* id app 页面最小宽度1280 */

@media screen and (min-width: 1280px) {
  #app {
    min-width: 1280px;
  }
}
/* 页面最小宽度1280 */
@media screen and (max-width: 1280px) {
  #app {
    max-width: 1280px;
  }
}
/* 页面最小宽度1280 */
@media screen and (min-width: 1440px) {
  #app {
    min-width: 1440px;
  }
}
</style>
