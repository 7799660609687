<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="1"></navbar>

    <!-- center -->
    <div class="index_c">
      <div class="flex-ju-sb" style="padding-bottom: 18px">
        <div class="title">{{ title }}</div>
        <div class="flex">
          <div class="btn" @click="goBack">返回</div>
        </div>
      </div>
      <div class="index_c_item">
        <iframe :src="src" style="width: 100%;height: 60vh;border: none"></iframe>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      src: "",
	  title: ''
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
	let baseURL = 'https://pc.bupaolu123.com/gw' //正式
	this.title = this.$route.query.title
    if (this.$route.query.type == "privacyPolicy") {
      this.src = baseURL + "/h5/privacyPolicy";
    } else {
      this.src = baseURL + "/h5/register";
    }
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  padding: 80px 300px;
}
.index_c_item {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  word-break: break-all;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.btn {
  background: #ffffff;
  border-radius: 366px 366px 366px 366px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  padding: 8px 24px;
  margin-right: 16px;
  cursor: pointer;
}
.title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
</style>
