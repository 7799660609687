import * as home from './home'
import * as login from './login'
import * as me from './me'
import * as detail from './detail'
import * as community from './community'
import * as message from './message'
import * as common from './common'
import * as order from './order'


export default {
  home, // 首页API
  login,
  me,
  detail,
  community,
  message,
  common,
  order
}
