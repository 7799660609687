<template>
  <!-- star -->
  <div class="homepage_wrap">
    <navbar :tabIdx="0"></navbar>
    <!-- center -->
    <div :style="{ width: '100%', height: '900px', position: 'relative', marginTop: '20px'}">
      <el-amap
        :vid="'amap-vue'"
        :zoom="zoom"
        :center="center"
        :events="events"
      >
        <el-amap-marker
          :position="marker.position"
          :events="marker.events"
          :visible="marker.visible"
          :draggable="marker.draggable"
          :icon="marker.icon"
        />
        <el-amap-info-window isCuston="true" :position="[item.lon, item.lat]" :visible="true" :offset="[0, -30]" v-for="item in dataList" :key="item">
          <div class="infoWindowBox" @click="goUrl('/detail?id=' + item.institutionId)">{{ item.institutionName }}</div>
        </el-amap-info-window>
      </el-amap>
    <div class="index_c">
      <div class="index_c_item" :style="{height: dataList.length == 0 ? 'auto' : '900px'}">
        <div class="index_c_item_form"  :style="{height: dataList.length == 0 ? 'auto' : '808px', marginLeft: '24px'}">
          <div class="flex" style="padding-bottom: 12px">
            <input
              type="text"
              class="input_form"
              placeholder="搜索机构、地点等"
              v-model="params.institutionName"
              @confrim="getList"
            />
            <div class="input_btn" @click="getList()">搜索</div>
          </div>
          <div class="border-b drow flex">
            <el-popover
              popper-class="popover_drow"
              placement="bottom"
              width="353"
              trigger="click"
            >
              <div class="flex" slot="reference" style="cursor: pointer">
                {{ cateName ? cateName : '不限分类' }}
                {{ cateName1 && cateName ? `/${cateName1}` : '' }}
                <i
                  class="el-icon-arrow-down"
                  style="
                    color: #333;
                    font-weight: bold;
                    font-size: 16px;
                    margin-left: 8px;
                  "
                ></i>
              </div>
              <div class="popover_box">
                <div>
                  <div :class="cateIdx == index ? 'popover_box_title_active' : ''"  v-for="(item, index) in categoryList" :key="index" @click="cateClick(index, item)">{{ item.institutionCategoryName }}</div>
                </div>
                <div class="popover_line"></div>
                <div>
                  <div :class="cateIdx1 == index ? 'popover_box_title_active' : ''" v-for="(item, index) in categoryList[cateIdx].childCategoryVos" :key="index" @click="cateClick1(index, item)">{{ item.institutionCategoryName }}</div>
                  <!-- <div>全部</div>
                  <div>小班</div>
                  <div>中班</div>
                  <div>大班</div>
                  <div style="visibility: hidden;">大班</div> -->
                </div>
              </div>
            </el-popover>
          </div>
          <div class="index_c_list" v-if="dataList.length != 0">
            <div
              class="index_c_list_item border-b"
              v-for="(item, index) in dataList"
              :key="index"
              @click="goUrl('/detail?id=' + item.institutionId)"
            >
              <div class="flex" style="align-items: flex-start">
                <div class="item_yuan">{{ index + 1 }}</div>
                <div style="width: 90%">
                  <div class="item_title">
                    {{ item.institutionName }}
                  </div>
                  <div
                    class="flex"
                    style="padding-top: 15px; padding-bottom: 16px"
                  >
                  <div :class="tabs == '签约' ? 'content_item_bottom_tag_item1' : 'content_item_bottom_tag_item'" v-for="tabs in item.tabs" :key="tabs">{{ tabs }}</div>
                  </div>
                  <div class="item_main">
                    <div class="flex" style="align-items: baseline">
                      <i
                        class="el-icon-location-outline"
                        style="padding-right: 11px"
                      ></i>
                      <div class="m-text-line-2">
                        {{ item.address }}
                      </div>
                    </div>
                  </div>
                  <div class="item_main" style="padding-top: 16px">
                    <div class="flex" style="align-items: baseline">
                      <i class="el-icon-phone" style="padding-right: 11px"></i>
                      {{ item.contact }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- footer -->
    <footer2></footer2>
    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import navbar from "../components/nav.vue";
export default {
  name: "demo",
  components: {
    footer2,
    navbar,
  },
  data() {
    return {
      zoom: 14, // 地图大小
      center: [113.385642, 22.935482], // 地图中心点
      marker: {
        // marker点
        position: [113.385642, 22.935482],
        visible: true,
        draggable: false,
        icon: '/static/pc/icon_dingwei@2x.png'
      },
      searchOption: {
        // city: '上海',
        citylimit: false
      },
      events: {
        // 地图点击事件
        click: (e) => {
          console.log(e)
          this.marker.position = [e.lnglat.lng, e.lnglat.lat]
          this.center = [e.lnglat.lng, e.lnglat.lat]
        }
      },
      textMarker: {
        text: '当前位置',
        position: [113.385642, 22.935482],
        events: {
          click: (e) => {
            if (this.textMarker.id) {
              this.$router.push('/detail?id=' + this.textMarker.id)
            }
            console.log(e, '11111')
          }
        },
      },
      params: {
				city: '广州市',
				categoryLevel1Id: 0,
				categoryLevel2Id: null,
				lat: 23.13014,
				lon: 113.263177,
				sortType: null,
				institutionName: null
			},
      cateIdx: 0,
			cateIdx1: null,
      categoryList: [],
      cateName: '',
      cateName1: '',
      dataList: []
    };
  },
  computed: {},
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    goBack() {
      this.$router.go(-1);
    },
    getList() {
      this.$api.home.searchPoi(this.params).then(res => {
        this.dataList = res
        if (this.dataList.length != 0) {
          this.marker.position = [res[0].lon, res[0].lat]
          this.center = [res[0].lon, res[0].lat]
        }
      })
    },
    cateClick(index, data) {
			this.cateIdx = index
			this.params.categoryLevel1Id = data.institutionCategoryId
      this.cateName = data.institutionCategoryId == 0 ? '' : data.institutionCategoryName
      this.cateIdx1 = null
			this.params.categoryLevel2Id = null
      this.cateName1 = ''
      this.getList()
		},
		cateClick1(index, data) {
			this.cateIdx1 = index
			this.params.categoryLevel2Id = data.institutionCategoryId
      this.cateName1 = data.institutionCategoryName
      this.getList()
		},
    getInstitutionCategoryList() {
      this.$api.home
        .getInstitutionCategoryList({ institutionName: "" })
        .then((res) => {
					this.categoryList = res
          for (const key in this.categoryList) {
            if (this.categoryList[key].institutionCategoryId == this.query.categoryLevel1Id) {
             this.cateIdx = Number(key)
            }
          }
				});
    },
  },
  mounted() {
    this.getInstitutionCategoryList()
    this.getList()
    // console.log(layui);
  },
  created() {},
};
</script>
<style scoped>
.homepage_wrap {
  background: #fafafa;
}
.index_c {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}
.index_c_item {
  /* background-color: #d8d8d8; */
  height: 900px;
  display: flex;
  align-items: center;
}
.index_c_item_form {
  width: 380px;
  height: 808px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  padding: 16px;
  box-sizing: border-box;
}
.input_form {
  width: 280px;
  height: 36px;
  background: #f5f5f5;
  border-radius: 277px 277px 277px 277px;
  border: none;
  padding: 5px 20px;
  box-sizing: border-box;
}
.input_btn {
  width: 60px;
  height: 36px;
  background: #fdc570;
  border-radius: 277px 277px 277px 277px;
  text-align: center;
  line-height: 36px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  margin-left: 24px;
  cursor: pointer;
}
.drow {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}
.index_c_list {
  overflow-y: scroll;
  height: 90%;
}
.index_c_list::-webkit-scrollbar {
  width: 0;
}
.index_c_list_item {
  width: 348px;
  padding: 16px 0;
  box-sizing: border-box;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
}
.item_title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.item_yuan {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fdc570;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  margin-right: 12px;
}
.item_main {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.content_item_bottom_tag_item {
  background: #f5f5f5;
  border-radius: 2px 2px 2px 2px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 16px;
}
.content_item_bottom_tag_item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(253, 197, 112, 0.1);
  border-radius: 2px 2px 2px 2px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #fdc570;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 16px;
}
.popover_box {
  padding: 24px 70px;
  background-color: #ffffff;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.popover_box div {
  padding-bottom: 16px;
  cursor: pointer;
}
.popover_box_title_active {
  color: #fdc570;
  font-weight: bold;
}
.popover_line {
  width: 1px;
  height: 164px;
  background: #f5f5f5;
}
.infoWindowBox {
  background: #81D3F8;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
</style>
