<template>
	<div>
		<div class="wrap">
			<div class="nav_top">
				<div class="nav_top_left" @click="centerDialogVisible1 = true">
					<div class="nav_top_title" style="cursor: pointer;">{{ addressName }}</div>
					<div class="nav_top_main">【切换城市】</div>
				</div>
				<div class="nav_top_right">
					<div class="nav_top_title" style="padding-right: 24px; cursor: pointer;" @click="goUrl('/map')">地图搜索</div>
					<div class="nav_top_title" style="padding-right: 24px; cursor: pointer;" @click="centerDialogVisible3 = true">联系客服</div>
					<div class="nav_top_title" style="padding-right: 24px; cursor: pointer;" @click="goUrl('/help')">帮助中心</div>
					<div class="nav_top_title" style="padding-right: 24px; cursor: pointer;" @click="goUrl('/msg')">消息</div>
					<div class="nav_top_title" style="padding-right: 24px; cursor: pointer;" v-if="!token" @click="goUrl('/login')">
						<span class="nav_top_main">注册</span>
						/登录
					</div>
					<div v-else>
						<el-popover popper-class="popover_login" placement="bottom" width="200" trigger="hover">
							<div class="user_tab">
								<div class="flex">
									<!-- <div class="user_tab_avatar"></div> -->
									<div class="">
										<el-image :src="userData.avatar" style="border-radius: 50%; width: 56px; height: 56px;margin-right: 8px"></el-image>
									</div>
									<div style="max-width: 100px">
										<div class="user_tab_name m-text-line-1">{{ userData.nickname }}</div>
										<div class="user_tab_tag" @click="isAuthOpen()">{{ userData.isAuthExpert ? '已认证专家' : '未认证专家' }}</div>
									</div>
								</div>
							</div>
							<div class="user_tab_item flex-ju-sb" @click="goUrl('/userinfo')">
								<div>用户中心</div>
								<i class="el-icon-arrow-right" style="color: #ccc; font-size: 18px"></i>
							</div>
							<div class="user_tab_item flex-ju-sb">
								<div>联系客服</div>
								<i class="el-icon-arrow-right" style="color: #ccc; font-size: 18px"></i>
							</div>
							<div class="user_tab_item flex-ju-sb" style="border-bottom-left-radius: 12px; border-bottom-right-radius: 12px" @click="loginOut">
								<div>退出登录</div>
								<i class="el-icon-arrow-right" style="color: #ccc; font-size: 18px"></i>
							</div>
							<div class="flex" slot="reference" style="cursor: pointer;">
								<!-- <div class="avatar"></div> -->
								<el-image :src="userData.avatar" style="border-radius: 50%; width: 20px; height: 20px" class="avatar"></el-image>
								<div class="user_name">{{ userData.nickname }}</div>
							</div>
						</el-popover>
					</div>
				</div>
			</div>
			<div class="nav_bar">
				<div style="display: flex; align-item: center">
					<!-- <div class="logo"></div> -->
					<el-image src="/static/logo.png" style="width: 132px;cursor: pointer" fit="fill" class="logo" @click="funRouter(0, '/')"></el-image>
					<div class="tab_list">
						<div :class="tabIdx == 0 ? 'tab_item_active' : 'tab_item'" @click="funRouter(0, '/')">首页</div>
						<div :class="tabIdx == 1 ? 'tab_item_active' : 'tab_item'" @click="funRouter(1, '/sumbit?type=nav')">机构</div>
						<div :class="tabIdx == 2 ? 'tab_item_active' : 'tab_item'" @click="funRouter(2, '/community')">社区</div>
					</div>
				</div>
				<div style="display: flex; align-item: center">
					<div class="input_box" style="padding: 10px">
						<input type="text" v-model="keywords" :placeholder="tabIdx == 2 ? '搜索' : '搜索机构'" />
					</div>
					<div class="sumbit_btn" @click="goSumbit()">搜索</div>
				</div>
				<div style="padding: 10px" v-if="tabIdx == 2">
					<div class="sumbit_btn_add" @click="goUrl('/subject')">发布</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="centerDialogVisible1" width="50%" title="切换城市" center>
			<el-input v-model="inputKey" @input="searchInput" placeholder="请输入城市名称/拼音首字母" prefix-icon="el-icon-search" style="width: 300px"></el-input>
			<div class="nav_top_title" style="padding: 12px 0; color: #333">我的城市</div>
			<div class="flex-wrap" style="padding-bottom: 12px">
				<div class="city_item">{{ addressName }}</div>
			</div>
			<div v-if="searchList.length != 0" style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);padding: 0 5px">
				<div class="nav_top_title" style="padding: 12px 0; color: #333">搜索城市</div>
				<el-row>
					<el-col :span="1.5" v-for="item in searchList" :key="item">
						<div class="city_item" @click.stop="selectIndex(item)">{{ item.areaName }}</div>
					</el-col>
				</el-row>
			</div>
			<div class="menu">
				<div class="city_menu_container">
					<div :class="areaId == item ? 'area_active' : 'area'" v-for="(item, index) in option.alphabet" :key="index" @click.stop="areaRight(item)">
						<div class="itemTit" style="text-align: center" ref="item.letter" :id="index">{{ item }}</div>
					</div>
				</div>
				<div class="city_menu_list" @scroll="onScroll">
					<div v-for="(item,index) in  option.cityList" :key="index">
						<div class="nav_top_title" style="padding: 12px 0; color: #333" :id="'hao-'+index">{{index}}</div>
							<el-row>
								<el-col :span="1.5" v-for="(ite,ind) in item" :key="ite['areaId']" class="hao-secondEach">
									<div class="city_item" @click.stop="selectIndex(ite)">{{ite['areaName']}}</div>
								</el-col>
							</el-row>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="centerDialogVisible2" width="30%" title="认证专家" center>
			<div class="flex-center">
				<div>
					<div class="flex" style="padding-bottom: 20px">
						<div class="ex_title xing">真实姓名</div>
						<el-input v-model="isAuth.realName" placeholder="请输入真实姓名" style="width: 300px"></el-input>
					</div>
					<div class="flex" style="padding-bottom: 20px">
						<div class="ex_title xing">联系电话</div>
						<el-input v-model="isAuth.contact" placeholder="请输入联系电话" style="width: 300px"></el-input>
					</div>
					<div class="flex" style="padding-bottom: 20px">
						<div class="ex_title" style="padding-right: 20px">擅长领域</div>
						<el-input type="textarea" v-model="isAuth.speText" :rows="5" placeholder="请输入擅长领域" style="width: 300px"></el-input>
					</div>
					<div class="flex" style="padding-bottom: 20px">
						<div class="ex_title" style="padding-right: 20px">个人简介</div>
						<el-input type="textarea" v-model="isAuth.introduction" :rows="5" placeholder="请输入个人简介" style="width: 300px"></el-input>
					</div>
					<div class="flex" style="padding-bottom: 20px">
						<div class="ex_title" style="padding-right: 20px">相关材料</div>
						<div style="width: 300px;">
							<uploadImg ref="upload"></uploadImg>
						</div>
					</div>
					<div class="index_c_top_btn" @click="sumbitClick()">确认提交</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="centerDialogVisible3" width="30%" title="联系客服" center>
			<div class="flex-center">
				<div>
					客服热线
					13332860706
				</div>
				
			</div>
			<div class="flex-center">
				<div>
					官方邮箱
					zangyu@famedbook.com
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import addressList from '@/utils/address.json'
import QRCode from "qrcode";
import uploadImg from '@/components/Upload/qiniuImage_s.vue';
export default {
	components: {
		uploadImg,
	},
	props: {
		tabIdx: {
			typeof: Number || String,
			default: 0,
		},
		sumbit: {
			typeof: Boolean,
			default: false,
		},
		keywords: {
			typeof: String,
			default: null,
		}
	},
	data() {
		return {
			centerDialogVisible: false,
			centerDialogVisible1: false,
			centerDialogVisible2: false,
			centerDialogVisible3: false,
			token: false,
			captchaimg: '',
			userData: {},
			params: {
				cid: '',
			},
			check: false,
			countdown: 0,
			timer: null,
			time: null,
			openId: '',
      option: addressList,
			areaId: '',
			inputKey: '',
			searchTimeout:null,//搜索防抖
			searchList: [],
			authUrl: '',
			userInfo: '',
			verifyCode: '',
			addressName: '广州市',
			isAuth: {}
		};
	},
	mounted() {
		if (!localStorage.getItem('bupaolu_token')) {
			// this.centerDialogVisible = true;
		} else {
			this.getUserInfo();
		}
		// this.getcaptcha();
		setTimeout(() => {
			this.getLocation(); // 调用获取地理位置
		}, 2000)
		setTimeout(() => {
			this.userInfo = JSON.parse(localStorage.getItem('bupaolu_userInfo')) || ''
			if (localStorage.getItem('广州市')) {
				this.addressName = JSON.parse(localStorage.getItem('bupaolu_address')).city
			}
		}, 10)
		this.$forceUpdate()
	},
	methods: {
		getLocation() {
			AMap.plugin('AMap.Geolocation', function() {
			  var geolocation = new AMap.Geolocation({
			    enableHighAccuracy: true,
			    timeout: 10000,
			    zoomToAccuracy: true,
			  })

			  geolocation.getCurrentPosition()
				geolocation.getCityInfo((status, complete) => {
					console.log(status, complete);
				})
			  AMap.event.addListener(geolocation, 'complete', onComplete)
			  AMap.event.addListener(geolocation, 'complete', onComplete)

			  function onComplete (data) {
			    // data是具体的定位信息
					let address = {}
					console.log(data);
					if (localStorage.getItem('bupaolu_address')) {
						address = JSON.parse(localStorage.getItem('bupaolu_address'))
						address.lon = data.position.lng
						address.lat = data.position.lat
						localStorage.setItem('bupaolu_address', JSON.stringify(address))
					} else {
						address = {
							city: "广州市",
							country: "中国",
							district: "天河区",
							lat: data.position.lat,
							lon: data.position.lng,
							province: "广东省",
						},
						localStorage.setItem('bupaolu_address', JSON.stringify(address))
					}
			  }
			})
		},
		funyuming(msg) {
			this.$router.push(msg);
		},
		funRouter(index, url) {
			this.tabIdx = index;
			this.$router.push(url);
		},
		goUrl(url) {
			this.$router.push(url);
		},
		goSumbit() {
			if (this.sumbit) {
				// emit事件
				console.log('fasong');
				return this.$emit('sumbit', this.keywords);
			}
			this.$router.push(this.tabIdx == 2 ? `community_sumbit?keywords=${this.keywords}` : `/sumbit?keywords=${this.keywords}`);
		},
		startCountdown() {
			this.countdown = 59;
			this.timer = setInterval(() => {
				if (this.countdown > 0) {
					this.countdown--;
				} else {
					clearInterval(this.timer);
					this.timer = null;
				}
			}, 1000);
			this.goCode();
		},
		goCode() {
			let params = {
				phone: this.params.phone,
				verifyCode: this.verifyCode,
				timestamp: 'pc_login' + this.time,
				type: 1
			};
			this.$api.login.sendCode(params).then(res => {
				this.$message.success(res);
			}).catch(err => {
				clearInterval(this.timer);
				this.timer = null;
				this.countdown = 0;
			})
		},
		// 获取验证码图片
		getcaptcha() {
			this.time = Number(new Date());
			this.captchaimg = this.$api.login.code('pc_login' + this.time);
		},
		login() {
			if (!this.check) {
				return this.$message.error('请先勾选协议');
			}
			if (!this.params.phone) {
				return this.$message.error('请输入手机号码');
			}
			if (!this.params.code) {
				return this.$message.error('请输入验证码');
			}
			this.$api.login.byMobile(this.params).then(res => {
				this.token = true;
				localStorage.setItem('bupaolu_token', res.token);
				localStorage.setItem('bupaolu_login', JSON.stringify(res));
				this.$setCookie('token', res.token)
				// 刷新页面
				window.location.reload();
				this.centerDialogVisible = false;
			});
		},
		loginOut() {
			localStorage.removeItem('bupaolu_token');
			localStorage.removeItem('bupaolu_login');
			localStorage.removeItem('bupaolu_userInfo');
			this.token = false;
			this.centerDialogVisible = true;
			// 刷新页面
			if (this.$route.name == 'bindPhone') {
				return this.$router.push('/')
			}
			window.location.reload();
		},
		getUserInfo() {
			this.$api.login.baseInfo().then(res => {
				this.userData = res;
				localStorage.setItem('bupaolu_userInfo', JSON.stringify(res));
				this.token = true;
				if (!res.isBind) {
					this.$message.error('请先绑定手机号码');
					this.$router.push('/phoneBind')
					// return;
				}
			});
		},
		areaRight(data) {
			this.areaId = data
			let top = document.getElementById(`hao-${data}`).offsetTop - document.querySelector('.menu').offsetTop;
			let element = document.getElementById(`hao-${data}`)
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		},
		loginInfo(type) {
			this.$api.login.authUrl(type).then(res => {
				setTimeout(() => {
					// 跳转页面
					window.location.href = res;
				}, 100)
			})
		},
		onScroll(e) {
			let scrollItems = document.querySelectorAll('.itemTit');
			for (let i = scrollItems.length - 1; i >= 0; i--) {
				// 判断滚动条滚动距离是否大于当前滚动项可滚动距离
				let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop;
				if (judge) {
					this.activeStep = i;
					break;
				}
			}
		},
		chose(e, index, newIndex) {
			this.brandIndex = newIndex;
			this.wortIndex = index;
			this.$emit('closeList', e);
		},
		closeList(val) {
			this.inpVal = val;
			this.visible = false;
		},
		//搜索输入
		searchInput(){
				if(this.searchTimeout){
					clearTimeout(this.searchTimeout)
				}
				if(!this.inputKey){
					this.searchList = [];
					return
				}
				//查询匹配的数据
				this.searchTimeout = setTimeout(() =>{
					let data = this.option.cityList;
					let result = [];
					let list = Object.values(data).flat();
					list.map(r =>{
						if(r['areaName'].indexOf(this.inputKey) !== -1){
							result.push(r)
						}
					})
					this.searchList = result
				},800)
		},
		init(e) {
			this.userInfo.avatar = e
			userInfo.avatar= e
			this.$forceUpdate()
		},
		selectIndex(e) {
			let address = JSON.parse(localStorage.getItem('bupaolu_address'))
			address.lat = e.latitude
			address.lon = e.longitude
			address.city = e.areaName
			this.addressName = address.city
			localStorage.setItem('bupaolu_address', JSON.stringify(address))
			this.centerDialogVisible1 = false
			window.location.reload();
		},
		isAuthOpen() {
			if (!this.userData.isAuthExpert) {
				this.centerDialogVisible2 = true
			}
		},
		sumbitClick() {
			let list = []
			for (const key in this.$refs.upload.ImageUrls) {
				list.push(this.$refs.upload.ImageUrls[key].url)
			}
			this.isAuth.images = list
			this.$api.me.authExpert(this.isAuth).then(res => {
				this.$message.success(res.data)
				this.centerDialogVisible2 = false
				this.isAuth = {}
			})
		}
	},
};
</script>
<style lang="scss" scoped>
.wrap {
	position: relative;
	width: 100vw;
	height: 101px;
	background-color: rgba(255, 255, 255, 1);
}
.nav_top {
	height: 36px;
	line-height: 36px;
	background: #222222;
	border-radius: 0px 0px 0px 0px;
	padding: 0 300px;
	box-sizing: border-box;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.nav_top_left,
.nav_top_right {
	display: flex;
	align-items: center;
}
.nav_top_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
}
.nav_top_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #fdc570;
	cursor: pointer;
}
.nav_bar {
	height: 80px;
	background: #ffffff;
	padding: 0 300px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
}
.tab_list {
	display: flex;
	align-items: center;
}
.tab_item_active {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	cursor: pointer;
	margin-right: 68px;
	position: relative;
	width: 50px;
}
.tab_item_active::before {
	position: absolute;
	bottom: -10px;
	left: -8px;
	content: '';
	width: 48px;
	height: 4px;
	background: #fdc570;
	border-radius: 150px 150px 150px 150px;
}
.tab_item {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #999999;
	cursor: pointer;
	margin-right: 68px;
	width: 50px;
}
.logo {
	// width: 132px;
	// height: 17px;
	// background: #333333;
	margin-right: 56px;
}
.input_box {
	width: 240px;
	background: #f5f5f5;
	border-radius: 277px 277px 277px 277px;
	margin-right: 8px;
}
.input_box input {
	border: none;
	width: 100%;
	background: #f5f5f5;
	box-sizing: border-box;
}
.input_box input::placeholder {
	padding: 0 8px;
}
.sumbit_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 20px;
	background: #fdc570;
	border-radius: 277px 277px 277px 277px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
.sumbit_btn_add {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	background: #fdc570;
	border-radius: 277px 277px 277px 277px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
.login_box {
	padding: 20px 0;
}
.login_box_logo {
	// width: 301px;
	// height: 39px;
	// background: #333333;
	margin-bottom: 24px;
}
.login_input {
	width: 360px;
	height: 46px;
	border-radius: 0px 0px 0px 0px;
	border: none;
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 16px;
}
.code_input {
	width: 258px;
	height: 46px;
	border-radius: 0px 0px 0px 0px;
	border: none;
	border-bottom: 1px solid #f5f5f5;
	margin-right: 10px;
	margin-bottom: 16px;
}
.code_btn {
	padding: 12px 10px;
	background: #fdc570;
	border-radius: 8px 8px 8px 8px;
	text-align: center;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
.xiyi {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	padding-left: 8px;
}
.ex_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	padding-right: 10px;
	color: #333333;
}
.xing::after {
	content: '*';
	color: red;
	font-size: 12px;
	padding-left: 4px;
}
.xiyi span {
	color: #81d3f8;
	cursor: pointer;
}
.login_btn {
	background: #fdc570;
	border-radius: 442px 442px 442px 442px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #ffffff;
	padding: 13px;
	text-align: center;
	margin: 0 35px;
	cursor: pointer;
	margin-top: 12px;
	margin-bottom: 24px;
}
.more_login {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
}
.line {
	width: 130px;
	height: 0px;
	border: 1px solid #f5f5f5;
	margin: 0 20px;
}
.login_icon {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	cursor: pointer;
}
.avatar {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-right: 8px;
}
.user_name {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #ffffff;
}
.user_tab {
	width: 200px;
	background: #fdc570;
	box-sizing: border-box;
	padding: 16px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	cursor: pointer;
}
.index_c_top_btn {
	margin-top: 24px;
	background: #fdc570;
	border-radius: 329px 329px 329px 329px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #ffffff;
	padding: 13px;
	text-align: center;
	cursor: pointer;
}
.user_tab_avatar {
	width: 56px;
	height: 56px;
	margin-right: 8px;
	border-radius: 50%;
}
.user_tab_name {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #ffffff;
	padding-bottom: 4px;
}
.user_tab_tag {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #ffffff;
}
.user_tab_item {
	width: 200px;
	background: #fff;
	box-sizing: border-box;
	padding: 16px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	cursor: pointer;
}
.check {
	border-radius: 50%;
	width: 16px;
	height: 16px;
	border: 1px solid #333;
}

.city_item {
	border-radius: 4px 4px 4px 4px;
	border: 1px solid #f5f5f5;
	padding: 4px 8px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #999999;
	margin-right: 12px;
	margin-bottom: 12px;
	cursor: pointer;
}
.city_item_active {
	border-radius: 4px 4px 4px 4px;
	border: 1px solid #fdc570;
	padding: 4px 8px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
	background: #fdc570;
	margin-right: 12px;
	margin-bottom: 12px;
	cursor: pointer;
}
.menu {
  position: relative;
}
.city_menu_container {
	position: absolute;
	top: 0;
	right: 0;
}
.city_menu_list {
	height: 650px;
	width: 95%;
	overflow: auto;
}
.city_menu_list::-webkit-scrollbar {
  display: none;
}
.area_active {
	background: #333333;
	color: #fff;
	border-radius: 8px;
	cursor: pointer;
	padding: 5px;
}
.area {
	cursor: pointer;
	padding: 5px;
}
.back_btn {
	background: #fafafa;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	padding: 8px 24px;
	margin-right: 16px;
	cursor: pointer;
}
</style>
