// 社区接口
import https from "@/utils/request";

// 社区列表
export const postsList = (data) => {
  return https({
    url: "/api/member/posts/list",
    method: 'post',
    data: data,
  });
};

// 话题
export const topicList = (data) => {
  return https({
    url: "/api/member/posts/topicList",
    method: 'post',
    data,
  });
};


// 发布
export const publish = (data) => {
  return https({
    url: "/api/member/posts/publish",
    method: 'post',
    data,
  });
};

// 详情
export const info = (data) => {
  return https({
    url: "/api/member/posts/info",
    method: 'post',
    data,
  });
}

// 用户评论列表
export const commentList = (data) => {
  return https({
    url: "/api/member/posts/commentList",
    method: 'post',
    data,
  });
}

// 发布评论
export const submitComment = (data) => {
  return https({
    url: '/api/member/posts/submitComment',
    method: 'post',
    data,
  })
}

// 投诉
export const complaint = (data) => {
  return https({
    url: '/api/member/posts/complaint',
    method: 'post',
    data,
  })
}

// 查看他人信息
export const detail = (data) => {
  return https({
    url: '/api/member/detail',
    method: 'post',
    data,
  })
}

export const taList = (data) => {
  return https({
    url: '/api/member/posts/taList',
    method: 'post',
    data,
  })
}

export const zan = (data) => {
  return https({
    url: '/api/member/posts/zan',
    method: 'post',
    data,
  })
}