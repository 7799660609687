<template>
	<!-- star -->
	<div class="homepage_wrap">
		<navbar :tabIdx="1"></navbar>

		<!-- center -->
		<div class="index_c_top flex-ju-sb">
			<el-image :src="detailData.coverImage" class="index_c_top_cover" style="width: 680px; height: 380px" fit="cover"></el-image>
			<div class="index_c_top_right">
				<div class="flex-ju-sb" style="align-items: baseline">
					<div class="index_c_top_title" style="width: 80%">{{ detailData.institutionName }}</div>
					<div class="flex shou" @click="handCollect">
						<span style="padding-right: 8px; font-weight: bold; font-size: 16px" v-if="!detailData.isCollect">+</span>
						<span>{{ detailData.isCollect ? '已收藏' : '收藏' }}</span>
					</div>
				</div>
				<div class="flex" style="padding-top: 18px">
					<el-rate v-model="detailData.institutionPoint" disabled text-color="#ff9900"></el-rate>
					<div class="rate_text">{{ detailData.institutionPoint }}</div>
				</div>
				<div class="flex" style="padding-top: 15px; padding-bottom: 16px">
					<div :class="item == '签约' ? 'content_item_bottom_tag_item1' : 'content_item_bottom_tag_item'" v-for="item in detailData.tabs" :key="item">{{ item }}</div>
				</div>
				<div class="main">
					<div class="flex" style="align-items: baseline">
						<el-image src="/static/pc/address.png" fit="cover" style="width: 16px; height: 16px; padding-right: 11px"></el-image>
						<div>
							{{ detailData.address }}
						</div>
					</div>
				</div>
				<div class="main" style="padding-top: 16px">
					<div class="flex" style="align-items: baseline">
						<!-- <i class="el-icon-phone" style="padding-right: 11px"></i> -->
						<el-image src="/static/pc/phone.png" fit="cover" style="width: 16px; height: 16px; padding-right: 11px"></el-image>
						{{ detailData.contact }}
					</div>
				</div>
			</div>
		</div>
		<div class="index_c">
			<div>
				<div class="flex" style="padding-bottom: 16px">
					<div class="yuan"></div>
					<div class="index_c_title">品牌介绍</div>
				</div>
				<div class="index_c_content">
					<div v-html="detailData.brandContent"></div>
				</div>
			</div>
			<div>
				<div class="flex" style="padding-bottom: 16px">
					<div class="yuan"></div>
					<div class="index_c_title">价目表</div>
				</div>
				<div class="index_c_wrap">
					<el-row :gutter="20">
						<el-col :span="6" v-for="(item, index) in tabList" :key="index">
							<div class="index_c_wrap_item" @click="goUrl(`/course?institutionId=${item.institutionId}&courseId=${item.courseId}`)">
								<!-- <div class="index_c_wrap_item_cover"></div> -->
								<el-image :src="item.coverImage" fit="cover" style="wiidth: 282px; height: 158px" class="index_c_wrap_item_cover"></el-image>
								<div class="index_c_wrap_item_bottom">
									<div class="index_c_wrap_item_bottom_title">{{ item.courseName }}</div>
									<div class="index_c_wrap_item_bottom_main">
										<span v-for="ite in item.tabs" :key="ite">{{ ite }}</span>
									</div>
									<div class="flex-ju-sb">
										<div class="index_c_wrap_item_bottom_price">￥{{ item.price }}</div>
										<div class="index_c_wrap_item_bottom_btn">{{ item.allowedBuy ? '购买' : '售馨' }}</div>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div style="padding: 16px 0">
				<div class="flex" style="padding-bottom: 16px">
					<div class="yuan"></div>
					<div class="index_c_title">机构详情</div>
				</div>
				<div class="index_c_tab">
					<div @click="tabClick(0)" :class="tabIdx == 0 ? 'index_c_tab_item_active' : 'index_c_tab_item'">团队介绍</div>
					<div @click="tabClick(1)" :class="tabIdx == 1 ? 'index_c_tab_item_active' : 'index_c_tab_item'">办学理念</div>
					<div @click="tabClick(2)" :class="tabIdx == 2 ? 'index_c_tab_item_active' : 'index_c_tab_item'">用户评价({{commitList.length}})</div>
					<div @click="tabClick(3)" :class="tabIdx == 3 ? 'index_c_tab_item_active' : 'index_c_tab_item'">相关资质</div>
				</div>
			</div>
			<el-row :gutter="20" v-if="tabIdx == 0">
				<el-col :span="6" v-for="(item, index) in teachDataList" :key="index" >
					<div class="item_c_item" @click="goUrl(`/teacher_detail?institutionId=${item.institutionId}&institutionMemberId=${item.id}`)">
						<div class="flex" style="align-items: flex-start">
							<!-- <div class="item_c_item_cover"></div> -->
							<div class="item_c_item_cover">
								<el-image :src="item.memberImage" fit="cover" style="width: 110px;height: 154px;border-radius: 4px"></el-image>
							</div>
							<div style="width: 70%">
								<div class="flex-ju-sb" style="padding-bottom: 8px">
									<div class="item_c_title">{{ item.memberName }}</div>
									<div class="item_c_status">详情</div>
								</div>
								<div class="item_c_main m-text-line-5">
									{{ item.introduction }}
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<!-- <div class="item_c_list" v-if="tabIdx == 0">
				
			</div> -->
			<div class="index_c_content" v-if="tabIdx == 1">
				<div v-html="detailData.brandIdea"></div>
			</div>
			<div class="index_c_commit" v-if="tabIdx == 2">
				<div class="index_c_commit_top">
					<div class="index_c_commit_tag" v-for="(item, index) in tabsVos" :key="index">{{ item.tab }}({{ item.cnt }})</div>
				</div>
				<div class="index_c_commit_item" v-for="(item, index) in commitList" :key="index">
					<div class="flex">
						<!-- <div class="index_c_commit_item_cover"></div> -->
						<el-image :src="item.avatar" fit="cover" style="width: 40px; height: 40px" class="index_c_commit_item_cover"></el-image>
						<div>
							<div class="flex" style="padding-bottom: 10px">
								<div class="index_c_commit_item_title">{{ item.nickname }}</div>
								<!-- <el-rate></el-rate> -->
							</div>
							<div class="flex">
								<div :class="ite == '签约' ? 'content_item_bottom_tag_item1' : 'content_item_bottom_tag_item'" v-for="(ite, idx) in item.tabs" :key="idx">{{ite}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-center" style="padding-top: 12px">
					<el-pagination background layout="prev, pager, next" :total="commitTotal" @current-change="currentChange"></el-pagination>
				</div>
			</div>
			<div class="index_c_four" v-if="tabIdx == 3">
				<div class="index_c_four_title" style="padding-bottom: 16px">请输入图片验证码查看资质</div>
				<div class="flex" style="padding-bottom: 16px">
					<el-input placeholder="请输入图片验证码查看资质" v-model="zzParams.verifyCode" style="width: 200px"></el-input>
					<el-image :src="codeUrl" class="code_img" style="width: 150px; height: 40px" @click="getCode()"></el-image>
				</div>
				<div class="index_c_four_btn" @click="zzSumbit">确定</div>
			</div>
		</div>
		<el-dialog :visible.sync="centerDialogVisible" width="50%" center>
			<div v-html="zzData"></div>
		</el-dialog>
		<!-- footer -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from '../components/footer.vue';
import navbar from '../components/nav.vue';
import { scrollTo } from '@/utils/scroll-to'
export default {
	components: {
		footer2,
		navbar,
	},
	data() {
		return {
			tabIdx: 0,
			detailData: {},
			tabList: [],
			tabParams: {
      		  id: '',
      		  pageNumber: 1,
      		  pageSize: 3,
      		},
			teachParams: {
      		  id: '',
      		  pageNumber: 1,
      		  pageSize: 3,
      		},
			commitParams: {
      		  institutionId: '',
      		  pageNumber: 1,
      		  pageSize: 10,
      		},
			teachDataList: [],
			commitList: [],
			tabsVos: [],
			commitTotal: 0,
			zzParams: {
      		  institutionId: '',
      		  verifyCode: '',
      		  timestamp: '',
      		},
			codeUrl: '', // 验证码图片
			centerDialogVisible: false,
			zzData: ''
		};
	},
	computed: {},
	methods: {
		tabClick(idx) {
			this.tabIdx = idx;
			if (this.tabIdx == 3) {
				this.getCode()
			}
		},
		goUrl(url) {
			this.$router.push(url);
		},
		getDetail(id) {
			this.$api.home.getInstitutionInfo({ id: id }).then((res) => {
      		  this.detailData = res
			  this.tabParams.id = id
			  this.teachParams.id = id
			  this.commitParams.institutionId = id
			  this.getTabList()
			  this.getTeachList()
			  this.getCommitList()
      		})
		},
		getTabList() {
    	  this.$api.home.getCourseList(this.tabParams).then((res) => {
			if (res) {
				this.tabList = res.records
			}
    	  })
    	},
		getTeachList() {
    	  this.$api.home.teachList(this.teachParams).then((res) => {
			if (res) {
				this.teachDataList = res.records
			}
    	  })
    	},
		getCommitList() {
    	  this.$api.home.commentList(this.commitParams).then((res) => {
			if (res) {
				this.commitList = res.commentPage.records
				this.tabsVos = res.tabsVos
				this.commitTotal = res.commentPage.total
			}
    	  })
    	},
		currentChange(e) {
			this.commitParams.pageNumber = e
      scrollTo(0, 800)
      this.getCommitList();
		},
		getCode() {
    	  this.zzParams.timestamp = 'pc_institution' + Number(new Date())
		  var time = Number(new Date())
		  this.codeUrl = this.$api.login.code('pc_institution' + time)
    	},
		async gotoZz() {
    	  this.zzParams.institutionId = this.detailData.institutionId
    	  this.$api.home.viewZizhi(this.zzParams).then(res => {
			console.log(res);
		  })
    	},
		handCollect() {
    	  this.$api.home.collect({ id: this.detailData.institutionId }).then((res) => {
    	    this.detailData.isCollect = !this.detailData.isCollect
			this.$forceUpdate()
			this.$message.success(res)
    	  })
    	},
		zzSumbit() {
			this.$api.home.viewZizhi(this.zzParams).then(res => {
				this.zzData = res
				this.centerDialogVisible = true
				this.zzParams.verifyCode = ''
			})
		}
	},
	mounted() {
		this.zzParams.institutionId = this.$route.query.id
		this.getDetail(this.$route.query.id)
		// console.log(layui);
	},
	created() {},
};
</script>
<style>
.el-rate__icon {
	font-size: 26px !important;
}
</style>
<style scoped>
.homepage_wrap {
	background: #fafafa;
}
.index_c_top {
	padding: 80px 300px;
	padding-bottom: 24px;
}
.index_c_top_cover {
	width: 680px;
	height: 380px;
}
.index_c_top_right {
	width: 600px;
	height: 380px;
	border-radius: 16px 16px 16px 16px;
	background-color: #fff;
	padding: 24px;
	box-sizing: border-box;
}
.index_c_top_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 20px;
	color: #333;
}
.index_c {
	padding: 0 300px;
}
.index_c_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #333333;
}
.index_c_content {
	background: #ffffff;
	border-radius: 16px 16px 16px 16px;
	padding: 24px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	margin-bottom: 24px;
	word-break: break-all;
}
.shou {
	background: #fdc570;
	cursor: pointer;
	border-radius: 8px 8px 8px 8px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #ffffff;
	padding: 8px 12px;
}
.rate_text {
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #fdc570;
	padding-top: 5px;
}
.content_item_bottom_tag_item {
	background: #f5f5f5;
	border-radius: 2px 2px 2px 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 12px;
	color: #333333;
	padding: 2px 8px;
	box-sizing: border-box;
	margin-right: 16px;
}
.content_item_bottom_tag_item1 {
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(253, 197, 112, 0.1);
	border-radius: 2px 2px 2px 2px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 12px;
	color: #fdc570;
	padding: 2px 8px;
	box-sizing: border-box;
	margin-right: 16px;
}
.main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
}

/* .index_c_wrap {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
} */
.index_c_wrap_item {
	width: 282px;
	margin-bottom: 16px;
	cursor: pointer;
}
.index_c_wrap_item_cover {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	width: 282px;
	height: 158px;
}
.index_c_wrap_item_bottom {
	padding: 16px;
	background-color: #fff;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}
.index_c_wrap_item_bottom_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #333333;
	padding-bottom: 8px;
}
.index_c_wrap_item_bottom_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	padding-bottom: 16px;
}
.index_c_wrap_item_bottom_price {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #f95620;
}
.index_c_wrap_item_bottom_btn {
	background: #fdc570;
	border-radius: 366px 366px 366px 366px;
	padding: 4px 16px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
}
.index_c_tab {
	background: #fff;
	border-radius: 16px;
	display: flex;
	align-items: center;
	padding: 16px;
}
.index_c_tab_item {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #999999;
	cursor: pointer;
	text-align: center;
	min-width: 96px;
	height: 46px;
	margin-right: 32px;
	line-height: 46px;
}
.index_c_tab_item_active {
	min-width: 96px;
	height: 46px;
	margin-right: 32px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	position: relative;
	cursor: pointer;
	text-align: center;
	line-height: 46px;
	border-bottom: 4px solid #fdc570;
}

.item_c_list {
	/* display: flex;
	align-items: center;
	flex-wrap: wrap; */
}
.item_c_item {
	background: #ffffff;
	border-radius: 12px 12px 12px 12px;
	padding: 16px;
	box-sizing: border-box;
	margin-bottom: 16px;
	cursor: pointer;
}
.item_c_item_cover {
	width: 110px;
	height: 154px;
	border-radius: 4px 4px 4px 4px;
	margin-right: 12px;
}
.item_c_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 18px;
	color: #333333;
}
.item_c_status {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 12px;
	color: #fdc570;
}
.item_c_main {
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #9e9e9e;
	word-break: normal;
}
.index_c_commit {
	padding: 16px;
	background-color: #fff;
	border-radius: 12px;
}
.index_c_commit_top {
	display: flex;
	align-items: center;
}
.index_c_commit_tag {
	background: #f5f5f5;
	border-radius: 578px 578px 578px 578px;
	padding: 4px 12px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 12px;
	color: #333333;
	margin-right: 8px;
	cursor: pointer;
}
.index_c_commit_item {
	border-bottom: 1px solid #f5f5f5;
	padding-top: 14px;
	padding-bottom: 16px;
}
.index_c_commit_item_cover {
	width: 40px;
	height: 40px;
	margin-right: 8px;
	border-radius: 50%;
}
.index_c_commit_item_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
	padding-right: 16px;
}
.index_c_four {
	padding: 24px;
	background-color: #fff;
	border-radius: 12px;
}
.index_c_four_title {
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	font-size: 14px;
	color: #333333;
}
.code_img {
	width: 150px;
	height: 40px;
	border-radius: 0px 0px 0px 0px;
	margin-left: 12px;
}
.index_c_four_btn {
	background: #fdc570;
	border-radius: 366px 366px 366px 366px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
	padding: 8px 0;
	width: 60px;
	text-align: center;
	cursor: pointer;
}
</style>
